import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import './index.css';
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Select from "react-select";
import CustomNumericInput from "../CustomNumericInput/CustomNumericInput";
import {isEmpty} from "../Global/Global";
import {lang, popover} from "../../Modules/Global";
import _ from "lodash";
import {Popover, Whisper} from "rsuite";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import store from "../../store";
import {convertPlaneToTree} from '../../api/helper'

const customStyles = {
	container: base => ({
		...base,
		visibility: 'visible'
	}),
    menuPortal: base => ({
        ...base,
        zIndex: 999
    }),
	control: base => ({
		...base,
		minHeight: 34
	}),
	dropdownIndicator: base => ({
		...base,
		padding: 4,
		visibility: 'visible'
	}),
	clearIndicator: base => ({
		...base,
		padding: 4
	}),
	valueContainer: base => ({
		...base,
		padding: '0px 6px',
		visibility: 'visible'
	}),
	input: base => ({
		...base,
		margin: 0,
		padding: 0
	}),
	option: (provided, state) => {
		return {
			...provided,
			//borderBottom: '1px dotted pink',
			color: state.isSelected ? '#ffffff' : '#000000',
			backgroundColor: state.isSelected ? '#007bff' : '#ffffff',
			//padding: 10,
			fontSize: 14,
			// lineHeight: '10px'
		}
	}
};

class ConstructProperties extends Component {

	constructor(props) {
		super(props);

		this.app = this.props.app;
		this.isStrip = this.props.hasOwnProperty('isStrip');
		this.basement = this.props.basement;

		this.validateField = this.props.parent.validateField;
		this.customNumericInputHandler = this.props.parent.customNumericInputHandler.bind(this.app);

		this.storeListeners = {};

		// Конструктивные особенности фундамента
		this.options = {
			// Геотехнические категории
			geotechnicalCategories: [
				{ id: 1, title: lang('I (простая)') },
				{ id: 2, title: lang('II (средняя)') },
				{ id: 3, title: lang('III (сложная)') },
			],

			// Типы зданий
			buildingTypes: [
				{ id: "тип_1", title: lang("С мостовым краном грузоподъемностью 75т и выше") },
				{ id: "тип_2", title: lang("Для фундаментов колонн открытых крановых эстакад при кранах грузоподъемностью свыше 15т")},
				{ id: "тип_3", title: lang("Для сооружения башенного типа")},
				{ id: "тип_4", title: lang("Для фундаментов зданий с мостовыми кранами")},
				{ id: "тип_5", title: lang("Для фундаментов бескрановых зданий")},
				{ id: "тип_6", title: lang("Для зданий с подвесным транспортным оборудованием")}
			],

			// Способы устройства пола
			floorTypes: [
				{ id: "по_грунту", title: lang("По грунту") },
				{ id: "на_лагах", title: lang("На лагах по грунту")},
				{ id: "по_цокольному", title: lang("По утепленному цокольному перекрытию")},
			],

			// Типы конструктивной схемы здания
			constructiveSchemeTypes: [
				{ id: "жесткая", title: lang("Жесткая") },
				{ id: "гибкая", title: lang("Гибкая")},
			],

            // Типы отапливаемости здания
            heatingTypes: [
                { id: true, title: lang("Отапливаемое") },
                { id: false, title: lang("Не отапливаемое")},
            ],

            // Способы устройства пола при ленточном фундаменте
            stripFloorTypes: [
                { id: "по_цокольному", title: lang("По утепленному цокольному перекрытию")},
                { id: "с_подвалом", title: lang("С  подвалом или техническим подпольем") }
            ],

			// Отапливаемость здания
			isBuildingHeating: this.app.state['отапливаемость_здания']
		};

		let selectedBuildingType = _.find(this.options.buildingTypes, {
			id: this.app.state['тип_здания']
		}) || this.options.buildingTypes[0];


		this.state = {
			Su: [],
			filteredSu: [],

			selectedConstructionOfBuilding:{
				Su: 12,
				isDisabled: 0,
				kren: "",
				label: "крупных блоков или кирпичной кладки без армирования",
				value: 9
			},

			selectedGeotechnicalCategory: _.find(this.options.geotechnicalCategories, {
				id: parseInt(this.app.state['геотехническая_категория'])
			}) || this.options.geotechnicalCategories[0],

			selectedBuildingType,

			selectedConstructiveSchemeType: _.find(this.options.constructiveSchemeTypes, {
				id: this.app.state['конструктивная_схема']
			}) || this.options.constructiveSchemeTypes[0],

			// Выбранный способ устройства пола
			selectedFloorType: _.find(this.options.floorTypes, {
				id: this.app.state['устройство_пола']
			}) || this.options.floorTypes[0],

            selectedHeatingType: _.find(this.options.heatingTypes, {
				id: this.app.state['отапливаемость_здания']
			}) || this.options.heatingTypes[0],

            // Выбранный способ устройства пола при ленточном фундаменте
            selectedStripFloorType: _.find(this.options.stripFloorTypes, {
                id: this.app.state['устройство_пола']
            }) || this.options.stripFloorTypes[0],
		};
	}

	getFilteredSu(Su, selectedBuildingType) {
		return ((!this.isStrip)) ? Su.filter(item => item.type.split(',').includes(selectedBuildingType.id)) : Su;
	}

	componentDidMount() {
		// Подпишемся на измнение стора
		this.storeListeners.updateStateFromStore = store.subscribe(this.updateStateFromStore);
	}

	getCurrentStateFromStore() {
		let Su = store.getState().fetched.Su.entities || [];

		return {
			Su,
			filteredSu: this.getFilteredSu(Su, this.state.selectedBuildingType)
		}
	}

	updateStateFromStore = () => {
		const currentState = this.getCurrentStateFromStore();

		this.setState(currentState);
	};

	componentDidCatch(error, errorInfo) {}

	handleChangeConstructionSu = (item) => {
		delete item.option;

        this.app.setState({ 'Su': item.Su });

        this.setState({selectedConstructionOfBuilding: item});

        if(!isEmpty(item.kren)){
            this.app.setState({
                'крен': true,
                iu: item.kren,
            })
        }else{
            this.app.setState({
                'крен': false,
                iu: '',
            })
        }
    };

	handleChangeBuildingType(selectedBuildingType) {
		let filteredSu = this.state.Su;

		if(!this.isStrip)
			filteredSu = this.state.Su.filter(item => item.type.split(',').includes(selectedBuildingType.id))

		this.setState({
			selectedBuildingType,
			filteredSu,
			selectedConstructionOfBuilding: filteredSu.find(item => item.parentId !== 0)
		}, () => this.app.setState({'тип_здания': selectedBuildingType.id}));
	}

    render() {
        return (
            <div>
				<div className="form-group">
					<label htmlFor="select-geotechnical-category">
						{lang('Геотехническая категория') + ': '}
						<Whisper placement="topStart"
								 delayShow={300}
								 trigger="hover" speaker={popover(
							<div className={'text-left'} style={{maxWidth: 350}}>
								<p className={'mb-1'}>
									{lang('Категорию сложности инженерно-геологических условий строительства следует определять в соответствии с СП 47.13330 по таблице А.1.')}
								</p>
							</div>
						)} >
							<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
						</Whisper>
					</label>
					<div className="Select-control">
						<Select
							id="select-geotechnical-category"
							styles={customStyles}
							value={this.state.selectedGeotechnicalCategory}
							onChange={selected => {
								this.setState({
									selectedGeotechnicalCategory: selected
								}, () => this.app.setState({"геотехническая_категория": Number(selected.id)}));
							}}
							options={this.options.geotechnicalCategories}
							getOptionLabel ={option => option.title}
							getOptionValue ={option => option.id}
							placeholder={lang('Выберите геотехническую категорию')}
						/>
					</div>
				</div>

				{!this.isStrip &&
				<div className="form-group">
					<label htmlFor="select-geotechnical-category">
						{lang('Тип здания') + ':'}
					</label>
					<div className="Select-control">
						<Select
							id="select-building-type"
							styles={customStyles}
							value={this.state.selectedBuildingType}
							onChange={selected => this.handleChangeBuildingType(selected)}
							options={this.options.buildingTypes}
							getOptionLabel ={option => option.title}
							getOptionValue ={option => option.id}
							placeholder={lang('Выберите тип здания')}
						/>
					</div>
				</div>
				}

				<div className="form-group">
					<label htmlFor="select-su">
						{lang('Конструкция здания') + ':'}
					</label>
					<div className="Select-control">
						<Select
							id="select-construction-building"
							onChange={this.handleChangeConstructionSu}
							styles={customStyles}
							options={convertPlaneToTree(this.state.filteredSu)}
							value={this.state.selectedConstructionOfBuilding}
							placeholder={lang('Выберите конструкцию здания')}
						/>
					</div>
				</div>

				<div className="form-group">
					<label htmlFor="field-operation-coord-y">{lang('Предельное значение осадки')} {ReactHtmlParser('S<sub>u</sub>')}{', '}{lang('(см)')}{':'}</label>
					<div className="sb-field-unit-container">
						<CustomNumericInput
							name={'Su'}
							className="form-control input-text"
							value={this.app.state.Su}
							allowNegative={false}
							measure={'[см]'}
							step={0.1}
							min={0}
							onValidate={this.validateField}
							enabledHandlerOnInput={true}
							isValid={!this.app.state.errors.hasOwnProperty('Su')}
							onChange={this.customNumericInputHandler}
						/>
					</div>
				</div>

				<div>
					{!this.isStrip &&
					<div className="form-group">
						<label htmlFor="select-geotechnical-category">
							{lang('Конструктивная схема') + ': '}
							<Whisper placement="topStart"
									 delayShow={300}
									 trigger="hover" speaker={popover(
								<div className={'text-left'} style={{maxWidth: 350}}>
									<p className={'mb-1'}>
										{lang('К числу зданий и сооружений с жесткой конструктивной схемой относятся')}:
										<br/>- {lang('здания панельные, блочные и кирпичные, в которых междуэтажные перекрытия опираются по всему контуру на поперечные и продольные стены или только на поперечные несущие стены при малом их шаге')};
										<br/>- {lang('сооружения типа башен, силосных корпусов, дымовых труб, домен и др.')}
									</p>
								</div>
							)} >
								<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
							</Whisper>
						</label>

						<div className="Select-control">
							<Select
								id="select-geotechnical-category"
								styles={customStyles}
								value={this.state.selectedConstructiveSchemeType}
								onChange={selected => {
									this.setState({
										selectedConstructiveSchemeType: selected
									}, () => this.app.setState({'конструктивная_схема': selected.id}));
								}}
								options={this.options.constructiveSchemeTypes}
								getOptionLabel ={option => option.title}
								getOptionValue ={option => option.id}
								placeholder={lang('Выберите конструктивную схему')}
							/>
						</div>
					</div>
					}

					{(this.state.selectedConstructiveSchemeType.id === "жесткая") &&
					<div>
						<div className="form-group">
							<CustomNumericInput
								name={'Yc_l'}
								className="form-control input-text"
								value={this.app.state.Yc_l}
								allowNegative={false}
								measure={'[м]'}
								precision={2}
								labelLeft={true}
								widthLabel={'65%'}
								widthInput={'35%'}
								label={lang('Длина здания L') + ', ' + lang('(м)') + ':'}
								min={0}
								onValidate={this.validateField}
								enabledHandlerOnInput={true}
								isValid={!this.app.state.errors.hasOwnProperty('Yc_l')}
								onChange={this.customNumericInputHandler}
							/>
						</div>

						<div className="form-group">
							<CustomNumericInput
								name={'Yc_h'}
								className="form-control input-text"
								value={this.app.state.Yc_h}
								allowNegative={false}
								measure={'[м]'}
								precision={2}
								labelLeft={true}
								widthLabel={'65%'}
								widthInput={'35%'}
								label={lang('Высота здания H') + ', ' + lang('(м)') + ':'}
								min={0}
								onValidate={this.validateField}
								enabledHandlerOnInput={true}
								isValid={!this.app.state.errors.hasOwnProperty('Yc_h')}
								onChange={this.customNumericInputHandler}
							/>
						</div>
					</div>
					}

					{this.basement && !this.app.state['отапливаемость_подвала'] &&
                    <div className="form-group">
                        <label htmlFor="">{lang('Отапливаемость здания') + ':'}</label>
                        <div className="Select-control">
                            <Select
                                menuPortalTarget={document.body}
                                styles={customStyles}
                                value={this.state.selectedHeatingType}
                                onChange={selected => {
                                    this.setState({
                                        selectedHeatingType: selected
                                    }, () => this.app.setState({'отапливаемость_здания': selected.id}));
                                }}
                                options={this.options.heatingTypes}
                                getOptionLabel ={option => option.title}
                                getOptionValue ={option => option.id}
                                placeholder={lang('Выберите тип отапливаемости')}
                            />
                        </div>
                    </div>
					}
					{this.basement && this.app.state['отапливаемость_подвала'] &&
                    <div className="form-group">
                        <label htmlFor="">{lang('Устройство пола') + ':'}</label>
                        <div className="Select-control">
                            <Select
                                menuPortalTarget={document.body}
                                styles={customStyles}
                                value={this.state.selectedStripFloorType}
                                onChange={selected => {
                                    this.setState({
                                        stripFloorTypes: selected
                                    }, () => this.app.setState({'устройство_пола': selected.id}));
                                }}
                                options={this.options.heatingTypes}
                                getOptionLabel ={option => option.title}
                                getOptionValue ={option => option.id}
                                placeholder={lang('Выберите тип устройства пола')}
                            />
                        </div>
                    </div>
					}

					{!this.basement &&
					<div>
						<div className="switch-wrapper">
							<label className="switch">
								<input type="checkbox"
									   name={'отапливаемость_здания'}
									   defaultChecked={this.state.isBuildingHeating}
									   onChange={() => {
										   let checked = !this.state.isBuildingHeating;

										   this.setState({isBuildingHeating: checked}, () => {
											   this.app.setState({'отапливаемость_здания': checked});
										   });
									   }}/>
								<span className="slider"></span>
							</label>
							<span className="switch-label">{lang('Здание отапливаемое')}</span>
							<div className="clearfix"></div>
						</div>

						{this.state.isBuildingHeating &&
						<div>
							<div className="form-group">
								<label htmlFor="select-geotechnical-category">
									{lang('Устройство пола') + ':'}
								</label>
								<div className="Select-control">
									<Select
										id="select-geotechnical-category"
                                        menuPortalTarget={document.body}
										styles={customStyles}
										value={this.state.selectedFloorType}
										onChange={selected => {
											this.setState({
												selectedFloorType: selected
											}, () => this.app.setState({'устройство_пола': selected.id}));
										}}
										options={this.options.floorTypes}
										getOptionLabel ={option => option.title}
										getOptionValue ={option => option.id}
										placeholder={lang('Выберите способ устройства пола')}
									/>
								</div>
							</div>

							<div style={{marginTop: 5}}>
								<CustomNumericInput
									name={'kh_temp'}
									className="form-control input-text"
									value={this.app.state.kh_temp}
									allowNegative={false}
									measure={'[<sup>o</sup>C]'}
									label={lang('Температура в помещении') + ', (°C):'}
									labelLeft={true}
									widthLabel={'65%'}
									widthInput={'35%'}
									lineHeight={'16px'}
									min={0}
									onValidate={this.validateField}
									enabledHandlerOnInput={true}
									isValid={!this.app.state.errors.hasOwnProperty('kh_temp')}
									onChange={this.customNumericInputHandler}
								/>
							</div>
						</div>
						}
					</div>
					}
				</div>
            </div>
        );
    }
}

export default ConstructProperties;
