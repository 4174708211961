import {createSlice} from "@reduxjs/toolkit";
import {isEmpty} from "../../Components/Global/Global";

const initialState = {
	sidebars: {
		sidebarParameters: {
			key: "sidebarParameters",
			type: "left",
			show: true,
			title: "Параметры фундамента"
		},
		sidebarConfig: {
			key: "sidebarConfig",
			type: "left",
			show: true,
			title: "Параметры фундамента"
		}
	},

	isOpenedSidebar: false,
	leftSidebarIsAttractedToScreen: false,	// Открыт или закрыт левый сайдбар
	position: {								// Позиция левого сайдбара
		x: 0,
		y: 0,
	}
};

/**
 * Функция, служащая для проверки состояния открыт ли хотя-бы один сайдбар
 * @param sidebars
 * @param type
 * @returns {boolean}
 */
const someSidebarIsOpen = function (sidebars = {}, type = "left") {
	return Object.keys(sidebars)
		.reduce((hiddenAll, sidebarKey) => {
			return hiddenAll && !sidebars[sidebarKey].show;
		}, true);
};

const sidebarsSlice = new createSlice({
	name: "sidebars",
	initialState,
	reducers: {
		toggleSidebarShow: (state, action) => {
			if (!isEmpty(state.sidebars[action.payload]))
				state.sidebars[action.payload].show = !state.sidebars[action.payload].show;
		},
		closeSidebarAction: (state, action) => {
			state.sidebars[action.payload].show = false
		},
		toggleAttractSidebar: state => {
			state.leftSidebarIsAttractedToScreen = !state.leftSidebarIsAttractedToScreen
		},
	}
});

export default sidebarsSlice.reducer;

export const {
	toggleSidebarShow,
	closeSidebarAction,
	toggleAttractSidebar
} = sidebarsSlice.actions;