import React, { Component } from 'react';
import {lang} from "../../Modules/Global";
import Select from "react-select";
import {Popover, Whisper} from "rsuite";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import CustomNumericInput from "../CustomNumericInput/CustomNumericInput";
import {stylesReactSelect} from "../Helpers/style";
import _ from "lodash";
import anchor1 from "../../img/anchor1.png";
import anchor2 from "../../img/anchor2.png";
import anchor3 from "../../img/anchor5.png";
import anchor4 from "../../img/anchor4.png";
import anchor5 from "../../img/anchor3.png";
import twoBranches from "../../img/twoBranches.svg";
import lowTraversa from "../../img/lowTraversa.svg";
import highTraversaSvgL from "../../img/highTraversaSvgL.svg";
import highTraversaSvgB from "../../img/highTraversaSvgB.svg";
import $ from "jquery";
import {isEmpty} from "../Global/Global";

const styleMultilineLabel = {width: '65%', lineHeight: '16px', alignSelf: 'center', marginBottom: 0},
	styleMultiLineInput = {width: '35%'};


class ColumnTypeMetal extends Component {

	constructor(props) {
		super(props);

		this.app = this.props.app;
		this.parent = this.props.parent;

		this.myRef = React.createRef();
		this.svgImg = {};

		this.svgTwoBranchesHandler = this.parent.svgTwoBranchesHandler;
		this.svgOneBranchHandler = this.parent.svgOneBranchHandler;
		this.paramsHandler = this.parent.paramsHandler;
		this.toggleButtonsGroupHandler = this.parent.toggleButtonsGroupHandler;
		this.customNumericInputHandler = this.parent.customNumericInputHandler;

		// Список типов металлической колонны
		this.optionsMetalColumnTypes = [
			{ id: 'двухветвевая', title: lang('Двухветвевая') },
			{ id: 'одноветвевая', title: lang('Одноветвевая') }
		];

		// Список типов баз колонны
		this.optionsBaseTypes = [
			{ id: 'высокая', title: lang('С высокой траверсой') },
			{ id: 'низкая', title: lang('С низкой траверсой') }
		];

		// Список типов нагрузок
		this.optionsLoads = [
			{ id: 'статическая', title: lang('Статическая') },
			{ id: 'динамическая', title: lang('Динамическая') }
		];

		// Список типов конструкции болта
		this.optionsBoltTypes = [
			{ id: 'конический', title: lang('конический'), img: anchor1 },
			{ id: 'прямой', title: lang('прямой'), img: anchor2 },
			{ id: 'с отгибом', title: lang('с отгибом'), img: anchor3 },
			{ id: 'с анкерной плитой съемный', title: lang('с анкерной плитой съемный'), img: anchor4 },
			{ id: 'с анкерной плитой глухой', title: lang('с анкерной плитой глухой'), img: anchor5 }
		];

		// Список колтичество болтов
		this.optionsBoltCount = [1, 2, 3, 4].map(item => {
			return {
				label: item,
				value: item
			}
		});

		// Марки стали
		this.optionsSteelGrades = ['Ст3пс2', 'Ст3пс4', 'Ст3сп4', 'Ст3сп2', '09Г2С-4', '09Г2С-6'].map(item => {
			return {
				label: item,
				value: item
			}
		});

		this.state = {
			isCheck: this.props.isCheck || false,

			width: 200,
			height: 200,
			delayShowPopover: 300,
			placementPopover: "auto",

			selectedMetalColumnType: _.find(this.optionsMetalColumnTypes, {
				id: this.app.state['тип_металлической_колонны']
			}),

			selectedBaseType: _.find(this.optionsBaseTypes, {
				id: this.app.state['база_колонны']
			}),

			selectedLoad: _.find(this.optionsLoads, {
				id: this.app.state['нагрузка_на_колонну']
			}),

			selectedBoltType: _.find(this.optionsBoltTypes, {
				id: this.app.state['конструкция_болта']
			}),
		};
	}

	setSvgImgToPopover(el, callback = () => {}) {
		this.setState({
			width: el.getSVGDocument().documentElement.getAttribute('width'),
			height: el.getSVGDocument().documentElement.getAttribute('height')
		}, () => {
			this.svgImg = $(el.getSVGDocument().documentElement).clone();

			$(this.myRef.current).html(this.svgImg);

			callback();
		});
	}

	popoverSvg() {
		return (
			<Popover
				visible
				style={{
					color: '#ffffff',
					// minWidth: ((this.state.width > 300) ? this.state.width : 300) + 'px',
					minHeight: ((this.state.height < 300) ? this.state.height : 300) + 'px'
				}}>
				<div
					className="popover-container-svg"
					ref={this.myRef}
					style={{
						// width: this.state.width + 'px',
						height: ((this.state.height < 300) ? this.state.height : 300) + 'px'
					}}>

				</div>
			</Popover>
		);
	}

	validateField = (props) => {
		const value = props.value;
		const name = props.name;
		let isValid = true,
			textError = lang('Заполните поле');

		switch (name) {
			case 'la':
			case 'ba':
				if(this.app.state['тип_колонны'] === 'металлическая'){
					if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
						if (value < 0.05 || isEmpty(value) || value > 0.8) {
							isValid = false;
							textError = 'Расстояние от оси колонны до оси болта не менее 0.05 м и не более 0.8 м';
						}
					}
				}
				break;
			case 'bp':
				if(value < 0.2 || value > 1){
					isValid = false;
					textError = 'Введены некорректные размеры анкерной плиты, уточните размеры';
				}
				if(this.app.state['тип_колонны'] === 'металлическая'){
					if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
						if(this.app.state['база_колонны'] === 'низкая'){
							if(value <= this.app.state.c_*2){
								isValid = false;
								textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
							}
						}
					}
				}
				if(isEmpty(value)) isValid = false;
				break;
			case 'lp':
				if(value < 0.2 || value > 1){
					isValid = false;
					textError = 'Введены некорректные размеры анкерной плиты, уточните размеры';
				}
				if(this.app.state['тип_колонны'] === 'металлическая'){
					if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
						if(this.app.state['база_колонны'] === 'низкая'){
							if(value <= this.app.state.c*2){
								isValid = false;
								textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
							}
						}
					}
				}
				if(isEmpty(value)) isValid = false;
				break;
			case 'h':
				if(value < 0.2 || value > 3 || isEmpty(value)){
					isValid = false;
					textError = 'Расстояние в осях между ветвями колонны должно быть не менее 0.2 м и не более 3 м';
				}
				break;
			case 'k':
				if(value < 0.15 || value > 1.1 || isEmpty(value)){
					isValid = false;
					textError = 'Расстояние от оси болта до противоположной грани болта должна быть в пределах от 0.15 м до 1.1 м';
				}
				break;
			case 'La':
				if(value < 0.2 || value > 1 || isEmpty(value)){
					isValid = false;
					textError = 'Расстояние от оси болта до противоположной грани пластины должно быть в пределах от 0.2 м до 1 м';
				}
				break;
			case 'c':
				if(value < 0.1 || value > 0.6 || isEmpty(value)){
					isValid = false;
					textError = 'Расстояние от оси болта до оси колонны должно быть в пределах от 0.1 м до 0.6 м';
				}
				if(this.app.state['тип_колонны'] === 'металлическая'){
					if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
						if(this.app.state['база_колонны'] === 'низкая'){
							if(this.app.state.lp <= value*2){
								isValid = false;
								textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
							}
						}
					}
				}
				break;
			case 'c_':
				if(value < 0.02 || value > 0.6 || isEmpty(value)){
					isValid = false;
					textError = 'Расстояние от оси болта до оси колонны должно быть в пределах от 0.02 м до 0.6 м';
				}
				if(this.app.state['тип_колонны'] === 'металлическая'){
					if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
						if(this.app.state['база_колонны'] === 'низкая'){
							if(this.app.state.bp <= value*2){
								isValid = false;
								textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
							}
						}
					}
				}
				break;
		}

		if(value < 0){
			isValid = false;
			textError = 'Значение не может быть отрицательным';
		}
		return {
			isValid: isValid,
			textError: (isValid) ? '' : textError
		}
	};

	render () {
		return (
			<div>
				<div className="form-group">
					<label htmlFor="">{lang('Конструкция колонны') + ':'}</label>
					<div className="Select-control">
						<Select
							menuPortalTarget={document.body}
							styles={stylesReactSelect}
							value={this.state.selectedMetalColumnType}
							onChange={selected => {
								this.setState({
									selectedMetalColumnType: selected
								}, () => this.app.setState({'тип_металлической_колонны': selected.id}));
							}}
							options={this.optionsMetalColumnTypes}
							getOptionLabel ={option => option.title}
							getOptionValue ={option => option.id}
							placeholder={lang('Выберите тип конструкции колонны')}
						/>
					</div>
				</div>

				{(this.app.state['тип_металлической_колонны'] === 'одноветвевая') &&
				<div className="form-group">
					<label htmlFor="">{lang('База колонны') + ':'}</label>
					<div className="Select-control">
						<Select
							menuPortalTarget={document.body}
							styles={stylesReactSelect}
							value={this.state.selectedBaseType}
							onChange={selected => {
								this.setState({
									selectedBaseType: selected
								}, () => this.app.setState({'база_колонны': selected.id}));
							}}
							options={this.optionsBaseTypes}
							getOptionLabel ={option => option.title}
							getOptionValue ={option => option.id}
							placeholder={lang('Выберите тип базы колонны')}
						/>
					</div>
				</div>
				}
				<div className="form-group">
					<label htmlFor="">{lang('Нагрузка на колонну') + ':'}</label>
					<div className="Select-control">
						<Select
							menuPortalTarget={document.body}
							styles={stylesReactSelect}
							value={this.state.selectedLoad}
							onChange={selected => {
								this.setState({
									selectedLoad: selected
								}, () => this.app.setState({'нагрузка_на_колонну': selected.id}));
							}}
							options={this.optionsLoads}
							getOptionLabel ={option => option.title}
							getOptionValue ={option => option.id}
							placeholder={lang('Выберите вид нагрузки')}
						/>
					</div>
				</div>

				{(this.app.state['нагрузка_на_колонну'] === 'динамическая') &&
				<div className='d-flex form-group'>
					<p style={{width: '65%'}}>{lang('Число циклов нагружений')}:</p>
					<select name='alpha'
							className='fz-14 form-control'
							onChange={this.paramsHandler}
							value={this.app.state.alpha}
							style={{width: '35%'}}>
						<option value='3.15'>50 тыс.</option>
						<option value='2.25'>200 тыс.</option>
						<option value='1.57'>800 тыс.</option>
						<option value='1.25'>2 млн.</option>
						<option value='1'>5 млн. и более</option>
					</select>
				</div>
				}
				<div className="row" style={{marginBottom: "7px"}}>
					<div className="col-3">
						<img src={this.state.selectedBoltType.img} alt="" className="anchor-img"/>
					</div>
					<div className="col-9">
						<div className="row no-gutters">
							<div className="form-group">
								<label htmlFor="">{lang('Конструкция болта') + ':'}</label>
								<div className="Select-control">
									<Select
										menuPortalTarget={document.body}
										styles={stylesReactSelect}
										value={this.state.selectedBoltType}
										onChange={selected => {
											this.setState({
												selectedBoltType: selected
											}, () => this.app.setState({'конструкция_болта': selected.id}));
										}}
										options={this.optionsBoltTypes}
										getOptionLabel ={option => option.title}
										getOptionValue ={option => option.id}
										placeholder={lang('Выберите тип конструкции болта')}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex form-group">
					<p style={{width: '65%'}}>{lang('Марка стали болта')}:</p>
					<Select
						menuPortalTarget={document.body}
						styles={stylesReactSelect}
						name={"марка_стали"}
						className={"w-35"}
						onChange={option => this.app.setState({"марка_стали": option.value})}
						defaultValue={this.optionsSteelGrades.find(option => option.value === this.app.state['марка_стали'])}
						options={this.optionsSteelGrades}
					/>
				</div>

				{this.state.isCheck &&
					<div className="form-group">
						<CustomNumericInput
							name={'H0'}
							className="form-control input-text"
							value={this.app.state['H0']}
							allowNegative={false}
							measure={'[см]'}
							precision={0}
							maxLength={1}
							labelLeft
							widthLabel={'65%'}
							widthInput={'35%'}
							label={"Глубина заделки H<sub>0</sub>, (см):"}
							step={2}
							min={0}
							onValidate={this.validateField}
							enabledHandlerOnInput={true}
							isValid={!this.app.state.errors.hasOwnProperty('H0')}
							onChange={this.customNumericInputHandler}
						/>
					</div>
				}

				{/* BEGIN - Двухветвевая  */}
				{(this.app.state['тип_металлической_колонны'] === 'двухветвевая') &&
				<div>
					<div className="d-flex form-group">
						<label htmlFor="" style={styleMultilineLabel}>
							{lang('Количество болтов на одной ветви') + ': '}
							<Whisper
								placement={this.state.placementPopover}
								delayShow={this.state.delayShowPopover}
								trigger="hover"
								onEntering={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({name: "n"}))}
								onExited={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({name: "n", isHide: true}))}
								speaker={this.popoverSvg()}
							>
								<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
							</Whisper>
						</label>
						<select
							name='n'
							className='fz-14 form-control'
							onChange={this.paramsHandler}
							value={this.app.state.n}
							style={styleMultiLineInput}
						>
							<option value={2}>2</option>
							<option value={4}>4</option>
							<option value={6}>6</option>
							<option value={8}>8</option>
						</select>
					</div>
					<div className="form-group">
						<CustomNumericInput
							name={'k'}
							className="form-control input-text"
							value={this.app.state.k}
							allowNegative={false}
							measure={'[м]'}
							precision={2}
							labelLeft
							widthLabel={'65%'}
							widthInput={'35%'}
							label={"Растояние в осях между болтами k, (м): "}
							whisper={
								<Whisper
									placement={this.state.placementPopover}
									delayShow={this.state.delayShowPopover}
									trigger="hover"
									onEntering={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({id: "k"}))}
									onExited={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({id: "k", isHide: true}))}
									speaker={this.popoverSvg()}
								>
									<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
								</Whisper>}
							min={0}
							step={0.1}
							onValidate={this.validateField}
							enabledHandlerOnInput={true}
							isValid={!this.app.state.errors.hasOwnProperty('k')}
							onChange={this.customNumericInputHandler}
						/>
					</div>
					<div className="form-group">
						<CustomNumericInput
							name={'h'}
							className="form-control input-text"
							value={this.app.state['h']}
							allowNegative={false}
							measure={'[м]'}
							precision={2}
							labelLeft
							widthLabel={'65%'}
							widthInput={'35%'}
							label={"Расстояние в осях между ветвей h, (м): "}
							whisper={
								<Whisper
									placement={this.state.placementPopover}
									delayShow={this.state.delayShowPopover}
									trigger="hover"
									onEntering={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({id: "h"}))}
									onExited={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({id: "h", isHide: true}))}
									speaker={this.popoverSvg()}
								>
									<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
								</Whisper>
							}
							min={0}
							step={0.1}
							onValidate={this.validateField}
							enabledHandlerOnInput={true}
							isValid={!this.app.state.errors.hasOwnProperty('h')}
							onChange={this.customNumericInputHandler}
						/>
					</div>

					{(this.app.state.n > 2) &&
					<div className="form-group">
						<CustomNumericInput
							name={'c_two'}
							className="form-control input-text"
							value={this.app.state.c_two}
							allowNegative={false}
							measure={'[м]'}
							precision={2}
							labelLeft
							widthLabel={'65%'}
							widthInput={'35%'}
							label={"Расстояние по осям между крайними болтами ветвей c, (м): "}
							whisper={
								<Whisper
									placement={this.state.placementPopover}
									delayShow={this.state.delayShowPopover}
									delayHide={10000000}
									trigger="hover"
									onEntering={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({id: "c_two"}))}
									onExited={() => this.setSvgImgToPopover(this.svgTwoBranchesHandler({id: "c_two", isHide: true}))}
									speaker={this.popoverSvg()}
								>
									<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
								</Whisper>
							}
							min={0}
							step={0.1}
							onValidate={this.validateField}
							enabledHandlerOnInput={true}
							isValid={!this.app.state.errors.hasOwnProperty('c_two')}
							onChange={this.customNumericInputHandler}
						/>
					</div>
					}
				</div>
				}
				{/* END - Двухветвевая  */}

				{(this.app.state['тип_металлической_колонны'] === 'одноветвевая') &&
				<div>
					<div className="d-flex form-group">
						<label htmlFor="" style={styleMultilineLabel}>
							{lang('Количество растянутых болтов') + ': '}
							<Whisper
								trigger="hover"
								placement={this.state.placementPopover}
								delayShow={100}
								enterable={true}
								onEntering={() => this.setSvgImgToPopover(this.svgOneBranchHandler({name: "n"}))}
								onExited={() => this.setSvgImgToPopover(this.svgOneBranchHandler({name: "n", isHide: true}))}
								speaker={this.popoverSvg()}
							>
								<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
							</Whisper>
						</label>

						<Select
							menuPortalTarget={document.body}
							styles={stylesReactSelect}
							name={"n"}
							className={"w-35"}
							onChange={option => this.app.setState({n: Number(option.value)})}
							defaultValue={this.optionsBoltCount.find(option => option.value === this.app.state.n)}
							options={this.optionsBoltCount}
						/>
					</div>
					<div className="form-group">
						<CustomNumericInput
							name={'bp'}
							className="form-control input-text m-0"
							value={this.app.state['bp']}
							allowNegative={false}
							measure={'[м]'}
							precision={2}
							labelLeft
							widthLabel={'65%'}
							widthInput={'35%'}
							label={"Ширина анкерной плитки b, (м): "}
							whisper={
								<Whisper
									trigger="hover"
									placement={this.state.placementPopover}
									delayShow={this.state.delayShowPopover}
									onEntering={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "bp"}))}
									onExited={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "bp", isHide: true}))}
									speaker={this.popoverSvg()}
								>
									<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
								</Whisper>
							}
							min={0}
							step={0.1}
							onValidate={this.validateField}
							enabledHandlerOnInput={true}
							isValid={!this.app.state.errors.hasOwnProperty('bp')}
							onChange={this.customNumericInputHandler}
						/>
					</div>
					<div className="form-group">
						<CustomNumericInput
							name={'lp'}
							className="form-control input-text"
							value={this.app.state['lp']}
							allowNegative={false}
							measure={'[м]'}
							labelLeft
							widthLabel={'65%'}
							widthInput={'35%'}
							label={"Длина анкерной плитки l, (м): "}
							whisper={
								<Whisper
									trigger="hover"
									placement={this.state.placementPopover}
									full={true}
									enterable={true}
									delayShow={this.state.delayShowPopover}
									updatePosition={() => {}}
									onEntering={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "lp"}))}
									onExited={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "lp", isHide: true}))}
									speaker={this.popoverSvg()}
								>
									<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
								</Whisper>}
							min={0}
							step={0.1}
							precision={2}
							onValidate={this.validateField}
							enabledHandlerOnInput={true}

							isValid={!this.app.state.errors.hasOwnProperty('lp')}
							onChange={this.customNumericInputHandler}
						/>
					</div>


					{(this.app.state['база_колонны'] === 'высокая') &&

					<div>

						<div className="form-group">
							<CustomNumericInput
								name={'la'}
								className="form-control input-text"
								value={this.app.state.la}
								allowNegative={false}
								measure={'[м]'}
								labelLeft
								widthLabel={'65%'}
								widthInput={'35%'}
								label={"Расстояние от оси колонны до оси болта l<sub>a</sub>, (м): "}
								whisper={
									<Whisper
										placement={this.state.placementPopover}
										delayShow={this.state.delayShowPopover}
										trigger="hover"
										onEntering={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "la"}))}
										onExited={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "la", isHide: true}))}
										speaker={this.popoverSvg()}
									>
										<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
									</Whisper>
								}
								min={0}
								precision={3}
								step={0.1}
								onValidate={this.validateField}
								enabledHandlerOnInput={true}

								onMouseOver={this.svgOneBranchHandler}
								onMouseOut={this.svgOneBranchHandler}

								isValid={!this.app.state.errors.hasOwnProperty('la')}
								onChange={this.customNumericInputHandler}
							/>
						</div>
						{this.app.state.n > 1 &&
						<div className="form-group">
							<CustomNumericInput
								name={'ba'}
								className="form-control input-text"
								value={this.app.state.ba}
								allowNegative={false}
								measure={'[м]'}
								labelLeft
								widthLabel={'65%'}
								widthInput={'35%'}
								label={"Расстояние от оси колонны до оси болта b<sub>a</sub> (м): "}
								whisper={
									<Whisper
										placement={this.state.placementPopover}
										delayShow={this.state.delayShowPopover}
										trigger="hover"
										onEntering={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "ba"}))}
										onExited={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "ba", isHide: true}))}
										speaker={this.popoverSvg()}
									>
										<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
									</Whisper>
								}
								min={0}
								precision={3}
								step={0.1}
								onValidate={this.validateField}
								enabledHandlerOnInput={true}

								onMouseOver={this.svgOneBranchHandler}
								onMouseOut={this.svgOneBranchHandler}

								isValid={!this.app.state.errors.hasOwnProperty('ba')}
								onChange={this.customNumericInputHandler}
							/>
						</div>
						}
					</div>
					}

					{(this.app.state['база_колонны'] === 'низкая') &&
					<div>
						<div className="form-group">
							<CustomNumericInput
								name={'c'}
								className="form-control input-text"
								value={this.app.state.c}
								allowNegative={false}
								measure={'[м]'}
								precision={3}
								labelLeft
								widthLabel={'65%'}
								widthInput={'35%'}
								label={"Расстояние от оси болта до оси колонны с, (м): "}
								whisper={
									<Whisper
										placement={this.state.placementPopover}
										delayShow={this.state.delayShowPopover}
										trigger="hover"
										onEntering={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "c"}))}
										onExited={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "c", isHide: true}))}
										speaker={this.popoverSvg()}
									>
										<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
									</Whisper>
								}
								min={0}
								step={0.1}
								onValidate={this.validateField}
								enabledHandlerOnInput={true}
								isValid={!this.app.state.errors.hasOwnProperty('c')}
								onChange={this.customNumericInputHandler}
							/>
						</div>
						{(this.app.state.n > 1) &&
						<div className="mt-3 form-group">
							<CustomNumericInput
								name={'c_'}
								className="form-control input-text"
								value={this.app.state.c_}
								allowNegative={false}
								measure={'[м]'}
								precision={3}
								labelLeft
								widthLabel={'65%'}
								widthInput={'35%'}
								label={"Расстояние от оси болта до оси колонны с',(м): "}
								whisper={
									<Whisper
										placement={this.state.placementPopover}
										delayShow={this.state.delayShowPopover}
										trigger="hover"
										onEntering={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "c_"}))}
										onExited={() => this.setSvgImgToPopover(this.svgOneBranchHandler({id: "c_", isHide: true}))}
										speaker={this.popoverSvg()}
									>
										<FontAwesomeIcon icon={faInfoCircle} className="rs-icon-info"/>
									</Whisper>}
								min={0}
								step={0.1}
								onValidate={this.validateField}
								enabledHandlerOnInput={true}
								isValid={!this.app.state.errors.hasOwnProperty('c_')}
								onChange={this.customNumericInputHandler}
							/>
						</div>
						}

					</div>
					}
				</div>
				}

				<div className="col-md-5 position-relative">
					{(this.app.state['тип_колонны'] === 'металлическая') &&
					<div
						ref={this.myRef}
						className="imgS"
						style={{
							height: 0,
							zIndex: -1,
							opacity: 0
						}}
					>
						<object id="svg-object"  data={twoBranches} type="image/svg+xml" />
						<object id="lowTraversaSvg" data={lowTraversa} type="image/svg+xml" />
						<object id="highTraversaSvgL" data={highTraversaSvgL} type="image/svg+xml" />
						<object id="highTraversaSvgB" data={highTraversaSvgB} type="image/svg+xml" />
					</div>
					}
				</div>
			</div>
		);
	}
}

export default ColumnTypeMetal;