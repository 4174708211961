import React, { Component } from 'react';
import { ToggleButton,ToggleButtonGroup, Tab, Tabs } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import PillarFoundationSolve from '../PillarFoundationSolve'
import PillarFoundationCheck from '../PillarFoundationCheck'

import './index.css';
import {lang} from "../../../Modules/Global";
import PillarReport from "../PillarReport";

import {Provider} from "react-redux";
import {store} from "../../../store";
import $ from 'jquery';

class PillarFoundationProps extends Component {

	constructor(props) {
		super(props);

		this.app = this.props.app;
	}

	toggleButtonsGroupHandler = (value, e) =>{
		let name = e.target.name;

		if(name === 'тип_расчета'){
			if(value === 'check'){
				this.app.setState({'привязка_к_абсолютным_отметкам':false});
			}
		}

		if(name === 'тип_металлической_колонны'){
			if(value === 'двухветвевая' && this.app.state.n%2 !== 0) this.app.setState({n:2})
			if(value === 'одноветвевая' && this.app.state.n > 4) this.app.setState({n:2})
		}

		this.app.setState({
			[name]:value
		});

		if(name === 'тип_колонны' && value !== 'металлическая'){
			this.app.setState({anchorBoltHTMLReport:false})
		}
		if(name === 'тип_колонны' && value === 'металлическая'){
			this.app.setState({anchorBoltHTMLReport:true})
		}

	};

	paramsHandler = e =>{
		console.log('paramsHandler e', e);

		let name = e.target.name;
		let value = e.target.value;
		this.app.setState({[name]:value})
	};

	svgOneBranchHandler = ({
	   id,
	   name,
	   isHide = false
	}) => {
		let img;

		//$(document).find('.imgS object').css({opacity: 0});

		let isLSide = id === 'la' || id === 'lp';
		let isBSide = id === 'ba' || id === 'bp' || name === 'n';
		let isBolts = name === 'n';

		this.boltHandler(this.app.state.n);

		if(this.app.state['база_колонны'] === 'высокая') {
			if(isLSide) img = document.getElementById('highTraversaSvgL');
			if(isBSide) img = document.getElementById('highTraversaSvgB');

			if(!isLSide && !isBSide) return false;
		}

		if(this.app.state['база_колонны'] === 'низкая'){
			img = document.getElementById('lowTraversaSvg');
		}

		if (isHide)
			img.style.opacity = 0;
		else
			img.style.opacity = 1;

		const svgDoc = img.contentDocument;

		let items = svgDoc.getElementsByClassName(id);

		if(id === 'c_' || isBolts || id === 'ba'){
			let name = '';
			if(isBolts) name = 'n';
			if(id === 'ba') name = 'ba';
			if(id === 'c_') name = 'c_';

			items = svgDoc.getElementsByClassName(name + '-' + this.app.state.n);

		}

		for(let i = 0;i < items.length;i++){
			let item = items[i];
			if(item.classList.contains('no-color')) continue;

			if(item.hasAttribute('stroke')){

				item.setAttribute('stroke', isHide ? isBolts ? 'black' :'none' : 'red');
				item.setAttribute('stroke-width',isBolts?'0.5px':'1.5px');

			}else{
				if(item.getAttribute('fill') === '#f2f2f2') continue;
				item.setAttribute('fill', isHide ? isBolts ? 'black':'none':'red');
			}

		}

		return img;
	}

	svgTwoBranchesHandler = ({
		 id,
		 name,
		 isHide = false
	 }) => {
		let isBolts = name === 'n' || id === 'n';

		this.boltHandler(this.app.state.n);

		const img = document.getElementById('svg-object');

		if (isHide)
			img.style.opacity = 0;
		else
			img.style.opacity = 1;

		const svgDoc = img.contentDocument;

		const lines = svgDoc.getElementsByClassName(id);

		if(isBolts || id === 'c_two' || id === 'c_'){
			let name = id;
			const items = svgDoc.getElementsByClassName(name + '-' + this.app.state.n);

			for(let i = 0;i < items.length;i++){
				let item = items[i];

				if(!item.classList.contains('n-black')){
					item.setAttribute('stroke',isHide?isBolts?'black':'none':'red');
				}
				if(id ==='c_two'){
					const title = svgDoc.getElementsByClassName(id)[0];

					title.setAttribute('fill',isHide?'none':'red');
					item.setAttribute('stroke-width','3px');
				}
			}

			//return img;
		}

		for(let i = 0; i < lines.length;i++){

			if(lines[i].hasAttribute('stroke')){
				lines[i].setAttribute('stroke',isHide?'none':'red');
				lines[i].setAttribute('stroke-width','3px');
			}else{
				lines[i].setAttribute('fill',isHide?'none':'red');
			}
		}

		return img;
	}

	boltHandler = (number) =>{
		let svg;
		if(this.app.state['тип_металлической_колонны'] === 'двухветвевая'){
			svg = document.getElementById('svg-object');
		}else{
			if(this.app.state['база_колонны'] === 'низкая'){
				svg = document.getElementById('lowTraversaSvg');
			}else{
				svg = document.getElementById('highTraversaSvgB');
			}

		}
		const svgDoc = svg.contentDocument;

		const allBolts = svgDoc.getElementsByClassName('n');
		const bolts = svgDoc.getElementsByClassName('n-' + number);

		for(let i = 0;i < allBolts.length;i++){
			let bolt = allBolts[i];

			bolt.style.visibility = 'hidden';

		}

		for(let i = 0;i < bolts.length;i++){
			let bolt = bolts[i];

			bolt.style.visibility = 'visible';

		}
	}

	render() {
		return (
			<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="home" title="Исходные данные" tabClassName={"no-margin"}>
					<div>
						<div>
							<p>Выберите требуемый тип расчета:</p>
							<ToggleButtonGroup type="radio" className="w-100" name="тип_расчета" onChange={this.toggleButtonsGroupHandler} value={this.app.state['тип_расчета']}>
								<ToggleButton variant="custom w-50 lh-14" value={"solve"}>{lang('Расчет нового фундамента')}</ToggleButton>
								<ToggleButton
									variant="custom w-50 lh-14"
									value={"check"}>
									{lang('Проверка заданного')}
								</ToggleButton>
							</ToggleButtonGroup>
						</div>

						{(this.app.state['тип_расчета'] === 'solve') &&
						<PillarFoundationSolve app={this.app}
											   wrapper={this}/>
						}

						{/*<p className="text-center">{lang("Данный раздел находится в разработке")}!</p>*/}
						{(this.app.state['тип_расчета'] === 'check') &&
						<PillarFoundationCheck
							app={this.app}
							wrapper={this}/>
						}
					</div>
				</Tab>
				<Tab eventKey="profile" title="Параметры расчета" tabClassName={"no-margin"}>
					<PillarReport app={this.app} />
				</Tab>
			</Tabs>
		);
	}
}

export default PillarFoundationProps;
