import React , { Component } from "react";
import ReactTable from "react-table";
import { Form } from 'react-bootstrap'
import CustomNumericInput from "../CustomNumericInput/CustomNumericInput";
import deleteIcon from './../../img/trash.svg';
import copyIcon from './../../img/copy.svg';
import addIcon from './../../img/add.svg';
import { isEmpty,precision } from "../Global/Global";
import _ from 'lodash';
import "react-table/react-table.css";
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {lang} from "../../Modules/Global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faCopy} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

const $ = window.$;

const mapStateToProps = state => ({
	solve: state.solve
});

class SurveysTable extends Component {

	constructor(props) {
		super(props);

		this.columnsDisabledFields = {
			суглинок:['R'],
			глина:['R'],
			супесь:['R'],
			песок:['Ip','Il','R'],
			'крупнообломочный грунт':['Il','Ip','R']
		};

		this.app = this.props.app;
		this.isStilt = this.props.hasOwnProperty('isStilt');

		this.state = {
			fullTable: this.props.fullTable || false
		};
	}

	validateField = (props) => {

		const value = props.value;
		const name = props.name;

		let isValid = true,
			textError = 'Заполните поле';

		if(name.includes('глубина_залегания')){
			let id = +name.split('_')[2] + 1;
			if (value <= 0 ) {
				isValid = false;
				textError = 'Мощность ' + id + ' слоя не задана.';
			}
			if(isEmpty(value)) isValid = false;
		}
		return {
			isValid: isValid,
			textError: (isValid) ? '' : textError
		}
	};

	validateCell = (e,cellInfo,postfix) => {

		let value = e.target.value;
		let index = cellInfo.index + 1;
		let name = cellInfo.column.id + postfix + '_' + cellInfo.index;
		let column = cellInfo.column.id;
		let errors = this.app.state.errors;
		let gType = this.app.state.surveysData[cellInfo.index]['тип_грунта'];
		let subGType = this.app.state.surveysData[cellInfo.index]['подтип_грунта'];
		let isNotRock = gType !== 'скальные';
		let isValid = true,
			textError = 'Заполните поле';


		switch (column) {
			case 'плотность_p':
			{
				if(value < 1.5 || value > 2.5){
					isValid = false;
					textError = "Плотность при естественной влажности должна быть не менее 1.5 и не более 2.5";
				}
				if (isEmpty(value) && isNotRock){
					isValid = false;
					textError = "Не задана плотность ест.влажн на слое  " + index;
				}
			}
				break;
			case 'плотность_pd':
			{
				if(value < 1.3 || value > 2.5){
					isValid = false;
					textError = "Плотность сухого грунта должна быть не менее 1.3 и не более 2.5";
				}
				if (isEmpty(value)){
					isValid = false;
					textError = "Не задана плотность ест.влажн на слое  " + index;
				}
			}
				break;
			case 'плотность_ps':
			{
				if(isNotRock){
					if(value < 1.5 || value > 3){
						isValid = false;
						textError = "Плотность частиц грунта должна быть не менее 1.5 и не более 3";
					}
					if (isEmpty(value)){
						isValid = false;
						textError = "Не задана плотность частиц грунта на слое  " + index;
					}
				}
			}
				break;
			case 'Sr':
			{
				if(isNotRock) {
					if (value < 0 || value > 1) {
						isValid = false;
						textError = "Коэфицент водонасыщения должен быть не отрицательным и не более 1 ";
					}
					if (isEmpty(value)) {
						isValid = false;
						textError = "Не задан коэфициент водонасыщения на слое  " + index;
					}
				}
			}
				break;
			case 'e':
			{
				if(isNotRock){
					if(value < 0.2 || value > 1){
						isValid = false;
						textError = "Коэфицент пористости должен быть не менее 0.2 и не более 1";
					}
					if (isEmpty(value)){
						isValid = false;
						textError = "Не задан коэфициент пористости на слое  " + index;
					}
				}
			}
				break;
			case 'природная_влажность_W':
			{
				if(isNotRock){
					if(value < 0.01 || value > 100){
						isValid = false;
						textError = "Природная влажность должна быть не менее 0.01 и не более 100 ";
					}
					if (isEmpty(value)) isValid = false;
					textError = "Не задан коэфициент пористости на слое  " + index;
				}
			}
				break;
			case 'влажность_Wl':
			{
				if(isNotRock){
					if(value < 0 || value > 50){
						isValid = false;
						textError = "Влажность на границе текучести должена быть не отрицательным и не более 50";
					}
					if (isEmpty(value)) {
						isValid = false;
						textError = "Не задана влажность на границе текучести на слое  " + index;
					}

				}
			}
				break;
			case 'влажность_Wp':
			{
				if(isNotRock){
					if(value < 0 || value > 50){
						isValid = false;
						textError = "Влажность на границе раскатывания должена быть не отрицательным и не более 50";
					}
					if (isEmpty(value)) {
						isValid = false;
						textError = "Не задана влажность на границе раскатывания на слое  " + index;
					}

				}
			}
				break;
			case 'Esr':
			{
				if(isNotRock){
					if(value < 1 || value > 50){
						isValid = false;
						textError = "Модуль деформации в водонасыщенном состоянии должен быть не менее 1 и не более 50";
					}
					if (isEmpty(value) && isNotRock){
						isValid = false;
						textError = "Не задан модуль деформации в водонасыщенном состоянии на слое  " + index;
					}
				}

			}
				break;
			case 'Ip':
			{
				switch (gType) {
					case 'суглинок':
					case 'глина':
					case 'супесь':
					{
						if(value < 0 || value > 100){
							isValid = false;
							textError = "Число пластичности должно быть не отрицательным и не более 100";
						}
						if (isEmpty(value)) {
							isValid = false;
							textError = "Не задано число пластичности на слое " + index;
						}
					}
						break;
					case 'крупнообломочный грунт':
						if(subGType === 'глинистый_заполнитель'){
							if(value < 0 || value > 100){
								isValid = false;
								textError = "Число пластичности должно быть не отрицательным и не более 100";
							}
							if (isEmpty(value)) {
								isValid = false;
								textError = "Не задано число пластичности на слое " + index;
							}
						}
						break;
				}
			}
				break;
			case 'Il':
			{
				switch (gType) {
					case 'суглинок':
					case 'глина':
					case 'супесь':
					{
						if(value < -2 || value > 2){
							isValid = false;
							textError = "Показатель текучести должен быть не менее -2 и не более 2";
						}
						if (isEmpty(value)) {
							isValid = false;
							textError = "Не задан показатель текучести на слое  " + index;
						}
					}
						break;
					case 'крупнообломочный грунт':
						if(subGType === 'глинистый_заполнитель'){
							if(value < -2 || value > 2){
								isValid = false;
								textError = "Показатель текучести должен быть не менее -2 и не более 2";
							}
							if (isEmpty(value)) {
								isValid = false;
								textError = "Не задан показатель текучести на слое  " + index;
							}
						}
						break;
				}
			}
				break;
			case 'E':
			{
				if(value < 1 || value > 100){
					isValid = false;
					textError = "Модуль деформации при естественной влажности должен быть не менее 1 и не более 100";
				}
				if (isEmpty(value) && isNotRock){
					isValid = false;
					textError = "Не задан модуль деформации при естественной влажности на слое  " + index;
				}
			}
				break;
			case 'Ee':
			{
				if(this.app.state['геотехническая_категория'] === 3 && isNotRock){
					if(value < 1 || value > 250){
						isValid = false;
						textError = "Модуль деформации грунта по ветви вторичного нагружения должен быть не менее 1 и не более 250";
					}
					if (isEmpty(value)) {
						isValid = false;
						textError = "Не задан Модуль деформации грунта по ветви вторичного нагружения на слое  " + index;
					}
				}
			}
				break;
			case 'filter':
			{
				if(value < 0 || value > 100){
					isValid = false;
					textError = "Коэфициент фильтрации должен быть не отрицательным и не более 100";
				}
				if (isEmpty(value)) {
					isValid = false;
					textError = "Не задан коэфициент фильтрации на слое  " + index;
				}
			}
				break;
			case 'сцепление_C':
				if(isNotRock){
					if(value < 0.1){
						isValid = false;
						textError = "Удельное сцепление должно быть не меньше 0.1 и не более 50";
					}
					if(value > 50){
						isValid = false;
						textError = "Удельное сцепление должно быть не меньше 0.1 и не более 50";
					}
					if (isEmpty(value) ) {
						isValid = false;
						textError = 'Не задано удельное сцепление грунта на слое' + index;
					}
				}

				break;
			case 'R':
				if(!isNotRock){
					if(value < 5 || value > 20){
						isValid = false;
						textError = "Расчетное сопротивление скального грунта должно быть не менее 5 и не более 20";
					}
					if (isEmpty(value)) {
						isValid = false;
						textError = 'Не задано расчетное сопротивление скального грунта на слое ' + index;
					}
				}

				break;
			case 'particles':
				if(value < 0 || value > 100){
					isValid = false;
					textError = "Количество песчаных частиц должно быть не отрицательным и не более 100";
				}
				if (isEmpty(value)) {
					isValid = false;
					textError = 'Не заданно количество песчаных частиц на слое ' + index;
				}
				break;
			case 'Угол_внутреннего_трения':
				if (value < 0 || value > 45) {
					isValid = false;
					textError = 'Угол внутреннего трения должен быть от 0 до 45';
				}
				if (isEmpty(value) && isNotRock) {
					isValid = false;
					textError = 'Не задан угол внутреннего трения на слое ' + index;
				}
				break;
		}


		if(e.target.disabled){
			isValid = true;
		}
		if(!isValid){

			e.target.classList.add('error-table');
			errors[name] = {
				textError:textError,
				tab:'2'
			};

		}else{
			e.target.classList.remove('error-table');
			delete  errors[name];

		}
		this.app.setState({
			errors:errors
		});

	};
	addNewRow = () => {
		let data = this.props.data.surveysData;
		let newLayputParamsRow = {
			"глубина_залегания":0,
			"подтип_грунта":'',
			"тип_грунта":'суглинок',
			E_1: '',
			Ee_1: '',
			Esr_1: '',
			Il_1: '',
			Ip_1: '',
			Sr_1: '',
			e_1: '',
			R_1: '',
			filter_1: '',
			particles_1: '',
			'Угол_внутреннего_трения_1': '',
			'влажность_Wl_1': '',
			'влажность_Wp_1': '',
			'плотность_p_1': '',
			'плотность_pd_1': '',
			'плотность_ps_1': '',
			'природная_влажность_W_1': '',
			'сцепление_C_1': '',
			E_2: '',
			Ee_2: '',
			Esr_2: '',
			Il_2: '',
			Ip_2: '',
			Sr_2: '',
			e_2: '',
			R_2: '',
			filter_2: '',
			particles_2: '',
			'Угол_внутреннего_трения_2': '',
			'влажность_Wl_2': '',
			'влажность_Wp_2': '',
			'плотность_p_2': '',
			'плотность_pd_2': '',
			'плотность_ps_2': '',
			'природная_влажность_W_2': '',
			'сцепление_C_2': '',
			E_3: '',
			Ee_3: '',
			Esr_3: '',
			Il_3: '',
			Ip_3: '',
			Sr_3: '',
			e_3: '',
			R_3: '',
			filter_3: '',
			particles_3: '',
			'Угол_внутреннего_трения_3': '',
			'влажность_Wl_3': '',
			'влажность_Wp_3': '',
			'плотность_p_3': '',
			'плотность_pd_3': '',
			'плотность_ps_3': '',
			'природная_влажность_W_3': '',
			'сцепление_C_3': ''
		};
		if(!_.isEqual(data[data.length - 1], newLayputParamsRow)){
			data.push(newLayputParamsRow);
			this.app.setState({
				'surveysData':data
			});
		}
	};

	renderCreateRow = () => {
		return(
			<div className={'layouts-controll pt-2'}>
				<div onClick={this.addNewRow} className={'btn btn-secondary'}>
					<img
						src={addIcon}
						alt=''
					/>
				</div>
			</div>
		)
	};

	customNumericInputHandler = (value, name , validator,cellInfo) => {
		let errors = this.app.state.errors;
		name = name + '_' + cellInfo.index;

		if (!validator.isValid) errors[name] = {
			textError: validator.textError,
			tab: '2'
		};
		else delete errors[name];

		const data = this.props.data.surveysData;

		data[cellInfo.index][cellInfo.column.id] = value;

		this.app.setState({
			'surveysData': data,
			errors: errors
		});

	};

	solveIpIl = (cellInfo,index) => {
		const data = this.props.data.surveysData;

		if(cellInfo.column.id === 'влажность_Wl' || cellInfo.column.id === 'влажность_Wp' || cellInfo.column.id === 'природная_влажность_W' ){
			if(data[cellInfo.index]['влажность_Wp'+ index] && data[cellInfo.index]['влажность_Wl'+ index] ){
				let wp = data[cellInfo.index]['влажность_Wp'+ index]/100;
				let wl = data[cellInfo.index]['влажность_Wl'+ index]/100;
				let sum = Math.round(((wl - wp) * 100) * 100) / 100;
				data[cellInfo.index]['Ip' + index] =  (!isNaN(sum)  && isFinite(sum))?sum:'ОШИБКА';
			}
			if(data[cellInfo.index]['влажность_Wp'+ index] && data[cellInfo.index]['влажность_Wl'+ index] && data[cellInfo.index]['природная_влажность_W'+ index]){
				let wp = data[cellInfo.index]['влажность_Wp'+ index]/100;
				let wl = data[cellInfo.index]['влажность_Wl'+ index]/100;
				let w = data[cellInfo.index]['природная_влажность_W'+ index]/100;
				let sum = Math.round(((((w) - wp)/(wl - wp))) * 100) / 100;
				data[cellInfo.index]['Il' + index] = (!isNaN(sum) && isFinite(sum))?sum:'ОШИБКА';
			}
		}
	};

	isDisabledCell = (cellInfo) => {

		const data = this.props.data.surveysData;
		let columnName = cellInfo.column.id;
		let gType = data[cellInfo.index]['тип_грунта'];
		let subGType = data[cellInfo.index]['подтип_грунта'];
		let disabled = false;
		let deep = 0;

		for (let i = 0;i < cellInfo.index;i++){
			deep += data[i]['глубина_залегания'];
		}

		if(gType === 'крупнообломочный грунт' && subGType === 'глинистый_заполнитель'){

			disabled = false;

		}else if(gType === 'скальные'){

			if(columnName !== 'R') disabled = true;
			else disabled = false;

		}else{

			disabled = this.columnsDisabledFields[gType].some(item =>{
				return item === columnName;
			})

		}
		let gr = (!this.app.state['планируемость_территории'])?this.app.state['глубина_слоя']:0;
		let dw = this.app.state.dw - gr;
		if(dw <= deep && this.app.state.dw !== 0){
			if(columnName === 'E'){
				disabled = true;
			}
		}
		return disabled;
	};

	tabImitation = (e) =>{
		if(e.keyCode === 13 || e.keyCode === 9){

			let newTarget = $(e.target).next();
			do{
				if(newTarget.next().length === 0){
					newTarget = newTarget.parent().parent().next().children().children().first();
				}else{
					newTarget = newTarget.next();
				}
			}while (newTarget.prop('disabled') || newTarget.hasClass('hide-cell'))

			newTarget.focus();
		}
	};

	hideCell = (cellInfo,index) =>{
		let show = [
			['e','плотность_pd','плотность_ps','Sr','природная_влажность_W','влажность_Wp','влажность_Wl','Ip','Il','particles','filter'],
			['плотность_p','E','Esr','Ee','сцепление_C','Угол_внутреннего_трения','R'],
			['']
		];

		if(this.isStilt && this.app.state['поперечное_сечение'] === 'пирамидальная'){
			let a_ = (this.app.state['a'] - this.app.state['d'])/2;
			if(a_/this.app.state['hs'] > 0.025){
				show[2].push('плотность_p','Угол_внутреннего_трения','сцепление_C')
			}
		}
		let id = index - 1;
		return show[id].includes(cellInfo.column.id)?'':' hide-cell';
	};
	renderEditable = (cellInfo) => {

		const data = this.props.data.surveysData;
		let disabled = this.isDisabledCell(cellInfo);
		let className = (disabled)?'p-1 disabled': 'p-1';
		return (
			<div className="d-flex flex-column justify-content-around h-100">
				<input
					id={cellInfo.column.id + '_1_' + cellInfo.index}
					type={'text'}
					className={className + ' table-field' + this.hideCell(cellInfo,1)}
					disabled={disabled}
					onBlur={(e) => {
						this.validateCell(e,cellInfo,'_1')
						this.solveIpIl(cellInfo,'_1');
					}}
					onKeyDown={this.tabImitation}
					onChange ={e => {
						this.validateCell(e, cellInfo, '_1');

						let val = e.target.value.replace(/[,]/g, ".")
						if(precision(val) > 3) return false;
						if (!($.isNumeric(val) || val === '' || val === '-')) return false;
						if(cellInfo.column.id !== 'Il' && val === '-') return false;
						data[cellInfo.index][cellInfo.column.id + '_1'] = val;

						this.app.setState({"surveysData": data});
					}
					}

					value={disabled ? '-' : this.app.state.surveysData[cellInfo.index][cellInfo.column.id + "_1"]}
				/>
				<input
					id={cellInfo.column.id + '_2_' + cellInfo.index}
					type={'text'}
					className={className + ' table-field' + this.hideCell(cellInfo,2)}
					disabled={disabled}
					onBlur={(e) => {
						this.validateCell(e, cellInfo, '_2')
						this.solveIpIl(cellInfo, '_2');
					}}
					onKeyDown={this.tabImitation}
					onChange={e => {

						this.validateCell(e, cellInfo, '_2');

						let val = e.target.value.replace(/[,]/g, ".")
						if (precision(val) > 3) return false;
						if (!($.isNumeric(val) || val === '' || val === '-')) return false;
						if (cellInfo.column.id !== 'Il' && val === '-') return false;

						data[cellInfo.index][cellInfo.column.id + '_2'] = val;
						this.app.setState({"surveysData": data});

					}
					}
					value={disabled ? '-' : this.app.state.surveysData[cellInfo.index][cellInfo.column.id + "_2"]}
				/>
				<input
					id={cellInfo.column.id + '_3_' + cellInfo.index}
					type={'text'}
					className={className + ' table-field' + this.hideCell(cellInfo,3)}
					disabled={disabled}
					onKeyDown={this.tabImitation}
					onBlur={(e) => {
						this.validateCell(e, cellInfo, '_3')
						this.solveIpIl(cellInfo, '_3');
					}}
					onChange={e => {

						this.validateCell(e, cellInfo, '_3');

						let val = e.target.value.replace(/[,]/g, ".")
						if (precision(val) > 3) return false;
						if (!($.isNumeric(val) || val === '' || val === '-')) return false;
						if (cellInfo.column.id !== 'Il' && val === '-') return false;

						data[cellInfo.index][cellInfo.column.id + '_3'] = val;

						this.app.setState({'surveysData': data});


					}}
					value={disabled ? '-' : this.app.state.surveysData[cellInfo.index][cellInfo.column.id + "_3"]}
				/>
			</div>

		);
	};
	renderControllButtons = (cellInfo) => {
		const data = this.props.data.surveysData;
		return (
			<div className='layouts-controll'>
				<button className="btn btn-sm"
						title="Удалить строку"
						onClick={e => {
							let keys = Object.keys(data[cellInfo.row._index]);
							let errors = this.app.state.errors;


							Object.keys(errors).forEach( key =>{
								if(keys.includes(key.slice(0,-2))){
									delete errors[key];
								}
							});

							data.splice(cellInfo.row._index,1);
							this.app.setState({
								'surveysData':data,
								errors:errors
							});
						}}>
					<FontAwesomeIcon icon={faTrashAlt} className="rs-icon-info"/>
				</button>
				<button className="btn btn-sm"
						title="Копировать строку"
						onClick={e => {
							let newParams = _.cloneDeep(data);
							let newLayoutParamsRow = newParams[cellInfo.row._index];
							data.push(newLayoutParamsRow);
							this.app.setState({'surveysData': data})
						}}>
					<FontAwesomeIcon icon={faCopy} className="rs-icon-info"/>
				</button>
			</div>
		);
	};
	renderIGEoption = (cellInfo) => {
		const data = this.props.data.surveysData;
		const groundType = data[cellInfo.index]['тип_грунта'];
		return (
			<Form.Group className='mb-0 p-2'>

				<CustomNumericInput
					className="form-control input-text"
					name={"глубина_залегания" + "_" + cellInfo.index}
					allowNegative={true}
					measure={'[м]'}
					label={''}
					step={0.1}
					enabledHandlerOnInput={true}
					onValidate={this.validateField}
					isValid={true}
					onChange = {(value, name , validator)=>{
						this.customNumericInputHandler(value, name , validator,cellInfo);
					}
					}
					value = {data[cellInfo.index]['глубина_залегания']}
				/>
				<Form.Group className='mb-0'>
					<span className='fz-12'>Тип грунта</span>
					<select
						onChange = {e => {

							let value = e.target.value;
							data[cellInfo.index]['тип_грунта'] = value;

							switch (value) {
								case 'крупнообломочный грунт':
									data[cellInfo.index]['подтип_грунта'] = 'глинистый_заполнитель';
									break;
								case 'песок':
									data[cellInfo.index]['подтип_грунта'] = 'пылеватый';
									break;
								default :
									data[cellInfo.index]['подтип_грунта'] = '';
									break;
							}

							this.app.setState({ 'surveysData':data })
						}
						}
						value = {data[cellInfo.index]['тип_грунта']}
						className='select form-control w-100'>
						<option value='суглинок'>Суглинок</option>
						<option value='глина'>Глина</option>
						<option value='супесь'>Супесь </option>
						<option value='песок'>Песок</option>
						<option value='крупнообломочный грунт'>Крупнообломочные грунты</option>
						<option value='скальные'>Скальные</option>
					</select>
					{(groundType === 'крупнообломочный грунт' || groundType === 'песок') &&
					<select
						onChange={e => {
							data[cellInfo.index]['подтип_грунта'] = e.target.value;
							this.app.setState({'surveysData': data});
						}}
						className='select form-control mt-1 w-100'>
						{(groundType === 'крупнообломочный грунт') &&
						<>
							<option value='глинистый_заполнитель'>с глинистым заполнителем</option>
							<option value='песчаный_заполнитель'>с песчаным заполнителем</option>
						</>
						}
						{(groundType === 'песок') &&
						<>
							<option value='пылеватый'>Пылеватый (d > 0.10)</option>
							<option value='мелкий'>Мелкий (d > 0.10)</option>
							<option value='гравелистый'>Гравелистый (d > 0.2)</option>
							<option value='крупный'>Крупный (d > 0.5)</option>
							<option value='средней_крупности'>Средней крупности (d > 0.25)</option>
						</>
						}
					</select>
					}
				</Form.Group>
			</Form.Group>
		);
	};
	renderStatIndicators = () =>{
		return(
			<div className="d-flex flex-column justify-content-around h-100 fz-12">
				<div className='p-1' > Норм. значение</div>
				<div className='p-2' > a = 0.85 </div>
				<div className='p-2' > a = 0.95 </div>
			</div>
		)
	};

	handleTableSize = (e) =>{
		let tableSize = (e.target.checked) ? 'advanced' : 'normal';
		this.setState({tableSize: tableSize});
		this.app.setState( {tableSize: tableSize});
	};

	render() {

		let clay = this.app.state.surveysData.some(item =>{

			let gType = item['тип_грунта'];
			let subGType = item['подтип_грунта'];

			let clays = ['суглинок','глина','супесь'];

			return (clays.includes(gType) || (gType === 'крупнообломочный грунт' && subGType === 'глинистый_заполнитель'));
		});

		let rock  = this.app.state.surveysData.some(item =>{
			return ((item['тип_грунта'] === 'скальные'));
		});

		let dw = false;

		if(!isEmpty(this.app.state.surveysData)){
			let layoutSum = 0;
			this.app.state.surveysData.forEach( e => { layoutSum += Number(e['глубина_залегания'])});
			dw = (parseFloat(this.app.state.dw) <= layoutSum && this.app.state['dw'] !== 0);
		}

		const tableWidth = (window.outerWidth >= 1400) ? 1000 : 600;
		let columnLength = 0;

		if(this.app.state.tableSize === 'normal') columnLength = 12;
		else columnLength = 18;

		if(!clay) columnLength -= 2;
		if(!rock) columnLength -= 1;
		if(!dw) columnLength -= 2;
		if(this.app.state['геотехническая_категория'] !== 3) columnLength -= 1;

		let columns = {
			advanced:[
				{
					Header:'ИГЭ',
					columns:[

						{
							Header:'Мощность слоя',
							accessor:'глубина_залегания',
							Cell:this.renderIGEoption,
							sortable:false,
							// width: 150
						},
						{
							Header:'Стат. показатели',
							accessor:'стат_показатели',
							sortable:false,
							Cell:this.renderStatIndicators,
							// width: 70
						},
					],
					// width: 220
				},
				{
					Header: 'Плотность г/см3',
					columns: [
						{
							Header: 'естеств. влажн. ρ',
							accessor: 'плотность_p',
							Cell: this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
						{
							Header: 'сухого грунта ρd',
							accessor: 'плотность_pd',
							Cell: this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
						{
							Header: 'частиц грунта ρs',
							accessor: 'плотность_ps',
							Cell: this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
					],
					// width:(tableWidth/columnLength)*3
				},
				{
					Header:'коэф порист. e',
					accessor:'e',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'коэф. водонасыщения Sr',
					accessor:'Sr',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Природная влажность W %',
					accessor:'природная_влажность_W',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Влажность на границе %',
					columns:[
						{
							Header:'текучести Wl',
							accessor:'влажность_Wl',
							Cell:this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
						{
							Header:'раскатывания Wp',
							accessor:'влажность_Wp',
							Cell:this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						}
					],
					// width:(tableWidth/columnLength)*2
				},
				{
					Header:'число пластичн. Ip %',
					accessor:'Ip',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'показатель текучести Il при Sr= 0,9 , дол.ед ',
					accessor:'Il',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Модуль деформации МПа',
					columns:[
						{
							Header:'естественной влажн. E',
							accessor:'E',
							Cell:this.renderEditable,
							width:tableWidth/columnLength
						},
						{
							Header:'Водонасыщ. сост.',
							accessor:'Esr',
							Cell:this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
						{
							Header:'По ветви вторичного нагружения Ee',
							accessor:'Ee',
							Cell:this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
					],
					// width:(tableWidth/columnLength)*3
				},
				{
					Header:'Удельное сцепление С КПа',
					accessor:'сцепление_C',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Угол внутреннего трения φ град.',
					accessor:'Угол_внутреннего_трения',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Содержание песч. частиц (2-0,05мм)%',
					accessor:'particles',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Коэфицент фильтрации м/сут',
					accessor:'filter',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Расчетное сопротивление скального грунта R Мпа',
					accessor:'R',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:this.renderCreateRow,
					Cell:this.renderControllButtons,
					sortable:false,
					width:55,
				},

			],
			normal:[
				{
					Header:'ИГЭ',
					columns:[

						{
							Header:'Мощность слоя',
							accessor:'глубина_залегания',
							Cell:this.renderIGEoption,
							sortable:false,
							// width:125
						},
						{
							Header:'Стат. показатели',
							accessor:'стат_показатели',
							sortable:false,
							Cell:this.renderStatIndicators,
							// width:68
						},
					],
					// width:200
				},
				{
					Header: 'Плотность г/см3',
					columns: [
						{
							Header: 'естеств. влажн. ρ',
							accessor: 'плотность_p',
							Cell: this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
						{
							Header: 'частиц грунта ρs',
							accessor: 'плотность_ps',
							Cell: this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
					],

				},
				{
					Header:'коэф порист. e',
					accessor:'e',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'коэф. водонасыщения Sr',
					accessor:'Sr',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'число пластичн. Ip %',
					accessor:'Ip',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'показатель текучести Il при Sr=0,9 , дол.ед ',
					accessor:'Il',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Модуль деформации МПа',
					columns:[
						{
							Header:'естественной влажн. E',
							accessor:'E',
							Cell:this.renderEditable,
							width:tableWidth/columnLength
						},
						{
							Header:'Водонасыщ. сост.',
							accessor:'Esr',
							Cell:this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
						{
							Header:'По ветви вторичного нагружения Ee',
							accessor:'Ee',
							Cell:this.renderEditable,
							sortable:false,
							width:tableWidth/columnLength
						},
					],
				},
				{
					Header:'Удельное сцепление С КПа',
					accessor:'сцепление_C',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Угол внутреннего трения φ град.',
					accessor:'Угол_внутреннего_трения',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:'Расчетное сопротивление скального грунта R Мпа',
					accessor:'R',
					Cell:this.renderEditable,
					sortable:false,
					width:tableWidth/columnLength
				},
				{
					Header:this.renderCreateRow,
					Cell:this.renderControllButtons,
					sortable:false,
					width:tableWidth/columnLength
					// width:55
				},
			]};


		if(!clay){
			columns.normal = columns.normal.filter(obj => obj.accessor !== "Ip" && obj.accessor !== 'Il');
			columns.advanced = columns.advanced.filter(obj => obj.accessor !== "Ip" && obj.accessor !== 'Il');
		}
		if(!rock){
			columns.normal = columns.normal.filter(obj => obj.accessor !== "R");
			columns.advanced = columns.advanced.filter(obj => obj.accessor !== "R");
		}
		if(!dw){

			columns.normal = columns.normal.filter(obj => obj.accessor !== 'e')
			let n = columns.normal[1];
			n.columns = n.columns.filter(obj => obj.accessor !== 'плотность_ps');
			columns.normal[1] = n;

			columns.advanced = columns.advanced.filter(obj => obj.accessor !== 'e')
			let a = columns.advanced[1];
			a.columns = a.columns.filter(obj => obj.accessor !== 'плотность_ps');
			columns.advanced[1] = a;

		}

		if(this.app.state['геотехническая_категория'] !== 3){

			columns.normal = columns.normal.map((item)=>{
				if(item.hasOwnProperty('columns')){
					item.columns = item.columns.filter(obj => obj.accessor !== 'Ee');
					return item;

				}
				return item;
			});

			columns.advanced = columns.advanced.map((item)=>{
				if(item.hasOwnProperty('columns')){
					item.columns = item.columns.filter(obj => obj.accessor !== 'Ee');
					return item;
				}
				return item;
			});

		}


		return (
			<div className="survaysTable container-loads">
				<div className="p-0">
					<h1 className="app-page-title">Инженерно-геологические изыскания</h1>
				</div>

				{this.state.fullTable &&
				<div className="col">
					<div className="switch-wrapper">
						<label className="switch" style={{left: 0, position: 'relative', top: 3}}>
							<input type="checkbox"
								   name="tableSize"
								   defaultChecked={this.app.state.tableSize === 'advanced'}
								   onChange={this.handleTableSize}/>
							<span className="slider"></span>
						</label>
						<span className="switch-label" style={{marginLeft: 0}}>{lang('Расширенная таблица')}</span>
						<div className="clearfix"></div>
					</div>
				</div>
				}

				<ReactTable
					className="layoutsParams"
					noDataText={
						<div>
							<h4>{lang("Грунтовые слои не добавлены")}</h4>
							<div
								className={'btn btn-secondary mt-2 pointer'}
								onClick={this.addNewRow}
							>Добавить новый слой</div>
						</div>
					}
					data={this.props.data.surveysData}
					columns={columns[this.app.state.tableSize]}
					style={{
						width:'100%',
						height:'100%'
					}}
					pageSize={this.props.data.surveysData.length}
					showPagination={false}
					sortable={false}
					resizable={false}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps)(SurveysTable);