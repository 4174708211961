import config from "../Model/Config";
import _ from "lodash";
const {url} = config;

/**
 * Функция, возвращающая массив данных с конструкциями сооружений
 * (данные коэффиценита Su в зависомтси  от типа здания)
 * @returns {Promise<any>}
 */
export const getSu = () => fetch(url + "/api/su")
	.then(response => {
		return response.json();
	})
	.then(items => {
		return items.map(item => {
			return {
				id: Number(item.id),
				parentId: Number(item.parent_id),
				value: Number(item.id),
				Su: item.Su,
				label: item.title,
				// isDisabled: item.isDisabled,
				kren: item.iu,
				type: item.type,
			}
		});
	});

/**
 * Функция, возвращающая коллекцию городов для выбора города строительства
 * @returns {Promise<Array>}
 */
export const getCities = () => fetch(url + "/api/cities")
	.then(response => {
		return response.json();
	})
	.then(items => {
		return _.sortBy(items.map(item => {
			return {
				value: Number(item.id),
				label: item.city.replace("*", "")
			}
		}), ["label"]);
	});


/**
 * Функция, возвращающая коллекцию акеров
 * @returns {Promise<any>}
 */
export const getAnchors = () => fetch(url + "/api/anchors")
	.then((response) => {
		return response.json();
	})
	.then(items => {
		return items.map(item => {
			return {
				d: item.d,
				t: item.type,
			}
		});
	});