import React, { Component } from 'react';
import CitySelector from "../../../Common/CitySelector";
import PillarSurveys from "../../PillarSurveys";

class CommonGeoParameters extends Component {

	constructor(props) {
		super(props);

		this.app = this.props.app;
	}

	render() {
		return (
			<div>
				<CitySelector app={this.app}/>
				<PillarSurveys app={this.app}/>
			</div>
		);
	}
}

export default CommonGeoParameters;