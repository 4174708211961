/**
 * Компонент расчета столбчатого фундамента
 */
import "rsuite/dist/styles/rsuite-default.min.css";
import React, { Component } from "react";
import config from "../../../Model/Config";
import PillarLoads from "../PillarLoads";
import deleteIcon from "../../../img/trash.svg";
import Surveys from "../PillarSurveys";
import SurveysTable from "../../SurveysTable";
import PillarFoundationProps from "../PillarFoundationProps/index.jsx";
import PillarReport from "../PillarReport/index.jsx";
import Errors from "../../Errors/index.jsx";
import { Notification } from "rsuite";
import { isEmpty } from "../../Global/Global";
import "bootstrap/dist/css/bootstrap.min.css";
import rightArrow from "../../../img/right-arrow.svg";
import leftArrow from "../../../img/left-arrow.svg";
import Draggable from "react-draggable"; // The default
import { DraggableCore } from "react-draggable"; // <DraggableCore>
import "./index.css";
import _ from 'lodash';
import $ from 'jquery';

import initialState from "../Models/Model";
import PillarScheme from "../PillarScheme";
import {connect, useDispatch, useSelector} from "react-redux";
import store from "../../../store";

import {appShowLoading, appHideLoading} from "../../../store/slices/appSlice";

import {Button} from "react-bootstrap";
import {lang, saveSvgAsImage} from "../../../Modules/Global";
import {toggleSidebarShow} from "../../../store/slices/sidebarsSlice";
import Sidebar from "../../Common/Sidebar";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faChevronLeft, faInfoCircle, faPlay} from "@fortawesome/free-solid-svg-icons";
import twoBranches from "../../../img/twoBranches.svg";
import lowTraversa from "../../../img/lowTraversa.svg";
import highTraversaSvgL from "../../../img/highTraversaSvgL.svg";
import highTraversaSvgB from "../../../img/highTraversaSvgB.svg";
import Container from "react-bootstrap/Container";
import {fetchSu} from "../../../store/asyncSlices/suSlice";
import {fetchCities} from "../../../store/asyncSlices/citiesSlice";

import html2canvas from "html2canvas";

const URL = config.url;
// const GLOBALS = window.GLOBALS || {};

class PillarApp extends Component {

	constructor(props) {
		super(props);

		this.props = props;

		this.dispatch = props.dispatch;

		this.state = $.extend(true,
			{},
			this.getCurrentStateFromStore(),
			initialState,
			{
				fileName: "",
				tabs: "1",
				disabledTabs: ["4"],
				tableSize: "normal",
				calculationSuccess: false
			}
		);

		this.dispatch(fetchSu());
		this.dispatch(fetchCities());

		this.bolts = [];

		// Доступные города
		this.optionsCities = [];

		this.storeListeners = {};
	}

	componentWillMount() {
		this.dispatch(() => {
			setTimeout(() => {
				this.dispatch(appHideLoading())
			}, 2000)
		});
	}

	componentDidMount() {
		// Подпишемся на измнение стора
		this.storeListeners.updateStateFromStore = store.subscribe(this.updateStateFromStore);

		$(document).on('click', '.tab-clickable', function(e) {
			$(this).parents('.left-tabs').find('.tab-clickable').removeClass('active');
			$(this).addClass('active');
		});
	}

	getCurrentStateFromStore() {
		return {
			...store.getState().sidebars,

			...store.getState().app,

			...store.getState().solve,

			fetched: store.getState().fetched
		}
	}

	updateStateFromStore = () => {
		const currentState = this.getCurrentStateFromStore();

		if (!_.isEqual(this.state, currentState))
			this.setState(currentState);
	};

	componentWillUnmount() {
		Object.keys(this.storeListeners).forEach(listenerKey => {
			this.storeListeners[listenerKey]();
		});
	}

	saveData() {
		localStorage.setItem("data", JSON.stringify(this.state));
	}

	deleteData() {
		localStorage.removeItem("data");
	}

	getData() {
		this.setState({ ...JSON.parse(localStorage.getItem("data")) });
	}

	solveFoundation() {
		(async () => {
			const rawResponse = await fetch(URL + "/solver", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(this.state),
			});
			const content = await rawResponse.json();
		})();
	};

	showErrorFields(errors) {
		Object.keys(errors).forEach((key) => {
			let el = document.querySelector("input#" + key);
			let evt = new Event("blur");
			if (el) el.dispatchEvent(evt);
		});
	};

	validateCheckSteps(errors) {
		let sides = ["l", "b"];

		sides.forEach((side) => {
			for (let i = 1; i <= 5; i++) {
				if (i > this.state["n" + side]) {
					delete errors["x" + side + "-" + i];
					delete errors["h" + side + "-" + i];
				} else {
					let xSide = this.state["x" + side][i];
					let hSide = this.state["h" + side][i];

					let xlValid = true;
					let hlValid = true;

					if (
						(xSide < 0.1 ||
							xSide > 7 ||
							isEmpty(xSide) ||
							isNaN(xSide)) &&
						this.state["тип_расчета"] === "check"
					) {
						xlValid = false;
					}

					if (
						(hSide < 0.2 ||
							hSide > 0.45 ||
							isEmpty(hSide) ||
							isNaN(hSide)) &&
						this.state["тип_расчета"] === "check"
					) {
						hlValid = false;
					}

					if (!xlValid) errors["x" + side + "-" + i] = {};
					else delete errors["x" + side + "-" + i];

					if (!hlValid) errors["h" + side + "-" + i] = {};
					else delete errors["h" + side + "-" + i];
				}
			}
		});

		if (
			this.state.nl + this.state.nb === 0 &&
			this.state["тип_расчета"] === "check" &&
			this.state.reinforcement
		) {
			errors["nl"] = {};
			errors["nb"] = {};
		} else {
			delete errors["nb"];
			delete errors["nl"];
		}

		return errors;
	};

	validateDataAfterSubmit() {
		let errors = this.state.errors;

		// Валидация полей без таблицы
		Object.keys(this.state).forEach((key) => {
			const value = this.state[key];
			const name = key;

			let isValid = true;

			switch (name) {
				case "dw":
					let deep = 0;
					this.state.surveysData.forEach(
						(item) => (deep += item.глубина_залегания)
					);
					isValid = true;
					if (deep < value && value !== 0) isValid = false;
					if (value < 0) isValid = false;
					break;
				case "Su":
					if (value < 0) isValid = false;
					break;
				case "соотношение_значение":
					if (
						this.state["рассчет_LB"] === "соотношение" &&
						this.state["форма_подошвы"] === "прямоугольная"
					) {
						if (value < 0 || value > 1) isValid = false;
					}
					break;
				case "глубина_слоя":
					if (!this.state["планируемость_территории"]) {
						if (
							value > this.state["глубина_заложения"] &&
							!isEmpty(this.state["глубина_заложения"])
						) {
							isValid = false;
						}
						if (isEmpty(value)) isValid = false;
					}
					break;
				case "глубина_заложения":
					if (value < 0.6 || value > 10) isValid = false;
					if (isEmpty(value)) isValid = true;
					if (this.state["планируемость_территории"] === "false") {
						if (
							value < this.state["глубина_слоя"] &&
							!isEmpty(value)
						) {
							isValid = false;
						}
					}
					break;
				case "высота_фундамента":
					if (
						this.state["тип_расчета"] === "check" &&
						this.state["anchor"] &&
						!this.state["reinforcement"] &&
						!this.state["size"]
					) {
						if (isEmpty(value) || parseFloat(value) === 0)
							isValid = false;
					} else {
						if (value < 0.6 || value > 15) isValid = false;
						if (isEmpty(value)) isValid = true;
					}
					break;
				case "вручную_значение":
					if (
						this.state["рассчет_LB"] === "вручную" &&
						this.state["форма_подошвы"] === "прямоугольная"
					) {
						if (value < 0.3) isValid = false;
						if (value > 25) isValid = false;
						if (isEmpty(value)) isValid = false;
					}
					break;
				case "hn":
					if (this.state["наличие_подготовки"]) {
						if (isEmpty(value) || value < 0.1 || value > 1)
							isValid = false;
					}
					break;
				case "h":
					if (this.state["тип_колонны"] === "металлическая") {
						if (
							this.state["тип_металлической_колонны"] ===
							"двухветвевая"
						) {
							if (value < 0.2 || value > 3 || isEmpty(value))
								isValid = false;
						}
					}
					break;
				case "k":
					if (this.state["тип_колонны"] === "металлическая") {
						if (
							this.state["тип_металлической_колонны"] ===
							"двухветвевая"
						) {
							if (value < 0.15 || value > 1.1 || isEmpty(value))
								isValid = false;
						}
					}
					break;
				case "LBc":
					if (this.state["форма_подошвы"] === "круглая") {
						if (this.state["тип_подколонника"] === "круглый") {
							if (value < 0.3 || value > 1.2 || isEmpty(value))
								isValid = false;
						}
					}
					break;
				case "YII_":
					if (value > 2.5 || value < 0) isValid = false;
					if (isEmpty(value)) isValid = false;
					break;
				case "kh_temp":
					if (value < 0 || value > 35) isValid = false;
					break;
				case "bp":
					if (
						this.state["тип_колонны"] === "металлическая" &&
						this.state["тип_металлической_колонны"] ===
						"одноветвевая"
					) {
						if (value < 0.2 || value > 1) isValid = false;
						if (isEmpty(value)) isValid = false;
					}
					break;
				case "lp":
					if (this.state["тип_колонны"] === "металлическая") {
						if (
							this.state["тип_металлической_колонны"] ===
							"одноветвевая"
						) {
							if (this.state["база_колонны"] === "низкая") {
								if (value <= this.state.c * 2) isValid = false;
							}
							if (value < 0.2 || value > 1) isValid = false;
							if (isEmpty(value)) isValid = false;
						}
					}
					break;
				case "La":
					if (value < 0.2 || value > 1 || isEmpty(value))
						isValid = false;
					break;
				case "c":
					if (value < 0.1 || value > 0.6 || isEmpty(value))
						isValid = false;
					break;
				case "lc":
				case "bc":
					if (this.state["тип_колонны"] !== "металлическая") {
						if (
							this.state["тип_колонны"] === "монолитная" ||
							(this.state["тип_подколонника"] !== "круглый" &&
								this.state["форма_подошвы"] === "круглая")
						) {
							if (value < 0.3 || value > 1.2) {
								isValid = false;
							}
						}
					}
					if (this.state["тип_колонны"] === "сборная") {
						if (value > 1.6) {
							isValid = false;
						}
					}
					break;
				case "Yc_l":
					if (this.state["конструктивная_схема"] === "жесткая") {
						if (value < 0 || value > 120) isValid = false;
						if (isEmpty(value)) isValid = false;
					}
					break;
				case "Yc_h":
					if (this.state["конструктивная_схема"] === "жесткая") {
						if (value < 0 || value > 60) isValid = false;
						if (isEmpty(value)) isValid = false;
					}
					break;
				case "la":
				case "ba":
					if (this.state["тип_колонны"] === "металлическая") {
						if (
							this.state["тип_металлической_колонны"] ===
							"одноветвевая"
						) {
							if (value < 0.05 || isEmpty(value) || value > 0.8)
								isValid = false;
						}
					}
					break;
				case "dc":
					if (this.state["тип_колонны"] === "сборная") {
						if (value > 1.2 || this.state.lc > value)
							isValid = false;
						if (isEmpty(value)) isValid = false;

						if (!isEmpty(this.state["высота_фундамента"])) {
							if (value > this.state["высота_фундамента"] - 0.2)
								isValid = false;
						}
					}
					break;
				default:
					break;
			}

			if (this.state["тип_расчета"] === "check") {
				switch (name) {
					case "l":
						if (this.state["форма_подошвы"] === "прямоугольная") {
							if (value < 0 || value > 12 || isEmpty(value)) {
								isValid = false;
							}
						}
						break;
					case "kernels":
						if (this.state["reinforcement"]) {
							if (value < 4 || value > 26 || isEmpty(value)) {
								isValid = false;
							}
						}
						break;
					case "b":
						if (this.state["форма_подошвы"] === "прямоугольная") {
							if (value < 0 || value > 12 || isEmpty(value)) {
								isValid = false;
							}
						}
						break;
					case "D":
						if (this.state["форма_подошвы"] === "круглый") {
							if (value < 0 || value > 12 || isEmpty(value)) {
								isValid = false;
							}
						}
						break;
					case "ad":
						if (
							value < 4 ||
							(value > 7 && this.state["reinforcement"])
						) {
							isValid = false;
						}
						break;
					case "глубина_заложения":
						if (value < 0) isValid = false;
						if (value < 0.5 || value > 4.5) isValid = false;
						if (!this.state["планируемость_территории"]) {
							if (
								value < this.state["глубина_слоя"] &&
								!isEmpty(value)
							) {
								isValid = false;
							}
						}
						if (isEmpty(value)) isValid = true;
						break;
				}
			}

			if (!isValid) errors[name] = {};
			else delete errors[name];
		});

		errors = this.validateCheckSteps(errors);

		// Валидация таблицы
		if (this.state.surveysData.length > 0) {
			let deep = 0;
			this.state.surveysData.forEach(
				(item) => (deep += item["глубина_залегания"])
			);
			let dw = this.state.dw <= deep && this.state.dw !== 0;

			this.state.surveysData.forEach((item, index) => {
				Object.keys(item).forEach((key) => {
					const gType = item["тип_грунта"];
					const value = item[key];
					const name = key + "_" + index;

					let isClay = false;
					const isNotRock = gType !== "скальные";

					switch (gType) {
						case "суглинок":
						case "глина":
						case "супесь":
							isClay = true;
							break;
						case "крупнообломочный грунт":
							if (
								item["подтип_грунта"] ===
								"глинистый_заполнитель"
							)
								isClay = true;
							break;
					}

					let isValid = true;
					switch (key) {
						case "Ip_1":
						{
							if (
								value < 0 ||
								value > 100 ||
								(isEmpty(value) && isClay)
							)
								isValid = false;
						}
							break;
						case "Il_1":
						{
							if (
								value < -1 ||
								value > 2 ||
								(isEmpty(value) && isClay)
							)
								isValid = false;
						}
							break;
						case "плотность_ps_1":
						{
							if (dw) {
								if (
									value < 1.5 ||
									value > 3 ||
									(isEmpty(value) && isNotRock)
								)
									isValid = false;
							}
						}
							break;
						case "плотность_pd_1":
						{
							if (this.state.tableSize !== "normal") {
								if (
									value < 1.3 ||
									(value > 2.5 && isEmpty(value))
								)
									isValid = false;
							}
						}
							break;
						case "e_1":
						{
							if (isNotRock && dw) {
								if (
									value < 0.2 ||
									value > 1 ||
									isEmpty(value)
								)
									isValid = false;
							}
						}
							break;
						case "Ee_2":
						{
							if (
								this.state["геотехническая_категория"] === 3 &&
								isNotRock
							) {
								if (
									value < 1 ||
									value > 250 ||
									isEmpty(value)
								)
									isValid = false;
							}
						}
							break;
						case "filter":
						{
							if (value < 0 || value > 100 || isEmpty(value))
								isValid = false;
						}
							break;
						case "Sr_1":
						{
							if (
								(isNotRock && value < 0) ||
								value > 1 ||
								isEmpty(value)
							)
								isValid = false;
						}
							break;
						case "Esr_2":
						{
							if (isNotRock) {
								if (
									value < 1 ||
									value > 50 ||
									(isEmpty(value) && isNotRock)
								)
									isValid = false;
							}
						}
							break;
						case "плотность_p_2":
						{
							if (isNotRock) {
								if (
									value < 1.5 ||
									value > 2.5 ||
									(isEmpty(value) && isNotRock)
								)
									isValid = false;
							}
						}
							break;
						case "E_2":
						{
							if (isNotRock) {
								if (
									value < 1 ||
									value > 100 ||
									(isEmpty(value) && isNotRock)
								)
									isValid = false;
							}
						}
							break;
						case "сцепление_C_2":
						{
							if (
								(value < 0.1 ||
									value > 50 ||
									isEmpty(value)) &&
								isNotRock
							)
								isValid = false;
						}
							break;
						case "Угол_внутреннего_трения_2":
						{
							if (isNotRock) {
								if (
									value < 0 ||
									value > 45 ||
									isEmpty(value)
								)
									isValid = false;
							}
						}
							break;
						case "R_2":
							if (
								(value < 5 || value > 20 || isEmpty(value)) &&
								!isNotRock
							)
								isValid = false;
							break;
						case "particles":
							if (this.state.tableSize !== "normal") {
								if (
									value < 0 ||
									value > 100 ||
									(isEmpty(value) && isNotRock)
								)
									isValid = false;
							}

							break;
						case "природная_влажность_W_1":
						{
							if (this.state.tableSize !== "normal") {
								if (
									value < 0.01 ||
									value > 100 ||
									(isEmpty(value) && isNotRock)
								)
									isValid = false;
							}
						}
							break;
						case "влажность_Wl_1":
						{
							if (
								isNotRock &&
								this.state.tableSize !== "normal"
							) {
								if (
									value < 0 ||
									value > 50 ||
									isEmpty(value)
								)
									isValid = false;
							}
						}
							break;
						case "влажность_Wp_1":
						{
							if (
								isNotRock &&
								this.state.tableSize !== "normal"
							) {
								if (
									value < 0 ||
									value > 50 ||
									isEmpty(value)
								)
									isValid = false;
							}
						}
							break;
						case "filter_1":
						{
							if (this.state.tableSize !== "normal") {
								if (
									value < 0 ||
									value > 100 ||
									isEmpty(value)
								)
									isValid = false;
							}
						}
							break;
						case "глубина_залегания":
							if (value <= 0 || isEmpty(value)) isValid = false;
							break;
					}
					if (!isValid) errors[name] = {};
					else delete errors[name];
				});
			});
		}
		this.showErrorFields(errors);

		this.setState({
			errors: errors,
		});
	}

	tabsHandler = (e) => {
		this.setState({
			tabs: e.target.value,
		});
	};

	formSubmit = (e) => {
		e.preventDefault();

		this.validateDataAfterSubmit();
		let loadsIsValid = false;

		this.state.loadsData.forEach((item) => {
			if (item.type === "постоянная" || item.type === "длительная")
				loadsIsValid = true;
		});

		if (!isEmpty(this.state.errors)) {
			Notification.error({
				title: "Ошибка",
				duration: 40000,
				description: <Errors app={this} />,
			});
			return false;
		}

		if (this.state.surveysData.length === 0) {
			Notification.error({
				title: "Ошибка",
				duration: 40000,
				description: (
					<div className={"notification-container"}>
						{" "}
						Не задано ни одного грунтового слоя
					</div>
				),
			});
			return false;
		}

		if (!loadsIsValid) {
			Notification.error({
				title: "Ошибка",
				duration: 40000,
				description: (
					<div className={"notification-container"}>
						{" "}
						Должна быть задана хотя бы одна длительная или
						постоянная нагрузка
					</div>
				),
			});
			return false;
		}

		this.setState({loading: true}, () => {

			$.ajax({
				url : URL + "/" + this.state["тип_расчета"],
				type: "POST",
				data : this.state,
				success: (response) => {
					try {
						$("#my_iframe").html(response);
					} catch (e) {
						console.log("Произошла ошибка при выводе отчета");
					}

					//if (!isEmpty(window.GLOBALS.fileName))
					//	this.setState({fileName: window.GLOBALS.fileName});

					let callback = () => {
						this.setState({
							calculationSuccess: true,
							tabs: "4",
							loading: false,
							disabledTabs: this.state.disabledTabs.filter(e => e !== "4"),

							// Очистим имя файла отчета, так как он еще не сформирован
							fileName: null
						});
					};

					window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, "my_iframe"]);
					window.MathJax.Hub.Queue(callback);
				}
			});

		});
	};

	prepareImagesToSend () {
		let images = {
			settlement : 'scheme-foundation-settlement',
			layers : 'scheme-layers-igr'
		};

		let promises = Object.entries(images).map(([key, value]) => {
			let DropAreaElement= document.getElementById(value);
			let config = {
				useCORS: true,
				imageTimeout: 0
			};

			return html2canvas(DropAreaElement, config).then(canvas => {
				let img = canvas.toDataURL("image/png");

				return {
					[key]: img
				};
			});
		});

		return Promise.all(promises).then(result => {
			return result;
		})
	}

	async startRenderImages() {
		return await this.prepareImagesToSend().then(images => {
			console.log(images.reduce((result, img) => Object.assign(result, img), {}));

			$.ajax({
				url : URL + "/pillarDoc",
				type: "POST",
				data : $.extend({}, this.state, {
					images: images.reduce((result, img) => Object.assign(result, img), {})
				}),
				success: response => {
					let report = response.report || {};

					if (!isEmpty(report.filename))
						this.setState({fileName: report.filename}, () => {
							this.dispatch(appHideLoading());
						});
				}
			});
		});
	}

	render() {
		return (
			<div className="main-window-container">

				<div className="control-panel-buttons">
					<div className="d-flex">
						<Button
							className={"btn btn-control"}
							active={this.state.sidebars.sidebarParameters.show}
							onClick={() => {
								this.dispatch(toggleSidebarShow("sidebarParameters"))
							}}
						>
							{lang("Параметры фундамента")}
						</Button>

						<div role="group"
							 className="btn-group btn-group--ml5 my-5px"
							 aria-label="Basic example">

							<button className="mx-1 btn btn-success"
									onClick={this.formSubmit.bind(this)}>
								<FontAwesomeIcon icon={faPlay} style={{marginRight: 5}}/>
								{lang('Выполнить расчет')}
							</button>
						</div>

						{/*<Button*/}
						{/*	className={"btn btn-control"}*/}
						{/*	onClick={() => {*/}
						{/*		saveSvgAsImage(*/}
						{/*			'scheme-foundation-settlement',*/}
						{/*			() => this.dispatch(appShowLoading()),*/}
						{/*			() => this.dispatch(appHideLoading())*/}
						{/*		)*/}
						{/*	}}*/}
						{/*>*/}
						{/*	{lang("Сохранить график")}*/}
						{/*</Button>*/}

						{/*<div*/}
						{/*	className="btn-group btn-group--ml5"*/}
						{/*	role="group"*/}
						{/*	aria-label="Basic example">*/}
						{/*	<a href="#"*/}
						{/*	   className="btn btn-primary my-5px"*/}
						{/*	   onClick={this.saveData.bind(this)}>*/}
						{/*		Сохранить данные!*/}
						{/*	</a>*/}
						{/*	<a href="#"*/}
						{/*	   className="btn btn-primary my-5px"*/}
						{/*	   onClick={this.deleteData}>*/}
						{/*		<img*/}
						{/*			src={deleteIcon}*/}
						{/*			style={{ width: "15px", height: "100%" }}*/}
						{/*			alt=""*/}
						{/*		/>*/}
						{/*	</a>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className="main-window-content">
					{this.state.loading &&
					<div className="overlay_loading">
						<div className="overlay_loading__inner">
							<div className="overlay_loading__content"><span className="spinner_loading"></span></div>
						</div>
					</div>
					}

					<div
						className="container-sidebars"
						style={{ minHeight: "calc(100vh - 46px)" }}>
						<Sidebar
							id="sidebarParameters"
							show={this.state.sidebars.sidebarParameters.show}
							attract={this.state.leftSidebarIsAttractedToScreen}
							title={lang("Параметры фундамента")}
						>
							<PillarFoundationProps app={this} />
						</Sidebar>
					</div>

					<div className="canvas-board-container"
						 style={{
						 	 left: this.state.sidebars.sidebarParameters.show && !this.state.leftSidebarIsAttractedToScreen ? 350 : 0,
							 width: "calc(100% - " + (
							 	this.state.sidebars.sidebarParameters.show && !this.state.leftSidebarIsAttractedToScreen ? 350 : 0
							 ) + "px)"
						 }}>

						<div className="pillar container-fluid p-0 root-container"
							 style={{height: 'calc(100vh - 91px)'}}
						>
							<div className="w-100 bg-white container-control-steps">
								<div className="btn-group btn-group--ml5" role="group">
									<input
										type="radio"
										name="tabs"
										id="control1"
										value="1"
										disabled={this.state.disabledTabs.includes("1")}
										onChange={this.tabsHandler}
										checked={this.state.tabs === "1"}
									/>
									<label htmlFor="control1"
										   className="btn btn-control-light">
										{lang('Отметки фундамента')}
										<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 5, opacity: 0.3}}/>
									</label>

									<input
										type="radio"
										name="tabs"
										id="control2"
										value="2"
										disabled={this.state.disabledTabs.includes("2")}
										onChange={this.tabsHandler}
										checked={this.state.tabs === "2"}
									/>
									<label htmlFor="control2"
										   className="btn btn-control-light">
										{lang('Сбор нагрузок на фундамент')}
										<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 5, opacity: 0.3}}/>
									</label>

									<input
										type="radio"
										name="tabs"
										id="control3"
										value="3"
										disabled={this.state.disabledTabs.includes("3")}
										onChange={this.tabsHandler}
										checked={this.state.tabs === "3"}
									/>
									<label htmlFor="control3"
										   className="btn btn-control-light">
										{lang('Инженерно-геологические изыскания')}
										<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 5, opacity: 0.3}}/>
									</label>

									<input
										type="radio"
										name="tabs"
										id="control4"
										value="4"
										disabled={this.state.disabledTabs.includes("4")}
										onChange={this.tabsHandler}
										checked={this.state.tabs === "4"}
									/>
									<label htmlFor="control4"
										   className={'btn btn-control-light' + (this.state.disabledTabs.includes("4") ? ' disabled' : '')}>
										{lang('Отчет')}
									</label>
								</div>

								{!isEmpty(this.state.fileName) &&
								<a
									className="btn btn-sm btn-success ml-2"
									style={{textDecoration: "none"}}
									target="_blank"
									href={[URL + '/download/' + this.state.fileName]}>
									{lang("Скачать отчет")}
								</a>
								}

								{isEmpty(this.state.fileName) && this.state.calculationSuccess &&
								<Button
									onClick={() => {
										this.setState({
											loading: true
										}, () => {
											setTimeout(() => {
												this.startRenderImages();
											}, 500);
										});
									}}
									className="btn btn-sm btn-success ml-2">
									{lang("Сформировать отчет")}
								</Button>
								}
							</div>

							<Container fluid={true}>
							</Container>

							<div className="container-fluid">

							</div>

							<div className="chart-container">
								<div className="h-100 w-100"
									 style={{display: (this.state.tabs < 2) ? 'block' : 'none'}}>
									<div className="container h-100 w-100 d-flex align-items-center justify-content-center">
										<PillarScheme app={this}
													  data={this.state} />
									</div>
								</div>

								<div className="col" style={{display: (this.state.tabs === "2") ? 'block' : 'none'}}>
									<PillarLoads app={this}/>
								</div>

								<div className="col" style={{display: (this.state.tabs === "3") ? 'block' : 'none'}}>
									<SurveysTable app={this}
												  data={this.state}
									/>
								</div>

								<div className="h-100" style={{display: (this.state.tabs === "4") ? 'block' : 'none'}}>
									<div id="my_iframe" className="h-100"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	fetched: state.fetched
});

export default connect(mapStateToProps)(PillarApp);
