import React from "react";
import ReactTable from "react-table";
import $ from "jquery";
import "react-table/react-table.css";
import deleteIcon from "../../../img/delete.svg";
import copyIcon from "../../../img/copy.svg";
import addIcon from "../../../img/add.svg";
import { isEmpty } from "../../Global/Global";
import _ from "lodash";

import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";

import { Whisper } from "rsuite";
import {lang} from "../../../Modules/Global";

class LoadsTable extends React.Component {
	constructor(props) {
		super(props);

		this.props = props;

		this.renderEditable = this.renderEditable.bind(this);
		this.editableSelect = this.editableSelect.bind(this);
		this.renderControlButtons = this.renderControlButtons.bind(this);
		this.renderCreateRow = this.renderCreateRow.bind(this);
	}

	app = this.props.app;

	showSVGLine = (e, cellInfo) => {
		if (cellInfo.column.id === "title" || cellInfo.column.id === "id")
			return false;

		let color = "red";

		switch (cellInfo.column.id) {
			case "My":
			case "Qx":
				color = "red";
				break;
			case "N":
				color = "blue";
				break;
			case "Mx":
			case "Qy":
				color = "#14FF00";
				break;
			default:
				return false;
		}
		const svg = document.getElementById("loads-scheme"),
			svgDoc = svg.contentDocument,
			lines = svgDoc.getElementsByClassName(cellInfo.column.id);

		if (e.type === "focus") {
			for (let i = 0; i < lines.length; i++) {
				if (isEmpty(lines[i].getAttribute("stroke"))) {
					lines[i].setAttribute("fill", color);
				} else {
					lines[i].setAttribute("stroke", color);
				}
			}
		}

		if (e.type === "blur") {
			for (let i = 0; i < lines.length; i++) {
				if (isEmpty(lines[i].getAttribute("stroke"))) {
					lines[i].setAttribute("fill", "#848484");
				} else {
					lines[i].setAttribute("stroke", "#848484");
				}
			}
		}
	};

	editableSelect(cellInfo) {
		const data = this.app.state.loadsData;

		return (
			<select
				style={{
					width: "100%",
					fontSize: "14px",
				}}
				className={"form-control p-1"}
				value={cellInfo.value}
				onChange={(e) => {
					data[cellInfo.index][cellInfo.column.id] = e.target.value;
					this.app.setState({
						loadsData: data,
					});
				}}
			>
				<option value="постоянная">Постоянное</option>
				<option value="длительная">Длительное</option>
				<option value="кратковременная">Кратковременное</option>
			</select>
		);
	}

	renderEditable(cellInfo) {
		return (
			<input
				type={"text"}
				style={{
					height: "100%"
				}}
				className={"table-field"}
				onChange={(e) => {
					const data = this.app.state.loadsData;

					let value = e.target.value;

					if (!(cellInfo.column.id === "title")) {
						if (!($.isNumeric(value) || value === "" || value === "-"))
							return false;
						value = value.replace(/[,]/g, ".");
					}

					data[cellInfo.index][cellInfo.column.id] = value;
					this.app.setState({
						loadsData: data,
					});
				}}
				onFocus={(e) => this.showSVGLine(e, cellInfo)}
				onBlur={(e) => {
					this.showSVGLine(e, cellInfo);

					const data = this.app.state.loadsData;

					let value = e.target.value;
					if (value === "") value = 0;

					data[cellInfo.index][cellInfo.column.id] = value;
					this.app.setState({
						loadsData: data,
					});
				}}
				value={cellInfo.value}
			/>
		);
	};

	renderColumnName(name, sub, measure, color) {
		return (
			<span
				style={{
					color: color,
				}}
			>
				{" "}
				{name} <sub> {sub} </sub> <br /> {measure}{" "}
			</span>
		);
	}

	renderControlButtons(cellInfo) {
		let data = this.app.state.loadsData;

		return (
			<div className="contoll-buttons">
				<button
					title={lang("Удалить загружение")}
					className="btn btn-sm"
					onClick={(e) => {
						_.remove(data, (item, i) => i === cellInfo.row._index);

						this.app.setState({
							loadsData: data,
						});
					}}
				>
					<FontAwesomeIcon icon={faTrashAlt} className="rs-icon-info" />
				</button>
				<button
					className="btn btn-sm"
					title={lang("Скопировать загружение")}
					onClick={(e) => {
						let newParams = _.cloneDeep(data);
						let newLayoutParamsRow = newParams[cellInfo.row._index];
						data.push(newLayoutParamsRow);
						this.app.setState({
							loadsData: data,
						});
					}}
				>
					<FontAwesomeIcon icon={faCopy} className="rs-icon-info" />
				</button>{" "}
			</div>
		);
	}

	addNewRow = () => {
		let data = this.app.state.loadsData;
		let id = data.length > 0 ? + data[data.length - 1].id + 1 : 1;
		let newRow = {
			id: id,
			title: "Новое загружение",
			N: 0,
			Mx: 0,
			My: 0,
			Qx: 0,
			Qy: 0,
			type: "длительная",
		};
		data.push(newRow);
		this.app.setState({
			loadsData: data,
		});
	};

	renderCreateRow() {
		return (
			<div className="contoll-buttons">
				<div className="btn btn-secondary" onClick={this.addNewRow}>
					<img src={addIcon} alt="" />
				</div>{" "}
			</div>
		);
	};

	render() {
		return (
			<ReactTable
				className="loadsTable"
				data={this.app.state.loadsData}
				columns={[
					{
						Header: "#",
						accessor: "id",
						Cell: this.renderEditable,
						minWidth: 30,
						sortable: false,
					},
					{
						Header: "Наименование загружения",
						accessor: "title",
						Cell: this.renderEditable,
						width: 180,
						style: {
							color: "#00000",
						},
						sortable: false,
					},
					{
						Header: "N ( кН )",
						accessor: "N",
						Cell: this.renderEditable,
						minWidth: 62,
						sortable: false,
					},
					{
						Header: this.renderColumnName("M", "x", "(кН * м)", "green"),
						accessor: "Mx",
						Cell: this.renderEditable,
						minWidth: 86,
						sortable: false,
					},
					{
						Header: this.renderColumnName("M", "y", "(кН * м)", "red"),
						accessor: "My",
						Cell: this.renderEditable,
						minWidth: 86,
						sortable: false,
					},
					{
						Header: this.renderColumnName("Q", "x", "(кН)", "red"),
						accessor: "Qx",
						Cell: this.renderEditable,
						minWidth: 62,
						sortable: false,
					},
					{
						Header: this.renderColumnName("Q", "y", "(кН)", "green"),
						accessor: "Qy",
						Cell: this.renderEditable,
						minWidth: 62,
						sortable: false,
					},
					{
						Header: "Тип",
						accessor: "type",
						Cell: this.editableSelect,
						minWidth: 160,
						sortable: false,
					},
					{
						Header: this.renderCreateRow,
						Cell: this.renderControlButtons,
						minWidth: 100,
						sortable: false,
					},
				]}
				style={{
					height: "350px",
				}}
				noDataText={
					<div>
						<h5> Добавьте загружения </h5>{" "}
						<div
							className={"btn btn-secondary mt-2 pointer w-100"}
							onClick={this.addNewRow}
						>
							<p className="m-0"> Добавить </p>
						</div>
					</div>
				}
				showPagination={false}
				sortable={false}
				resizable={false}
			/>
		);
	}
}
export default LoadsTable;
