import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	loading: true
};

const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		/**
		 * Включение прелоадера всего приложения
		 * @param state
		 */
		appShowLoading(state) {
			state.loading = true;
		},

		/**
		 * Отключение прелоадера всего приложения
		 * @param state
		 */
		appHideLoading(state) {
			state.loading = false;
		}
	}
});

export default appSlice.reducer;

export const {
	appShowLoading,
	appHideLoading
} = appSlice.actions;