import React, {Component, useState, useRef, useEffect} from 'react';
import Draggable from "react-draggable";
import {useDispatch, useSelector} from "react-redux";
import {closeSidebarAction, toggleAttractSidebar} from "../../../store/slices/sidebarsSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight, faAngleDoubleLeft, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Resizable} from "re-resizable";
import './index.css';

const Sidebar = ({...props}) => {
	const defaultProps = {
		show: props.show,
		attract: props.attract
	};

	Object.assign(defaultProps, props);

	const sidebarKey = props.id;

	//const leftSidebarIsAttractedToScreen = useSelector(state => state.sidebars.sidebars.leftSidebarIsAttractedToScreen);
	//const position = useSelector(state => state.sidebars.sidebars[sidebarKey].position);

	const dispatch = useDispatch();
	const [show, setShow] = useState(props.show);
	const [attract, setAttract] = useState(props.attract);
	const [position, setPosition] = useState({
		x: 0,
		y: 0
	});
	const [size, setSize] = useState({
		width: 350,
		height: 500
	});

	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	useEffect(() => {
		setAttract(props.attract);
	}, [props.attract]);

	return(
		<Draggable
			disabled={!show}
			handle={
				!attract ? "#disabled" : ".sidebar-header-panel"
			}
			//bounds={attract ? '#container-sidebars' : '#main-window-content'}
			defaultPosition={position}
			position={position}
			onStop={(e, node) => {
				console.log({ x: node.x, y: node.y });
				setPosition({ x: node.x, y: node.y });
			}}>

			<div style={{display: show ? "block" : "none"}}>
				<div id={`sidebar-${sidebarKey}`}
					 className="container-sidebar">
					<div className="sidebar-header-panel">
						<div className="panel-icons float-right">
							<div className="sidebar-header-panel-icon">
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();

										if (!attract)
											setPosition({
												x: 5,
												y: 45
											});
										else
											setPosition({
												x: 0,
												y: 0
											});

										dispatch(toggleAttractSidebar());
									}}
								>
									{attract ?
										<FontAwesomeIcon icon={faAngleDoubleLeft}/>
										:
										<FontAwesomeIcon icon={faAngleDoubleRight}/>
									}
								</a>
							</div>
							<a
								className="sidebar-header-panel-icon fs-14"
								onClick={(e) => {
									e.preventDefault();

									dispatch(closeSidebarAction(sidebarKey));

									// setShow(false);
								}}
								href="#">
								<FontAwesomeIcon icon={faTimes}/>
							</a>
						</div>
						<h3 className="sidebar-title">
						<span>
							{props.title}
						</span>
						</h3>
					</div>
					<div className="container-sidebar-inner padding-bottom">
						{props.children}
						<div style={{ clear: "both" }}></div>
					</div>
				</div>
				}
			</div>

		</Draggable>
	);
}

export default Sidebar;