import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	"тип_расчета": "solve",								// Тип расчета | "тип_расчета" / type
	"привязка_к_абсолютным_отметкам": false,			// Привязать к асболютным отметкам | "привязка_к_абсолютным_отметкам" / snapToAbsolutePoints
	"тип_металлической_колонны": "одноветвевая",		// Тип металлической колонны | "тип_металлической_колонны" / typeOfMetalColumn
	"город": 274,
};

const solveSlice = createSlice({
	name: "solve",
	initialState,
	reducers: {
		changeSolveType: (state, action) => {
			const solutionType = action.payload;

			// Провериим на допустимые варианты, которые может принимать свойство
			if (["solve", "check"].includes(solutionType)) {
				state["тип_расчета"] = solutionType;

				if (solutionType === "check")
					state["привязка_к_абсолютным_отметкам"] = false;
			}
		},

		changeTypeOfMetalColumn: (state, action) => {
			const typeOfMetalColumn = action.payload.toLowerCase();

			if (["одноветвевая", "двухветвевая"].includes(typeOfMetalColumn))
					state["тип_металлической_колонны"] = typeOfMetalColumn;
		},

		changeSelectedCity: (state, action) => {
			state["город"] = Number(action.payload)
		}
	}
});

export default solveSlice.reducer;

export const {
	changeSolveType,
	changeTypeOfMetalColumn,
	changeSelectedCity
} = solveSlice.actions;