// const options = [
// 	{
// 		label: "Group 1",
// 		options: [
// 			{ label: "Group 1, option 1", value: "value_1" },
// 			{ label: "Group 1, option 2", value: "value_2" }
// 		]
// 	},
// 	{ label: "A root option", value: "value_3" },
// 	{ label: "Another root option", value: "value_4" }
// ];
export function convertPlaneToTree(items) {
	let results = JSON.parse(JSON.stringify(items));

	const parentMap = {};
	const root = [];

	// Map parent positions
	results.forEach((el, i) => {
		parentMap[el.id] = i;
		el.options = [];
	});

	results.forEach(({...result}) => {
		const insert = result;
		if (result.parentId === 0) {
			root.push(insert);
			return;
		}
		results[parentMap[result.parentId]].options.push(insert);
	});

	return root;
}