import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getSu} from "../../api"

const initialState = {
	loading: false,
	entities: []	// Данные коэффиценита Su в зависомтси  от типа здания
};

export const fetchSu = createAsyncThunk(
	'api/su',
	async (_, { rejectWithValue }) => {
		try {
			return await getSu();
		} catch (err) {
			return rejectWithValue([], err);
		}
	}
)

const { actions, reducer } = createSlice({
	name: "su",
	initialState,
	reducers: {},
	extraReducers: {
		[fetchSu.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId.requestId) {
				state.entities = payload;
				state.loading = true;
				state.currentRequestId = "";
			}
		},
		[fetchSu.pending]: (state, { meta }) => {
			state.currentRequestId = meta;
			state.loading = "pending";
		},
		[fetchSu.rejected]: (state, { meta, payload, error }) => {
			state.currentRequestId = meta;
			state.loading = false;
			state.entities = payload;
			state.error = error;
		},
	}
});

export default reducer;