
import React from "react";
import _ , { isEmpty } from 'lodash';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import './index.css';
class Errors extends React.Component {

    state = {
        errors:this.props.app.state.errors,
    }
    app = this.props.app;

    tabsHandler = (tab) =>{
        this.app.setState({
            tabs:tab
        })
    }

    render() {

        let tab2 = _.pickBy(this.state.errors, function(value, key) {
            return value.tab === '2'
        });
        let tab3 = _.pickBy(this.state.errors, function(value, key) {
            return value.tab === '3'
        });
        return (
            <div>
                <div className={'notification-container'}>
                    {  ( !isEmpty(tab2)) &&
                    <div
						// onClick={()=>this.tabsHandler('2')}
					>
                        <p className={'notificationTitle'}>
                            Инженерно-геологические изыскания
                        </p>

                        {Object.keys(tab2).map((key,index) => {
                            return <p key={index}><a href={'#' + key} >{tab2[key].textError}</a></p>;
                        })}
                    </div>
                    }

                    {  ( !isEmpty(tab3)) &&
                        <div
                            // onClick={()=>this.tabsHandler('3')}
                        >
                            <p className={'notificationTitle'}>Параметры фундамента</p>

                            {Object.keys(tab3).map((key,index) => {
                                return <p key={index}><a href={'#' + key} >{tab3[key].textError}</a></p>;
                            })}
                        </div>

                    }

                </div>
            </div>
        );
    }
}
export default Errors;