import {Popover} from "rsuite";
import React from "react";
import html2canvas from "html2canvas";

export function lang(str) {
	window.languageDictionary = window.languageDictionary || {};
	if (window.languageDictionary.hasOwnProperty(str)) {
		return window.languageDictionary[str];
	} else {
		return str;
	}
}

export function popover(html) {
	return <Popover visible style={{
		color: '#ffffff'
	}}>{html}</Popover>
}

export function saveSvgAsImage(
	id,
	beforeStartCallback = () => {},
	afterFinishCallback = () => {},
) {
	beforeStartCallback();

	function getFormattedTime() {
		let today = new Date();
		let y = today.getFullYear();
		// JavaScript months are 0-based.
		let m = today.getMonth() + 1;
		let d = today.getDate();
		let h = today.getHours();
		let mi = today.getMinutes();
		let s = today.getSeconds();

		return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
	}

	html2canvas(document.getElementById(id)).then(canvas => {
		let a = document.createElement('a');

		// toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
		a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
		a.download = `screen-${getFormattedTime()}.jpg`;
		a.click();

		afterFinishCallback();
	});
}