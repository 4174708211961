export const stylesReactSelect = {
	container: base => ({
		...base,
		visibility: 'visible'
	}),
	control: base => ({
		...base,
		minHeight: 34
	}),
	menuPortal: base => ({
		...base,
		zIndex: 999
	}),
	dropdownIndicator: base => ({
		...base,
		padding: 4,
		visibility: 'visible'
	}),
	clearIndicator: base => ({
		...base,
		padding: 4
	}),
	valueContainer: base => ({
		...base,
		padding: '0px 6px',
		visibility: 'visible'
	}),
	input: base => ({
		...base,
		margin: 0,
		padding: 0
	}),
	option: (provided, state) => ({
		...provided,
		//borderBottom: '1px dotted pink',
		color: state.isSelected ? '#ffffff' : '#000000',
		//padding: 10,
		fontSize: 14,
		// lineHeight: '10px'
	}),
};