import React, { Component } from 'react';
import config from "../../../Model/Config";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import './index.css';
import leftArrow from "../../../img/left-arrow.svg";

const  URL = config.url;

class PillarReport extends Component {

    app = this.props.app;

    paramsHandler = e => {

        this.app.setState({
                [e.target.name]:e.target.checked
        });

    };

    checkAll = () =>{

        this.app.setState({
            initDataHTMLReport: true,
            solveDHTMLReport: true,
            solveAHTMLReport: true,
            solveRHTMLReport: true,
            solveEHtmlReport: true,
            solvePHtmlReport: true,
            EPRCheckHTMLReport: true,
            solveSHTMLReport: true,
            solveStepsHTMLReport: true,
            solvePressingHTMLReport: true,
            solvePlateReinforcementHTMLReport: true,
            solveCheckMult: true,
            plateCrackingHTMLReport: true,
            solveUnderColumnReinforcement: true,
            shortSolvedParams: true,
            krenHTMLReport:this.app.state['крен'],
            anchorBoltHTMLReport:this.app.state['тип_колонны'] === 'металлическая',
            titlePageHTMLReport:true,
            contentTitles:true,
        })

    };
    uncheckAll = () =>{

        this.app.setState({
            initDataHTMLReport: false,
            solveDHTMLReport: false,
            solveAHTMLReport: false,
            solveRHTMLReport: false,
            solveEHtmlReport: false,
            solvePHtmlReport: false,
            EPRCheckHTMLReport: false,
            solveSHTMLReport: false,
            solveStepsHTMLReport: false,
            solvePressingHTMLReport: false,
            solvePlateReinforcementHTMLReport: false,
            solveCheckMult: false,
            plateCrackingHTMLReport: false,
            solveUnderColumnReinforcement: false,
            shortSolvedParams: false,
            krenHTMLReport:false,
            anchorBoltHTMLReport:false,
            titlePageHTMLReport:false,
            contentTitles:false,
        })

    };
    render() {
        return (
            <div>
                {/*<div className=" my-3">*/}
                {/*    <form method = "POST"  target="_blank" action={ URL +'/report/layers'}>*/}
                {/*        <button type='submit'  className='mx-1 btn btn-success' onClick={this.showPreloader}> Таблица слоев </button>*/}
                {/*        <input type='text' className='hidden' name='data' defaultValue={JSON.stringify(this.app.state.surveysData)}/>*/}
                {/*    </form>*/}
                {/*</div>*/}
                {/*<div className="d-flex mb-3">*/}
                {/*    <div className="btn btn-secondary mr-3" onClick={this.checkAll}>Отметить</div>*/}
                {/*    <div className="btn btn-secondary mr-3" onClick={this.uncheckAll}>Очистить</div>*/}
                {/*</div>*/}
                <div onChange={this.paramsHandler} className="form-group">
                    <div className="my-1 d-flex">
                        <input type="checkbox" defaultChecked={this.app.state.titlePageHTMLReport} className="report_checkbox mr-2" id="report20" name='titlePageHTMLReport'/>
                        <label htmlFor="report20" >Титульный лист</label>
                    </div>
                    <div className="my-1 d-flex">
                        <input type="checkbox" defaultChecked={this.app.state.contentTitles} className="report_checkbox mr-2" id="report30" name='contentTitles'/>
                        <label htmlFor="report30" >Содержание</label>
                    </div>
                    <div className="my-1 d-flex">
                        <input type="checkbox" defaultChecked={this.app.state.initDataHTMLReport} className="report_checkbox mr-2" id="report14" name='initDataHTMLReport'/>
                        <label htmlFor="report14" >Вывести исходные данные</label>
                    </div>
                    {(this.app.state['тип_расчета'] === 'solve' || this.app.state.size) &&
                        <div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solveDHTMLReport}
                                       className="report_checkbox mr-2" id="report1" name='solveDHTMLReport'/>
                                <label htmlFor="report1">Определение глубины заложения</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solveAHTMLReport} id="report2"
                                       className="report_checkbox mr-2" name='solveAHTMLReport'/>
                                <label htmlFor="report2">Определение габаритов фундамента</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solveRHTMLReport}
                                       className="report_checkbox mr-2" id="report3" name='solveRHTMLReport'/>
                                <label htmlFor="report3">Расчет сопротивления грунта основания</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solveEHtmlReport} id="report4"
                                       className="report_checkbox mr-2" name='solveEHtmlReport'/>
                                <label htmlFor="report4">Расчет эксцентриситетов нагрузки по подошве фундамента</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solvePHtmlReport} id="report5"
                                       className="report_checkbox mr-2" name='solvePHtmlReport'/>
                                <label htmlFor="report5">Рассчитываем давление в угловой точке P</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.EPRCheckHTMLReport} id="report6"
                                       className="report_checkbox mr-2" name='EPRCheckHTMLReport'/>
                                <label htmlFor="report6">Проверки на эксцетриситеты,R,P</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solveSHTMLReport} id="report7"
                                       className="report_checkbox mr-2" name='solveSHTMLReport'/>
                                <label htmlFor="report7">Расчет осадки основания</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" disabled={!this.app.state['крен']}
                                       defaultChecked={this.app.state.krenHTMLReport} id="report7"
                                       className="report_checkbox mr-2" name='krenHTMLReport'/>
                                <label htmlFor="report15">Расчет крена</label>
                            </div>
                        </div>
                    }


                    { (this.app.state['тип_расчета'] === 'solve') &&
                        <div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solveStepsHTMLReport} id="report8" className="report_checkbox mr-2" name='solveStepsHTMLReport'/>
                                <label htmlFor="report8" >Расчет ступеней</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solvePressingHTMLReport} id="report9" className="report_checkbox mr-2" name='solvePressingHTMLReport'/>
                                <label htmlFor="report9" >Расчет на продавливание</label>
                            </div>
                        </div>
                    }
                    {(this.app.state['тип_расчета'] === 'solve' || this.app.state.anchor) &&
                        <div className="my-1 d-flex">
                            <input type="checkbox" defaultChecked={this.app.state.anchorBoltHTMLReport}
                                   disabled={this.app.state['тип_колонны'] !== 'металлическая'} id="report7"
                                   className="report_checkbox mr-2" name='anchorBoltHTMLReport'/>
                            <label htmlFor="report16">Расчет анкерного болта</label>
                        </div>
                    }
                    {(this.app.state['тип_расчета'] === 'solve' || this.app.state.reinforcement) &&
                        <div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solvePlateReinforcementHTMLReport}
                                       id="report10" className="report_checkbox mr-2"
                                       name='solvePlateReinforcementHTMLReport'/>
                                <label htmlFor="report10">Армирование плитной части</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" disabled={!this.app.state['solvePlateReinforcementHTMLReport']} defaultChecked={this.app.state.solveCheckMult} id="report13"
                                       className="report_checkbox mr-2" name='solveCheckMult'/>
                                <label htmlFor="report13">Проверка прочности плитной части</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" disabled={!this.app.state['solvePlateReinforcementHTMLReport']} defaultChecked={this.app.state.plateCrackingHTMLReport} id="report11"
                                       className="report_checkbox mr-2" name='plateCrackingHTMLReport'/>
                                <label htmlFor="report11">Рачет по образованию и раскрытию трещин плитной части</label>
                            </div>
                            <div className="my-1 d-flex">
                                <input type="checkbox" defaultChecked={this.app.state.solveUnderColumnReinforcement}
                                       className="report_checkbox mr-2" id="report12" name='solveUnderColumnReinforcement'/>
                                <label htmlFor='report12'>Армирование подколонника</label>
                            </div>
                        </div>
                    }
                    <div className="my-1 d-flex">
                        <input type="checkbox"  defaultChecked={this.app.state.shortSolvedParams} className="report_checkbox mr-2" id="report14" name='shortSolvedParams'/>
                        <label htmlFor='report14'>Основные расчетные параметры</label>
                    </div>
                </div>
            </div>
        );
    }
}

export default PillarReport;
