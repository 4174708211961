import React from 'react';
import ReactDOM from "react-dom";
import { isEmpty,precision } from '../Global/Global';
import NumericInput from "../NumericInput/NumericInput";
import ReactHtmlParser from "react-html-parser";

const $ = window.$;

class CustomNumericInput extends React.Component
{

    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            value: this.props.value,
            isValid: this.props.hasOwnProperty('isValid') ? this.props.isValid : true,
            disabled: this.props.hasOwnProperty('disabled') ? !!this.props.disabled : false,
        };

        this.onValidate = this.props.onValidate || function () {
            return {
                isValid: true,
                textError: ''
            }
        };

        this.options = $.extend(true, {
            id:(isEmpty(this.props.id)) ? props.name : this.props.id,
            precision:this.props.precision || Number.MAX_SAFE_INTEGER || 9007199254740991,
            step: 1,
            maxLength: props.maxLength || 9007199254740991,
            min: Number.MIN_SAFE_INTEGER || -9007199254740991,
            max: Number.MAX_SAFE_INTEGER || 9007199254740991,

			widthLabel: this.props.widthLabel || '50%',
			widthInput: this.props.widthInput || '50%',
			labelLeft: this.props.labelLeft || false,
			lineHeight: this.props.lineHeight || '16px',

            className: 'form-control right-nav-input ' ,
            enabledHandlerOnInput: this.props.hasOwnProperty('enabledHandlerOnInput') ? !!this.props.enabledHandlerOnInput : false,
            allowFloat: this.props.hasOwnProperty('allowFloat') ? !!this.props.allowFloat : true,
            allowNegative: this.props.hasOwnProperty('allowNegative') ? !!this.props.allowNegative : false,
            selectValueOnFocus: this.props.hasOwnProperty('selectValueOnFocus') ? !!this.props.selectValueOnFocus : false,
            receiveProps: this.props.hasOwnProperty('receiveProps') ? !!this.props.receiveProps : false,
        }, props);
    }

    componentDidMount() {
        $(ReactDOM.findDOMNode(this).parentNode).find('input[type="text"]').numericInput({
            allowFloat: this.options.allowFloat,
            allowNegative: this.options.allowNegative
        });
    }

    componentWillReceiveProps(nextProps) {

        let state = {};

        if (this.options.receiveProps) {
            state.value = nextProps.value;
            state.disabled = nextProps.hasOwnProperty('disabled') ? nextProps.disabled : false;
        }

        if (nextProps.isValid !== this.state.isValid) {
            let validator = this.onValidate({
                name: this.props.name,
                value: nextProps.value
            });

            state.isValid =  validator.isValid;
            state.textError =  validator.textError;
        }

        if (!(Object.keys(state).length === 0 && state.constructor === Object)) this.setState(state);
    }

    onInput (e) {
        let value = e.target.value;

        let name = this.props.name;

        if(precision(value) > this.options.precision) return false;

        if (e.target.value === '-')
            return;
        else if (!isFinite(value))
            value = null;

        if(!isEmpty(value)){
            if(value.toString().length > this.options.maxLength) return false;
        }


        if (!isFinite(value)) value = null;

        let validator = this.onValidate({
            name: this.props.name,
            value: value
        });

        let state = {
            value: parseFloat(e.target.value),
        };

        if (!isEmpty(value)) {
            state.isValid =  validator.isValid;
            state.textError = validator.textError;
        }

        this.setState(state);
        if (this.options.enabledHandlerOnInput) this.props.onChange(value, name, validator);

        if (this.props.hasOwnProperty('onInput')) this.props.onInput(value, name, validator);
    }

    handleChange (value) {
        let name = this.props.name;
        if (!isFinite(value)) value = null;

        if(precision(value) > this.options.precision) {
            return false;
        }

        let validator = this.onValidate({
            name: this.props.name,
            value: value
        });

        this.setState({
            value: value,
            isValid: validator.isValid,
            textError: validator.textError
        });

        if (this.props.hasOwnProperty('onChange')) this.props.onChange(value, name , validator);
        if (this.props.hasOwnProperty('onBlur')) this.props.onBlur(value, name , validator);
    }

    render() {
        // Наследуем базовые параметры
        let hasErrorClass = !this.state.isValid ? ' has-error' : '';
        let labelRow = this.options.labelLeft ? ' d-flex flex-wrap justify-content-between' : '',
			styleLabel = {},
			styleInput = {};

        if (this.props.labelLeft) {
			styleInput.width = this.options.widthInput;
			styleLabel.width = this.options.widthLabel;
		}

        styleLabel.lineHeight = this.options.lineHeight;

        return (
            <div id={this.props.name} className={'custom-input' + hasErrorClass + ' ' + labelRow}
                onMouseOver={this.props.onMouseOver}
                onMouseOut={this.props.onMouseOut}
            >
            {!isEmpty(this.props.label) &&
            <label htmlFor=""
				   className={(this.props.labelLeft)?' m-0' : 'm-0'}
				   style={styleLabel}
			> {ReactHtmlParser(this.props.label)}
                {!isEmpty(this.props.whisper) &&
                this.props.whisper
                }
			</label>
            }
            <div className={'custom-input__container'}
				 style={styleInput}>
            <NumericInput
                id={this.props.name}
                disabled={this.props.disabled}
                className={this.options.className}
                placeholder={this.options.placeholder}
                value={this.props.value}
                step={this.options.step}
                min={this.options.min}
                max={this.options.max}
                onStep={this.handleChange.bind(this)}
                onChange={this.handleChange.bind(this)}
                onFocus={this.props.onFocus}
                onBlur={this.handleChange.bind(this)}
                onInput={this.onInput.bind(this)}
                />
             
                </div>
                {(!this.state.isValid) &&
                <div className="error-wrapper w-100" style={{
                    display: (!this.state.isValid) ? 'block' : 'none'
                }}>{ReactHtmlParser(this.state.textError)}</div>
                }
            </div>
            );
    }
}

export default CustomNumericInput;