import React, { Component } from 'react';
import _ from 'lodash'
import { ToggleButton,ToggleButtonGroup} from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'react-tabs/style/react-tabs.css';

import highTraversaSvgL from '../../../img/highTraversaSvgL.svg';
import highTraversaSvgB from '../../../img/highTraversaSvgB.svg';
import lowTraversa from '../../../img/lowTraversa.svg';
import twoBranches from '../../../img/twoBranches.svg';
import './index.css';
import CustomNumericInput from "../../CustomNumericInput/CustomNumericInput";
import ConstructProperties from "../../ConstructProperties/index.jsx";
import {isEmpty} from "../../Global/Global";
import Collapsible from "react-collapsible";
import Select from 'react-select';
import {lang, popover} from "../../../Modules/Global";
import CommonGeoParameters from "../Components/CommonGeoParameters";
import {stylesReactSelect} from "../../Helpers/style";
import ColumnTypeMetal from "../../ColumnTypeMetal/ColumnTypeMetal";

class PillarFoundationSolve extends Component {

	constructor(props) {
		super(props);

		this.app = this.props.app;
		this.wrapper = this.props.wrapper;

		this.svgTwoBranchesHandler = this.wrapper.svgTwoBranchesHandler;
		this.svgOneBranchHandler = this.wrapper.svgOneBranchHandler;
		this.paramsHandler = this.wrapper.paramsHandler;
		this.toggleButtonsGroupHandler = this.wrapper.toggleButtonsGroupHandler;

		this.myRef = React.createRef();
		this.svgImg = {};

		// Список форм подошв
		this.optionsSoleShapes = [
			{ id: 'прямоугольная', title: lang('Прямоугольная') },
			{ id: 'круглая', title: lang('Круглая') },
			{ id: 'квадратная', title: lang('Квадратная') }
		];

        // Список сопряжений
        this.optionsColumnTypes = [
            { id: 'монолитная', title: lang('Монолитную Ж/Б') },
            { id: 'металлическая', title: lang('Металлическую') },
            { id: 'сборная', title: lang('Сборную Ж/Б') }
        ];

        // Список типов подколонника
        this.optionsFootTypes = [
            { id: 'круглый', title: lang('Круглый') },
            { id: 'прямоугольный', title: lang('Прямоугольный') }
        ];

		// Конструктивные особенности фундамента
		this.optionsFoundationDesignFeatures = [
			{ id: 'наружную_стену', title: lang('Фундамент под наружную стену') },
			{ id: 'внутреннюю_стену', title: lang('Фундамент под внутреннюю стену') },
		];

		// Классы арматуры
		this.optionsReinforcementGrades = ['A240', 'A400', 'A500', 'A600', 'A800', 'A1000'].map(item => {
			return {
				label: item,
				value: item
			}
		});

		// Классы бетона
		this.optionsConcreteGrades = ["B15", "B20", "B25", "B30", "B35", "B40", "B45", "B50", "B55", "B60", "B70", "B80", "B90", "B100"].map(item => {
			return {
				label: item,
				value: item
			}
		});

		this.state = {
			width: 200,
			height: 200,
			delayShowPopover: 300,
			placementPopover: "auto",

			collapseState: {
				common: false,
				foundation: false,
				building: false,
				subcolumn: false
			},

			su: null,

			// Наличие бетонной подготовки
			preparationFoundation: this.app.state['наличие_подготовки'],

			selectedSoleShape: _.find(this.optionsSoleShapes, {
				id: this.app.state['форма_подошвы']
			}),

            selectedColumnType: _.find(this.optionsColumnTypes, {
                id: this.app.state['тип_колонны']
            }),

            selectedFootType: _.find(this.optionsFootTypes, {
                id: this.app.state['тип_подколонника']
            }),

			selectedFoundationDesignFeature: _.find(this.optionsFoundationDesignFeatures, {
				id: this.app.state['фундамент_под']
			}),

			tabIndex:0,
		};

		this.wrapper = this.props.wrapper;
	}

    validateField = (props) => {
        const value = props.value;
        const name = props.name;
        let isValid = true,
            textError = 'Заполните поле';

        switch (name) {
            case 'вручную_значение':
                if(this.app.state['рассчет_LB'] === 'вручную'){
                    if (value < 0.3) {
                        isValid = false;
                        textError = 'Сторона фундамента слишком мала';
                    }
                    if(value > 25){
                        isValid = false;
                        textError = 'Сторона фундамента слишком велика';
                    }
                    if(isEmpty(value)) {
                        isValid = false;
                        textError = 'Заполните поле';
                    }
                }
                break;
            case 'hn':
                if(this.app.state['наличие_подготовки']){
                    if(isEmpty(value)) {
                        isValid = false;
                        textError = 'Заполните поле';
                    }

                    if(value < 0.1 || value > 1){
                        isValid = false;
                        textError = 'hn должно быть не более 1 м и не менее 0.1 м';
                    }
                }
                break;
            case 'Su':
                if (value < 0) {
                    isValid = false;
                    textError = 'Осадка не может быть отрицательной';
                }
                if(isEmpty(value)){
                    isValid = false;
                    textError = "Заполните поле";
                }
                break;
            case 'la':
            case 'ba':
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if (value < 0.05 || isEmpty(value) || value > 0.8) {
                            isValid = false;
                            textError = 'Расстояние от оси колонны до оси болта не менее 0.05 м и не более 0.8 м';
                        }
                    }
                }
                break;
            case 'соотношение_значение':
                if(value < 0.6 || value > 0.85){
                    isValid = false;
                    textError = 'Рекомендуется принимать соотношение сторон прямоугольного фундамента в пределах 0.6-0.85 в соответствии с СП 50-101-2004';
                }
                if(value < 0){
                    isValid = false;
                    textError = 'Соотношение сторон не может быть отрицательным';
                }
                if(value > 1){
                    isValid = false;
                    textError = 'Соотношение сторон не может быть больше 1';
                }
                break;
            case 'kh_temp':
                if(value < 0){
                    isValid = false;
                    textError = 'Температура не может быть отрицательной, введите корректные данные';
                }
                if(value > 45){
                    isValid = false;
                    textError = 'Температура должна быть не более 45';
                }
                if(isEmpty(value)) isValid = false;
                break;

            case 'bp':
                if(value < 0.2 || value > 1){
                    isValid = false;
                    textError = 'Введены некорректные размеры анкерной плиты, уточните размеры';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(value <= this.app.state.c_*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                if(isEmpty(value)) isValid = false;
                break;
            case 'lp':
                if(value < 0.2 || value > 1){
                    isValid = false;
                    textError = 'Введены некорректные размеры анкерной плиты, уточните размеры';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(value <= this.app.state.c*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                if(isEmpty(value)) isValid = false;
                break;
            case 'lc':
                if(this.app.state['тип_колонны'] !== 'металлическая'){
                    if((this.app.state['тип_подколонника'] !== 'круглый' && this.app.state['форма_подошвы'] === 'круглая') || this.app.state['тип_колонны'] === 'монолитная' ){
                        if(value < 0.3 || value > 1.2){
                            isValid = false;
                            textError = 'Длина подколонника должна быть в пределах от 0.3 м до 1.2 м';
                        }
                    }
                }
                if(this.app.state['тип_колонны'] === 'сборная'){
                    if(value > 1.6){
                        isValid = false;
                        textError = 'Сечение колонны должно быть в пределах от 0.1 м до 1.6 м';
                    }
                }

                break;
            case 'bc':
                if(this.app.state['тип_колонны'] !== 'металлическая'){
                    if((this.app.state['тип_подколонника'] !== 'круглый' && this.app.state['форма_подошвы'] === 'круглая') || this.app.state['тип_колонны'] === 'монолитная' ){
                        if(value < 0.3 || value > 1.2){
                            isValid = false;
                            textError = 'Длина подколонника должна быть в пределах от 0.3 м до 1.2 м';
                        }
                    }
                }
                if(this.app.state['тип_колонны'] === 'сборная'){
                    if(value > 1.6){
                        isValid = false;
                        textError = 'Сечение колонны должно быть в пределах от 0.1 м до 1.6 м';
                    }
                }
                break;
            case 'Yc_l':
                if(value > 120){
                    isValid = false;
                    textError = 'L не более 120 м';
                }
                if(value < 1){
                    isValid = false;
                    textError = 'L не менее 1 м';
                }
                if(isEmpty(value)){
                    isValid = false;
                    textError = 'Заполните поле';
                }
                break;
            case 'Yc_h':
                if(value > 60){
                    isValid = false;
                    textError = 'H не более 60 м';
                }
                if(value < 1){
                    isValid = false;
                    textError = 'H не менее 1 м';
                }
                if(isEmpty(value)){
                    isValid = false;
                    textError = 'Заполните поле';
                }
                break;
            case 'h':
                if(value < 0.2 || value > 3 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние в осях между ветвями колонны должно быть не менее 0.2 м и не более 3 м';
                }
                break;
            case 'k':
                if(value < 0.15 || value > 1.1 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до противоположной грани болта должна быть в пределах от 0.15 м до 1.1 м';
                }
                break;
            case 'La':
                if(value < 0.2 || value > 1 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до противоположной грани пластины должно быть в пределах от 0.2 м до 1 м';
                }
                break;
            case 'c':
                if(value < 0.1 || value > 0.6 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до оси колонны должно быть в пределах от 0.1 м до 0.6 м';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(this.app.state.lp <= value*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                break;
            case 'c_':
                if(value < 0.02 || value > 0.6 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до оси колонны должно быть в пределах от 0.02 м до 0.6 м';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(this.app.state.bp <= value*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                break;
            case 'LBc':
                if(this.app.state['форма_подошвы'] === 'круглая') {
                    if (this.app.state['тип_подколонника'] === 'круглый') {
                        if (value < 0.3 || value > 1.2 || isEmpty(value)) {
                            isValid = false;
                            textError = 'Диаметр подколонника  должна быть в пределах от 0.3 м до 1.2 м';
                        }
                    }
                }
                break;
            case 'dc':
                if(this.app.state['тип_колонны'] === 'сборная'){
                    if(value > 2){
                        isValid = false;
                        textError = 'Глубина заделки должна быть меньше из условия соотношения сечения колонны к глубине ее заделки';
                    }
                    if(Math.max(this.app.state.lc,this.app.state.bc) > value){
                        isValid = false;
                        textError = 'Условие заделки колонны в стакан не обеспеченно убедитесь в правильности введенных данных';
                    }
                    if(!isEmpty(this.app.state['высота_фундамента'])){
                        if(value > this.app.state['высота_фундамента'] - 0.2){
                            isValid = false;
                            textError = 'Высота фундамента меньше глубины заделки колонны в стакан';
                        }
                    }
                }
                break;
        }

        if(value < 0){
            isValid = false;
            textError = 'Значение не может быть отрицательным';
        }
        return {
            isValid: isValid,
            textError: (isValid) ? '' : textError
        }
    };

    customNumericInputHandler = (value, name , validator) => {
        let errors = this.app.state.errors;

        if(!validator.isValid) {
            if(name === 'соотношение_значение') {
                if (value < 0 || value > 1) {
                    errors[name] = {
                        textError: validator.textError,
                        tab: '3'
                    };
                }
            }else {
                errors[name] = {
                    textError: validator.textError,
                    tab: '3'
                };
            }
        }else{
            delete errors[name];
        }

        this.app.setState({
            [name]: value,
            errors:errors
        });
    };

	handleTriggerClickCollapsible = (name) => {
		let collapseState = this.state.collapseState;

		Object.entries(collapseState).forEach(([key, value]) => {
			if (key !== name) collapseState[key] = false;
		});

		collapseState[name] = !collapseState[name];

		this.setState({
			collapseState
		})
	};

    popover = () =>{
        return (
			<div className="imgS" >
				<object id="svg-object"  data={twoBranches} type="image/svg+xml" />
				<object id="lowTraversaSvg" data={lowTraversa} type="image/svg+xml" />
				<object id="highTraversaSvgL" data={highTraversaSvgL} type="image/svg+xml" />
				<object id="highTraversaSvgB" data={highTraversaSvgB} type="image/svg+xml" />
			</div>
		)
    };

    customCheckboxHandler = (name,checked) =>{
        this.app.setState({
            [name]:checked,
        })

    };

	boltHandler = (number) =>{
		let svg;

		if(this.app.state['тип_металлической_колонны'] === 'двухветвевая'){
			svg = document.getElementById('svg-object');
		}else{
			if(this.app.state['база_колонны'] === 'низкая'){
				svg = document.getElementById('lowTraversaSvg');
			}else{
				svg = document.getElementById('highTraversaSvgB');
			}

		}
		const svgDoc = svg.contentDocument;

		const allBolts = svgDoc.getElementsByClassName('n');
		const bolts = svgDoc.getElementsByClassName('n-' + number);

		for(let i = 0;i < allBolts.length;i++){
			let bolt = allBolts[i];

			bolt.style.visibility = 'hidden';

		}

		for(let i = 0;i < bolts.length;i++){
			let bolt = bolts[i];

			bolt.style.visibility = 'visible';
		}
	}



    render() {
        return (
            <div className='container-fluid'>
                <div className="row">
					<div className="Collapsible-wrapper" style={{marginTop: 15, marginLeft: -15, marginRight: -15}}>

						<Collapsible accordionPosition={'common'}
									 handleTriggerClick={this.handleTriggerClickCollapsible}
									 trigger={'Общие параметры'}
									 overflowWhenOpen={'visible'}
									 open={this.state.collapseState.common}>
							<div className="Collapsible-content">
								<div className="Collapsible-panel">
									<CommonGeoParameters app={this.app}/>
								</div>
							</div>
						</Collapsible>

						<Collapsible accordionPosition={'foundation'}
									 handleTriggerClick={this.handleTriggerClickCollapsible}
									 trigger={'Характеристики фундамента'}
									 overflowWhenOpen={'visible'}
									 open={this.state.collapseState.foundation}>
							<div className="Collapsible-content">
								<div className="Collapsible-panel">
									<div style={{paddingBottom: 10}}>
										<div className="row no-gutters">
											<div className="w-100">
												<div className="form-group">
													<label htmlFor="">{lang('Форма подошвы') + ':'}</label>
													<div className="Select-control">
														<Select
                                                            menuPortalTarget={document.body}
															styles={stylesReactSelect}
															value={this.state.selectedSoleShape}
															onChange={selected => {
																this.setState({
																	selectedSoleShape: selected
																}, () => this.app.setState({'форма_подошвы': selected.id}));
															}}
															options={this.optionsSoleShapes}
															getOptionLabel ={option => option.title}
															getOptionValue ={option => option.id}
															placeholder={lang('Выберите форму подошвы')}
														/>
													</div>
												</div>

												{(this.state.selectedSoleShape.id === 'прямоугольная') &&
												<div>
													<ToggleButtonGroup onChange={this.toggleButtonsGroupHandler}
																	   type="radio"
																	   name="рассчет_LB"
																	   value={this.app.state["рассчет_LB"]}
																	   className="w-100">
														<ToggleButton size="sm" variant="light" value="вручную">{lang('Задать l или b')}</ToggleButton>
														<ToggleButton size="sm" variant="light" value="соотношение">{lang('Задать b/l')}</ToggleButton>
													</ToggleButtonGroup>

													{this.app.state["рассчет_LB"] === "вручную" &&
													<div className="d-flex">
														<div className="col-6">
															<div className="row">
																<ToggleButtonGroup type="radio"
																				   onChange={this.toggleButtonsGroupHandler}
																				   name="вручную_сторона"
																				   value={this.app.state['вручную_сторона']}
																				   className="w-100"
																				   style={{
																				   		paddingRight: 5
																				   }}>
																	<ToggleButton size="sm" variant="light" className="btn-block w-50" value="l">l (м)</ToggleButton>
																	<ToggleButton size="sm" variant="light" className="btn-block w-50" value="b">b (м)</ToggleButton>
																</ToggleButtonGroup>
															</div>
														</div>
														<div className="col-6">
															<div className="row">
																<CustomNumericInput
																	name={'вручную_значение'}
																	className="form-control input-text m-0"
																	value={this.app.state['вручную_значение']}
																	allowNegative={false}
																	measure={'[м]'}
																	precision={2}
																	label={""}
																	min={0}
																	step={0.1}
																	onValidate={this.validateField}
																	enabledHandlerOnInput={true}
																	isValid={!this.app.state.errors.hasOwnProperty('вручную_значение')}
																	onChange={this.customNumericInputHandler}
																/>
															</div>
														</div>
													</div>
													}
													{this.app.state['рассчет_LB'] === 'соотношение' &&
													<div>
														<CustomNumericInput
															name={'соотношение_значение'}
															className="form-control input-text"
															value={this.app.state['соотношение_значение']}
															allowNegative={false}
															measure={''}
															labelLeft={true}
															widthLabel={'65%'}
															widthInput={'35%'}
															label={lang('Соотношение сторон фундамента, (b/l)') + ':'}
															step={0.05}
															min={0.05}
															onValidate={this.validateField}
															enabledHandlerOnInput={true}
															isValid={true}
															onChange={this.customNumericInputHandler}
														/>
													</div>
													}
												</div>
												}

												<div className="form-group" style={{marginTop: 5}}>
													<label htmlFor="">{lang('Конструктивные особенности фундамента') + ':'}</label>
													<div className="Select-control">
														<Select
															styles={stylesReactSelect}
															value={this.state.selectedFoundationDesignFeature}
															onChange={selected => {
																this.setState({
																	selectedFoundationDesignFeature: selected
																}, () => this.app.setState({'фундамент_под': selected.id}));
															}}
															options={this.optionsFoundationDesignFeatures}
															getOptionLabel ={option => option.title}
															getOptionValue ={option => option.id}
															placeholder={lang('Выберите тип фундамента')}
														/>
													</div>
												</div>

												<div>
													<div className="switch-wrapper">
														<label className="switch">
															<input type="checkbox"
																   name={'наличие_подготовки'}
																   defaultChecked={this.state.preparationFoundation}
																   onChange={() => {
																   		let checked = !this.state.preparationFoundation;

																   		this.setState({preparationFoundation: checked}, () => {
																   			this.app.setState({'наличие_подготовки': checked});
																		});
																   }}/>
															<span className="slider"></span>
														</label>
														<span className="switch-label">{lang('Наличие бетонной подготовки')}</span>
														<div className="clearfix"></div>
													</div>

													{this.state.preparationFoundation &&
														<div className="form-group">
															<CustomNumericInput
																name={'hn'}
																className="form-control input-text"
																value={this.app.state['hn']}
																allowNegative={false}
																measure={'[м]'}
																precision={2}
																min={0}
																labelLeft={true}
																widthLabel={'65%'}
																widthInput={'35%'}
																label={"Толщина подготовки h<sub>n</sub>, (м):"}
																step={0.1}
																onValidate={this.validateField}
																enabledHandlerOnInput={true}
																isValid={!this.app.state.errors.hasOwnProperty('hn')}
																onChange={this.customNumericInputHandler}
															/>
														</div>
													}
												</div>

												<div className="d-flex form-group">
													<p style={{width: '65%'}}>{lang('Класс арматуры')}:</p>
													<Select
														menuPortalTarget={document.body}
														styles={stylesReactSelect}
														name={"класс_арматуры"}
														className={"w-35"}
														onChange={option => this.app.setState({"класс_арматуры": option.value})}
														defaultValue={this.optionsReinforcementGrades.find(option => option.value === this.app.state['класс_арматуры'])}
														options={this.optionsReinforcementGrades}
													/>
												</div>

												<div className="d-flex form-group">
													<p style={{width: '65%'}}>{lang('Класс бетона')}:</p>
													<Select
														menuPortalTarget={document.body}
														styles={stylesReactSelect}
														name={"класс_бетона"}
														className={"w-35"}
														onChange={option => this.app.setState({"класс_бетона": option.value})}
														defaultValue={this.optionsConcreteGrades.find(option => option.value === this.app.state['класс_бетона'])}
														options={this.optionsConcreteGrades}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Collapsible>

						<Collapsible accordionPosition={'building'}
									 handleTriggerClick={this.handleTriggerClickCollapsible}
									 trigger={'Конструктивные особенности здания'}
									 overflowWhenOpen={'visible'}
									 open={this.state.collapseState.building}>
							<div className="Collapsible-content">
								<div className="Collapsible-panel">
									<div style={{paddingBottom: 10}}>
										<div className="row no-gutters">
											<ConstructProperties
												parent={this}
												app={this.app}
											/>
										</div>
									</div>
								</div>
							</div>
						</Collapsible>

						<Collapsible accordionPosition={'subcolumn'}
									 handleTriggerClick={this.handleTriggerClickCollapsible}
									 trigger={'Тип и параметры подколонника'}
									 overflowWhenOpen={'visible'}
									 open={this.state.collapseState.subcolumn}>
							<div className="Collapsible-content">
								<div className="Collapsible-panel">
									<div style={{paddingBottom: 10}}>
										<div className="row no-gutters">
											<div className="w-100">
                                                <div className="form-group">
                                                    <label htmlFor="">{lang('Сопряжение с фундаментом') + ':'}</label>
                                                    <div className="Select-control">
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={stylesReactSelect}
                                                            value={this.state.selectedColumnType}
                                                            onChange={selected => {
                                                                this.setState({
                                                                    selectedColumnType: selected
                                                                }, () => this.app.setState({'тип_колонны': selected.id}));
                                                            }}
                                                            options={this.optionsColumnTypes}
                                                            getOptionLabel ={option => option.title}
                                                            getOptionValue ={option => option.id}
                                                            placeholder={lang('Выберите тип колонны')}
                                                        />
                                                    </div>
                                                </div>
												{(this.app.state['тип_колонны'] === 'монолитная') &&
												<div>
													{(this.app.state['форма_подошвы'] !== 'круглая') &&
													<div>
														<div className="form-group">
															<CustomNumericInput
																name={'lc'}
																className="form-control input-text"
																value={this.app.state['lc']}
																allowNegative={false}
																measure={'[м]'}
																precision={2}
																labelLeft
                                                                widthLabel={'65%'}
                                                                widthInput={'35%'}
																label={"Длина подколонника l<sub>c</sub>, (м):"}
																min={0}
																step={0.1}
																onValidate={this.validateField}
																enabledHandlerOnInput={true}
																isValid={!this.app.state.errors.hasOwnProperty('lc')}
																onChange={this.customNumericInputHandler}
															/>
														</div>
														<div className="form-group">
															<CustomNumericInput
																name={'bc'}
																className="form-control input-text"
																value={this.app.state['bc']}
																allowNegative={true}
																measure={'[м]'}
																precision={2}
																labelLeft
                                                                widthLabel={'65%'}
                                                                widthInput={'35%'}
																label={"Ширина подколонника b<sub>c</sub>, (м):"}
																min={0}
																step={0.1}
																onValidate={this.validateField}
																enabledHandlerOnInput={true}
																isValid={!this.app.state.errors.hasOwnProperty('bc')}
																onChange={this.customNumericInputHandler}
															/>
														</div>
													</div>
													}
													{(this.state.selectedSoleShape.id === 'круглая') &&
													<div>
														{(this.app.state['тип_подколонника'] === 'круглый') &&
                                                        <div className="form-group">
                                                            <CustomNumericInput
                                                                name={'LBc'}
                                                                className="form-control input-text"
                                                                value={this.app.state['LBc']}
                                                                allowNegative={false}
                                                                measure={'[м]'}
                                                                precision={2}
                                                                labelLeft
                                                                widthLabel={'65%'}
                                                                widthInput={'35%'}
                                                                label={"Диаметр круглого подколонника, (м):"}
                                                                min={0}
                                                                step={0.1}
                                                                onValidate={this.validateField}
                                                                enabledHandlerOnInput={true}
                                                                isValid={!this.app.state.errors.hasOwnProperty('LBc')}
                                                                onChange={this.customNumericInputHandler}
                                                            />
                                                        </div>
														}
														{(this.app.state['тип_подколонника'] === 'прямоугольный') &&
														<div>
															<div className="form-group">
																<CustomNumericInput
																	name={'lc'}
																	className="form-control input-text"
																	value={this.app.state.lc}
																	allowNegative={false}
																	measure={'[м]'}
																	precision={2}
																	labelLeft
                                                                    widthLabel={'65%'}
                                                                    widthInput={'35%'}
																	label={"Длина подколоника, (м):"}
																	min={0}
																	step={0.1}
																	onValidate={this.validateField}
																	enabledHandlerOnInput={true}
																	isValid={!this.app.state.errors.hasOwnProperty('lc')}
																	onChange={this.customNumericInputHandler}
																/>
															</div>
															<div className="form-group">
																<CustomNumericInput
																	name={'bc'}
																	className="form-control input-text"
																	value={this.app.state.bc}
																	allowNegative={false}
																	measure={'[м]'}
																	precision={2}
																	labelLeft
                                                                    widthLabel={'65%'}
                                                                    widthInput={'35%'}
																	label={"Ширина подколоника, (м):"}
																	step={0.1}
																	onValidate={this.validateField}
																	enabledHandlerOnInput={true}
																	isValid={!this.app.state.errors.hasOwnProperty('bc')}
																	onChange={this.customNumericInputHandler}
																/>
															</div>
														</div>
														}
                                                        <div className="form-group">
                                                            <label htmlFor="">{lang('Тип подколонника') + ':'}</label>
                                                            <div className="Select-control">
                                                                <Select
                                                                    menuPortalTarget={document.body}
                                                                    styles={stylesReactSelect}
                                                                    value={this.state.selectedFootType}
                                                                    onChange={selected => {
                                                                        this.setState({
                                                                            selectedFootType: selected
                                                                        }, () => this.app.setState({'тип_подколонника': selected.id}));
                                                                    }}
                                                                    options={this.optionsFootTypes}
                                                                    getOptionLabel ={option => option.title}
                                                                    getOptionValue ={option => option.id}
                                                                    placeholder={lang('Выберите тип подколонника')}
                                                                />
                                                            </div>
                                                        </div>
													</div>
													}
												</div>
												}
												{(this.app.state['тип_колонны'] === 'сборная') &&
												<div>
													<div className="form-group">
														<CustomNumericInput
															name={'lc'}
															className="form-control input-text"
															value={this.app.state['lc']}
															allowNegative={false}
															measure={'[м]'}
															precision={2}
															labelLeft
                                                            widthLabel={'65%'}
                                                            widthInput={'35%'}
															label={"Длина колонны l, (м):"}
															min={0}
															step={0.1}
															onValidate={this.validateField}
															enabledHandlerOnInput={true}
															isValid={!this.app.state.errors.hasOwnProperty('lc')}
															onChange={this.customNumericInputHandler}
														/>
													</div>
													<div className="form-group">
														<CustomNumericInput
															name={'bc'}
															className="form-control input-text"
															value={this.app.state['bc']}
															allowNegative={false}
															measure={'[м]'}
															precision={2}
															labelLeft
                                                            widthLabel={'65%'}
                                                            widthInput={'35%'}
															label={"Ширина колонны b, (м):"}
															min={0}
															step={0.1}
															onValidate={this.validateField}
															enabledHandlerOnInput={true}
															isValid={!this.app.state.errors.hasOwnProperty('bc')}
															onChange={this.customNumericInputHandler}
														/>
													</div>
													<div className="form-group">
														<CustomNumericInput
															name={'dc'}
															className="form-control input-text"
															value={this.app.state['dc']}
															allowNegative={false}
															measure={'[м]'}
															precision={2}
															labelLeft
                                                            widthLabel={'65%'}
                                                            widthInput={'35%'}
															label={"Глубина заделки колонны в стакан dc, (м):"}
															step={0.1}
															min={0}
															onValidate={this.validateField}
															enabledHandlerOnInput={true}
															isValid={!this.app.state.errors.hasOwnProperty('dc')}
															onChange={this.customNumericInputHandler}
														/>
													</div>
												</div>
												}
												{(this.app.state['тип_колонны'] === 'металлическая') &&
													<ColumnTypeMetal
														app={this.app}
														parent={this}
														validateField={this.validateField}
													/>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Collapsible>
					</div>
                </div>
            </div>
        );
    }
}

export default PillarFoundationSolve;
