import React, { Component } from 'react';
import { Container,ToggleButton,ToggleButtonGroup,Form,Col,Row } from 'react-bootstrap'
import Select from 'react-select'
import { ReactSVG } from 'react-svg'
import $ from 'jquery'
import CustomNumericInput from "../../CustomNumericInput/CustomNumericInput";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import './index.css';
import surveysImg from '../../../img/surveysImg.svg';
import {isEmpty,precision} from "../../Global/Global";
import {Checkbox, Whisper, Modal, Button} from "rsuite";
import {lang, popover} from "../../../Modules/Global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faSearchPlus} from "@fortawesome/free-solid-svg-icons";
import ReactHtmlParser from "react-html-parser";

const styleMultilineLabel = {width: '65%', lineHeight: '16px', alignSelf: 'center', marginBottom: 0},
    styleMultiLineInput = {width: '35%'};

class PillarSurveys extends Component {

	constructor(props) {
		super(props);

		this.app = this.props.app;

		this.state = {
			isAbsolutePosition: this.app.state['привязка_к_абсолютным_отметкам'],
			isPlannedTerritory: this.app.state['планируемость_территории']
		};
	}

	selectHandler = (e) => {

		this.app.setState({'город':e.value});
		this.setState({selectedOption:e})

	};

	customCheckboxHandler = (v,checked) =>{
		this.app.setState({
			'привязка_к_абсолютным_отметкам':checked,
		});
	};

	lineHandler = (lines,state,color = 'black') => {
		if(state === 'show'){
			for(let i = 0; i < lines.length;i++){
				if(lines[i].hasAttribute('stroke')){
					lines[i].setAttribute('stroke',color);
					lines[i].setAttribute('stroke-width','1px');
				}else{
					lines[i].setAttribute('fill',color);
				}
			}
		}
		if(state === 'hide'){
			for(let i = 0; i < lines.length;i++){
				if(lines[i].hasAttribute('stroke')){
					lines[i].setAttribute('stroke','none');
					lines[i].setAttribute('stroke-width','3px');
				}else{
					lines[i].setAttribute('fill','none');
				}
			}
		}
	};

	validateField = (props) => {

		const value = props.value;
		const name = props.name;

		let isValid = true;
		let textError = 'Ошибка в заполнении поля';

		switch (name) {
			case 'dw':

				let deep = 0;
				this.app.state.surveysData.forEach(item => deep+= item.глубина_залегания);

				isValid = true;
				if (value < 0) {
					isValid = false;
					textError = 'Уровень грунтовых вод не может залегать выше поверхности грунта';
				}
				if(deep < value && value !== 0){
					isValid = false;
					textError = 'Уровень грунтовых вод ниже заданной мощности слоев, убедитесь в правильности введенных данных';
				}
				break;
			case 'глубина_заложения':
				if(value < 0){
					isValid = false;
					textError = 'Не может быть отрицательной';
				}
				if(value < 0.5 || value > 10){
					isValid = false;
					textError = 'Глубина заложения должна быть не мене 0.5 м и не более 10 м';
				}

				if(!this.state.isPlannedTerritory){
					if(value < this.app.state['глубина_слоя'] && !isEmpty(value)){
						isValid = false;
						textError = 'Подошва фундамента залегает на насыпном грунте,увеличьте глубину заложения';
					}else{
						if(this.app.state.errors.hasOwnProperty('глубина_слоя')){
							let errors = this.app.state.errors;
							delete errors['глубина_слоя'];
							this.app.setState({errors:errors});
						}
					}
				}

				if (isEmpty(value)) isValid = true;
				break;
			case 'высота_фундамента':
				if(this.app.state['тип_расчета'] === 'check' && this.app.state['anchor'] && (!this.app.state['reinforcement'] && !this.app.state['size'])){
					if (isEmpty(value) || parseFloat(value) === 0) {

						textError = 'Укажите высоту фундамента для сбора нагрузок приходящий на верх фундамента';
						isValid = false;
					}
				}else{
					if(value < 0){
						isValid = false;
						textError = 'Не может быть отрицательной';
					}
					if(value < 0.6 || value > 15){
						isValid = false;
						textError = 'Высота фундамента должна быть не мене 0.6 м и не более 15 м';
					}
					if (isEmpty(value)) isValid = true;
				}
				break;
			case 'глубина_слоя':
				if (isEmpty(value)) {
					isValid = false;
					textError = 'Не задана мощность почвенно-растительного (насыпного) слоя грунта';
				}
				if(!this.state.isPlannedTerritory) {
					if (value > this.app.state['глубина_заложения'] && !isEmpty(this.app.state['глубина_заложения'])) {
						isValid = false;
						textError = 'Подошва фундамента залегает на насыпном грунте,увеличьте глубину заложения';
					}
				}
				break;
			case 'YII_':
				isValid = true;
				if (value > 2.5) {
					isValid = false;
					textError = 'Удельный вес грунта должен быть не более 2.5 гр/см<sup>2</sup>';
				}
				if (isEmpty(value)) {
					isValid = false;
					textError = 'Заполните поле';
				}
				if (value < 0) {
					isValid = false;
					textError = 'Удельный вес грунта не может быть отрицательным';
				}
				break;
			default:
				break;
		}

		return {
			isValid: isValid,
			textError: (isValid) ? '' : textError
		}
	};

	trackInputs = (value, name , validator) => {
		let errors = this.app.state.errors;

		if(!validator.isValid) {

			//e.target.classList.add('error-table');
			errors[name] = {
				textError: validator.textError,
				tab: '2'
			};

		}else{
			//e.target.classList.remove('error-table');
			delete  errors[name];

		}
		this.app.setState({
			[name]: value,
			errors:errors
		});
	};

	customNumericInputHandler = (value, name , validator) =>{

		let errors = this.app.state.errors;
		if(!validator.isValid) {
			errors[name] = {
				textError: validator.textError,
				tab: '2'
			};
		} else {
			delete  errors[name];
		}

		this.app.setState({
			[name]: value,
			errors:errors
		});
	};

	toggleButtonsGroupHandler = (value,e) =>{
		let name = e.target.name;
		this.app.setState({[name]:value});
	};

	renderFoundationScheme(img) {
		return (
			<ReactSVG
				src={img}
				beforeInjection={svg => {
					svg.setAttribute('width', '100%');
					svg.setAttribute('id', 'surveysImg');
				}}
				evalScripts="always"
				renumerateIRIElements={false}
			/>
		);
	}

	render() {
		return (
			<div>
				<div>
					<div className="switch-wrapper">
						<label className={'switch' + (this.app.state['тип_расчета'] === 'check' ? ' switch-disabled' : '')}>
							<input type="checkbox"
								   name={'привязка_к_абсолютным_отметкам'}
								   disabled={this.app.state['тип_расчета'] === 'check'}
								   checked={this.app.state['привязка_к_абсолютным_отметкам']}
								   onChange={() => {
									   let checked = !this.state.isAbsolutePosition;

									   this.setState({isAbsolutePosition: checked}, () => {
										   this.app.setState({'привязка_к_абсолютным_отметкам': checked});
									   });
								   }}/>
							<span className="slider"></span>
						</label>
						<span className="switch-label">{lang('Привязать к абсолютным отметкам')}</span>
						<div className="clearfix"></div>
					</div>


					<div className="switch-wrapper">
						<label className="switch">
							<input type="checkbox"
								   name={'планируемость_территории'}
								   defaultChecked={this.state.isPlannedTerritory}
								   onChange={() => {
									   let checked = !this.state.isPlannedTerritory;

									   this.setState({isPlannedTerritory: checked}, () => {
										   this.app.setState({'планируемость_территории': checked});
									   });
								   }}/>
							<span className="slider"></span>
						</label>
						<span className="switch-label">{lang('Планируемая территория')}</span>
						<div className="clearfix"></div>
					</div>

					<div>
						<div className="form-group custom-input  d-flex flex-wrap justify-content-between">
							<label htmlFor="отметка_верха" style={styleMultilineLabel}>
								{lang('Уровень грунтовых вод')} {ReactHtmlParser('d<sub>w</sub>')}{', (м):'}
							</label>
							<div style={styleMultiLineInput}>
								<CustomNumericInput
									name={'dw'}
									className="form-control input-text"
									value={this.app.state.dw}
									allowNegative={false}
									precision={2}
									max={999}
									step={0.1}
									onValidate={this.validateField}
									enabledHandlerOnInput={true}
									isValid={!this.app.state.errors.hasOwnProperty('dw')}
									onChange={this.customNumericInputHandler}
								/>
							</div>
						</div>

						{(this.app.state['привязка_к_абсолютным_отметкам']) &&
							/**<div className='tracks track-up' >
								<input name='отметка_верха' onChange={this.trackInputs} onBlur={this.trackInputs} className="input-text form-control mr-1"  type="text" value={this.app.state['отметка_верха']}/>
								[м]
							</div>**/
							<div className="form-group custom-input  d-flex flex-wrap justify-content-between">
								<label htmlFor="отметка_верха" style={styleMultilineLabel}>
									{lang('Значение на отметке верха') + ':'}
								</label>
								<div style={styleMultiLineInput}>
									<CustomNumericInput
										name={'отметка_верха'}
										className="form-control input-text"
										value={this.app.state['отметка_верха']}
										allowNegative={false}
										//measure={'[м]'}
										precision={2}
										//label={"Занчение на отмете верха:"}
										max={999}
										step={0.1}
										onValidate={this.validateField}
										enabledHandlerOnInput={true}
										isValid={true}
										onChange={this.customNumericInputHandler}
									/>
								</div>
							</div>
						}

						{(this.app.state['привязка_к_абсолютным_отметкам']) &&
						<div className="form-group custom-input  d-flex flex-wrap justify-content-between">
							<label htmlFor="отметка_поверхности" style={styleMultilineLabel}>
								{lang('Значение на отметке поверхности') + ':'}
							</label>
							<div style={styleMultiLineInput}>
								<CustomNumericInput
									name={'отметка_поверхности'}
									className="form-control input-text"
									value={this.app.state['отметка_поверхности']}
									allowNegative={false}
									//measure={'[м]'}
									precision={2}
									//label={"Занчение на отмете верха:"}
									max={999}
									step={0.1}
									onValidate={this.validateField}
									enabledHandlerOnInput={true}
									isValid={true}
									onChange={this.customNumericInputHandler}
								/>
							</div>
						</div>
						}

						{(this.app.state['привязка_к_абсолютным_отметкам']) &&
						<div className="form-group custom-input  d-flex flex-wrap justify-content-between">
							<label htmlFor="отметка_подошвы" style={styleMultilineLabel}>
								{lang('Значение на отметке подошвы') + ':'}
							</label>
							<div style={styleMultiLineInput}>
								<CustomNumericInput
									name={'отметка_подошвы'}
									className="form-control input-text"
									value={this.app.state['отметка_подошвы']}
									allowNegative={false}
									//measure={'[м]'}
									precision={2}
									//label={"Занчение на отмете верха:"}
									max={999}
									step={0.1}
									onValidate={this.validateField}
									enabledHandlerOnInput={true}
									isValid={true}
									onChange={this.customNumericInputHandler}
								/>
							</div>
						</div>
						}

						{(!this.app.state['привязка_к_абсолютным_отметкам']) &&
						<div className="form-group custom-input  d-flex flex-wrap justify-content-between">
							<label htmlFor="высота_фундамента" style={styleMultilineLabel}>
								{lang('Высота фундамента') + ', (м):'}
							</label>
							<div style={styleMultiLineInput}>
								<CustomNumericInput
									name={'высота_фундамента'}
									className="form-control input-text"
									value={this.app.state['высота_фундамента']}
									allowNegative={false}
									disabled={(this.app.state['тип_расчета'] === 'check' && (!this.app.state['anchor'] || (this.app.state['reinforcement'] || this.app.state['reinforcement'])))}
									//measure={'[м]'}
									precision={2}
									//label={"Занчение на отмете верха:"}
									max={999}
									step={0.1}
									onValidate={this.validateField}
									enabledHandlerOnInput={true}
									isValid={true}
									onChange={this.customNumericInputHandler}
								/>
							</div>
						</div>
						}

						{(!this.app.state['привязка_к_абсолютным_отметкам']) &&
						<div className="form-group custom-input  d-flex flex-wrap justify-content-between">
							<label htmlFor="глубина_заложения" style={styleMultilineLabel}>
								{lang('Глубина заложения') + ', (м):'}
							</label>
							<div style={styleMultiLineInput}>
								<CustomNumericInput
									name={'глубина_заложения'}
									className="form-control input-text"
									value={this.app.state['глубина_заложения']}
									allowNegative={false}
									disabled={this.app.state['тип_расчета'] === 'check'}
									//measure={'[м]'}
									precision={2}
									//label={"Занчение на отмете верха:"}
									max={999}
									step={0.1}
									onValidate={this.validateField}
									enabledHandlerOnInput={true}
									isValid={true}
									onChange={this.customNumericInputHandler}
								/>
							</div>
						</div>
						}
					</div>
				</div>

				<div className="form-group">
					<CustomNumericInput
						name={'YII_'}
						className="form-control input-text"
						value={this.app.state['YII_']}
						allowNegative={false}
						label={lang('Удельный вес грунта, залегающего выше подошвы фундамента') + " Y<sub>II</sub>'" + ', (г/см<sup>3</sup>):'}
						lineHeight={'18px'}
						labelLeft={true}
						widthLabel={'65%'}
						widthInput={'35%'}
						min={0}
						max={999}
						step={0.1}
						precision={3}
						onValidate={this.validateField}
						enabledHandlerOnInput={true}
						isValid={!this.app.state.errors.hasOwnProperty('YII_')}
						onChange={this.customNumericInputHandler}
					/>
				</div>

				{!this.state.isPlannedTerritory &&
				<div className="form-group">
					<CustomNumericInput
						precision={2}
						name={'глубина_слоя'}
						className="form-control input-text"
						value={this.app.state['глубина_слоя']}
						measure={'[м]'}
						label={lang('Мощность почвенно-растительного (насыпного) слоя грунта') + ' h<sub>н</sub>, (м):'}
						labelLeft={true}
						widthLabel={'65%'}
						widthInput={'35%'}
						step={0.1}
						min={0}
						onValidate={this.validateField}
						enabledHandlerOnInput={true}
						isValid={!this.app.state.errors.hasOwnProperty('глубина_слоя')}
						onChange={this.customNumericInputHandler}
					/>
				</div>
				}

			</div>
		);
	}
}

export default PillarSurveys;
