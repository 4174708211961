import React, {Component, useState, useEffect} from 'react';
import Select from "react-select";
import {lang, popover} from "../../../Modules/Global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Whisper} from "rsuite";
import _ from "lodash";
import config from "../../../Model/Config";
import {useDispatch, useSelector} from "react-redux";
import {changeSelectedCity} from "../../../store/slices/solveSlice";
import {isEmpty} from "../../Global/Global";
import {fetchCities} from "../../../store/asyncSlices/citiesSlice";

const customStyles = {
	container: base => ({
		...base,
		visibility: 'visible'
	}),
	control: base => ({
		...base,
		minHeight: 34
	}),
	dropdownIndicator: base => ({
		...base,
		padding: 4,
		visibility: 'visible'
	}),
	clearIndicator: base => ({
		...base,
		padding: 4
	}),
	valueContainer: base => ({
		...base,
		padding: '0px 6px',
		visibility: 'visible'
	}),
	input: base => ({
		...base,
		margin: 0,
		padding: 0
	}),
	option: (provided, state) => ({
		...provided,
		//borderBottom: '1px dotted pink',
		color: state.isSelected ? '#ffffff' : '#000000',
		// backgroundColor: state.isSelected ? '#007bff' : '#ffffff',
		//padding: 10,
		fontSize: 14,
		// lineHeight: '10px'
	}),
};

const CitySelector = ({...props}) => {
	const app = props.app;

	const cities = useSelector(store => store.fetched.cities.entities);

	const dispatch = useDispatch();

	useEffect(() => {
		cities.length === 0 && dispatch(fetchCities);
	}, [dispatch, cities]);

	const selectedCityId = useSelector(store => store.solve["город"]);
	// Если не задан город
	if ((isEmpty(selectedCityId) || isNaN(selectedCityId)) && !isEmpty(cities))
		dispatch(changeSelectedCity(cities[0].id));

	return (
		<div className="form-group">
			<label htmlFor="select-geotechnical-category">
				{lang('Город строительства') + ':'}
			</label>
			<div className="Select-control">
				<Select
					id="select-city"
					styles={customStyles}
					value={cities.find(city => city.value === selectedCityId)}
					onChange={selected => dispatch(changeSelectedCity(selected.value))}
					options={cities}
					getOptionLabel ={option => option.label}
					getOptionValue ={option => option.value}
					placeholder={lang('Выберите город строительства')}
				/>
			</div>
		</div>
	);
};

class _CitySelector extends Component {

	componentDidMount() {
		this.fetchCities().then(cities => {
			this.setState({
				optionsCities: cities,
				selectedCity: _.find(cities, {
					id: this.app.state['город']
				}) || cities[0],
			})
		})
	}

	fetchCities() {
		return fetch(config.url + '/api/cities')
			.then(response => { return response.json(); })
			.then(data => data.map(city => {
				return {
					value: city.id,
					label: city.city
				}
			}));
	}

}

export default CitySelector;