import 'rsuite/dist/styles/rsuite-dark.min.css';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import PillarFoundationApp from './Components/PillarFoundation/PillarApp/index.jsx'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './sb.css';
import './styles/vtabs.css';
import './report.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons/index";

class App extends Component {
    state = {
        foundationType: 'pillar',
        admin: false,
    };

    getData = () => {
        let admin = localStorage.getItem('aaa') || false;
        this.setState({admin:admin})
    };

    foundationHandler = (e) =>{
        this.setState({[e.target.name]:e.target.value});
    };

    render() {
        return (
        	<BrowserRouter>
				<div id="component-main-window" className="app-main-window">
					{/*Расчет столбачтого фундамента*/}
					<Route path={'/'} exact component={PillarFoundationApp}/>
				</div>
			</BrowserRouter>
        );
    }

    componentDidMount() {
        this.getData();
    }
}

export default App;
