const state = {

	// Данные загружениий, отображаемые в таблице по умолчанию
	loadsData: [
		{
			id: 1,
			title: "Собственный вес",
			N: "825",
			Mx: "0",
			My: "0",
			Qx: "0",
			Qy: "0",
			type: "постоянная",
		},
		{
			id: 2,
			title: "Полезные",
			N: "125",
			Mx: "185",
			My: "40",
			Qx: "10",
			Qy: "5",
			type: "длительная",
		},
		{
			id: 3,
			title: "Снег",
			N: "10",
			Mx: "14",
			My: "8",
			Qx: "0",
			Qy: "0",
			type: "кратковременная",
		},
		{
			id: 4,
			title: "Ветер",
			N: "2",
			Mx: "9",
			My: "4",
			Qx: "15",
			Qy: "10",
			type: "кратковременная",
		},
	],

	// Данные инжернерно-геологических изысканий, отображаемые в таблице по умолчанию
	surveysData: [
		{
			глубина_залегания: 4.2,
			подтип_грунта: "",
			тип_грунта: "суглинок",
			E_1: "",
			Ee_1: "",
			Esr_1: "",
			Il_1: "0.67",
			Ip_1: "12.4",
			Sr_1: "0.63",
			e_1: "0.85",
			R_1: "",
			filter_1: "",
			particles_1: "",
			Угол_внутреннего_трения_1: "",
			влажность_Wl_1: "",
			влажность_Wp_1: "",
			плотность_p_1: "",
			плотность_pd_1: "",
			плотность_ps_1: "2.69",
			природная_влажность_W_1: "",
			сцепление_C_1: "",
			E_2: "14.1",
			Ee_2: "",
			Esr_2: "4.7",
			Il_2: "",
			Ip_2: "",
			Sr_2: "",
			e_2: "",
			R_2: "",
			filter_2: "",
			particles_2: "",
			Угол_внутреннего_трения_2: "17",
			влажность_Wl_2: "",
			влажность_Wp_2: "",
			плотность_p_2: "1.73",
			плотность_pd_2: "",
			плотность_ps_2: "",
			природная_влажность_W_2: "",
			сцепление_C_2: "13",
			E_3: "",
			Ee_3: "",
			Esr_3: "",
			Il_3: "",
			Ip_3: "",
			Sr_3: "",
			e_3: "",
			R_3: "",
			filter_3: "",
			particles_3: "",
			Угол_внутреннего_трения_3: "",
			влажность_Wl_3: "",
			влажность_Wp_3: "",
			плотность_p_3: "",
			плотность_pd_3: "",
			плотность_ps_3: "",
			природная_влажность_W_3: "",
			сцепление_C_3: "",
		},
		{
			глубина_залегания: 2.4,
			подтип_грунта: "",
			тип_грунта: "суглинок",
			E_1: "",
			Ee_1: "",
			Esr_1: "",
			Il_1: "0.60",
			Ip_1: "14.1",
			Sr_1: "0.87",
			e_1: "0.93",
			R_1: "",
			filter_1: "",
			particles_1: "",
			Угол_внутреннего_трения_1: "",
			влажность_Wl_1: "",
			влажность_Wp_1: "",
			плотность_p_1: "",
			плотность_pd_1: "",
			плотность_ps_1: "2.69",
			природная_влажность_W_1: "",
			сцепление_C_1: "",
			E_2: "",
			Ee_2: "",
			Esr_2: "5.3",
			Il_2: "",
			Ip_2: "",
			Sr_2: "",
			e_2: "",
			R_2: "",
			filter_2: "",
			particles_2: "",
			Угол_внутреннего_трения_2: "17",
			влажность_Wl_2: "",
			влажность_Wp_2: "",
			плотность_p_2: "1.80",
			плотность_pd_2: "",
			плотность_ps_2: "",
			природная_влажность_W_2: "",
			сцепление_C_2: "15",
			E_3: "",
			Ee_3: "",
			Esr_3: "",
			Il_3: "",
			Ip_3: "",
			Sr_3: "",
			e_3: "",
			R_3: "",
			filter_3: "",
			particles_3: "",
			Угол_внутреннего_трения_3: "",
			влажность_Wl_3: "",
			влажность_Wp_3: "",
			плотность_p_3: "",
			плотность_pd_3: "",
			плотность_ps_3: "",
			природная_влажность_W_3: "",
			сцепление_C_3: "",
		},
		{
			глубина_залегания: 3.8,
			подтип_грунта: "",
			тип_грунта: "суглинок",
			E_1: "",
			Ee_1: "",
			Esr_1: "",
			Il_1: "0.15",
			Ip_1: "15.1",
			Sr_1: "0.88",
			e_1: "0.75",
			R_1: "",
			filter_1: "",
			particles_1: "",
			Угол_внутреннего_трения_1: "",
			влажность_Wl_1: "",
			влажность_Wp_1: "",
			плотность_p_1: "",
			плотность_pd_1: "",
			плотность_ps_1: "2.69",
			природная_влажность_W_1: "",
			сцепление_C_1: "",
			E_2: "",
			Ee_2: "",
			Esr_2: "11",
			Il_2: "",
			Ip_2: "",
			Sr_2: "",
			e_2: "",
			R_2: "",
			filter_2: "",
			particles_2: "",
			Угол_внутреннего_трения_2: "21",
			влажность_Wl_2: "",
			влажность_Wp_2: "",
			плотность_p_2: "1.91",
			плотность_pd_2: "",
			плотность_ps_2: "",
			природная_влажность_W_2: "",
			сцепление_C_2: "23",
			E_3: "",
			Ee_3: "",
			Esr_3: "",
			Il_3: "",
			Ip_3: "",
			Sr_3: "",
			e_3: "",
			R_3: "",
			filter_3: "",
			particles_3: "",
			Угол_внутреннего_трения_3: "",
			влажность_Wl_3: "",
			влажность_Wp_3: "",
			плотность_p_3: "",
			плотность_pd_3: "",
			плотность_ps_3: "",
			природная_влажность_W_3: "",
			сцепление_C_3: "",
		},
		{
			глубина_залегания: 4.2,
			подтип_грунта: "",
			тип_грунта: "суглинок",
			E_1: "",
			Ee_1: "",
			Esr_1: "",
			Il_1: "0.07",
			Ip_1: "17.9",
			Sr_1: "0.88",
			e_1: "0.79",
			R_1: "",
			filter_1: "",
			particles_1: "",
			Угол_внутреннего_трения_1: "",
			влажность_Wl_1: "",
			влажность_Wp_1: "",
			плотность_p_1: "",
			плотность_pd_1: "",
			плотность_ps_1: "2.70",
			природная_влажность_W_1: "",
			сцепление_C_1: "",
			E_2: "",
			Ee_2: "",
			Esr_2: "13.3",
			Il_2: "",
			Ip_2: "",
			Sr_2: "",
			e_2: "",
			R_2: "",
			filter_2: "",
			particles_2: "",
			Угол_внутреннего_трения_2: "16",
			влажность_Wl_2: "",
			влажность_Wp_2: "",
			плотность_p_2: "1.89",
			плотность_pd_2: "",
			плотность_ps_2: "",
			природная_влажность_W_2: "",
			сцепление_C_2: "33",
			E_3: "",
			Ee_3: "",
			Esr_3: "",
			Il_3: "",
			Ip_3: "",
			Sr_3: "",
			e_3: "",
			R_3: "",
			filter_3: "",
			particles_3: "",
			Угол_внутреннего_трения_3: "",
			влажность_Wl_3: "",
			влажность_Wp_3: "",
			плотность_p_3: "",
			плотность_pd_3: "",
			плотность_ps_3: "",
			природная_влажность_W_3: "",
			сцепление_C_3: "",
		},
	],

	нагрузки: "загружения",
	тип_подколонника: "круглый",
	вручную_значение: "",
	привязка_к_абсолютным_отметкам: false,
	глубина_заложения: "1.3",
	высота_фундамента: "1.5",
	отметка_поверхности: "",
	вручную_сторона: "l",
	отметка_верха: "",
	отметка_подошвы: "",
	класс_бетона: "B15",
	город: 274,
	форма_подошвы: "прямоугольная",
	отапливаемость_здания: true,
	рассчет_LB: "соотношение",
	соотношение_значение: "0.75",
	тип_здания: "тип_5",
	тип_колонны: "металлическая",
	глубина_слоя: 0.3,
	lc: 0.6,
	bc: 0.4,
	конструктивная_схема: "жесткая",
	устройство_пола: "на_лагах",
	kh_temp: 18,
	фундамент_под: "наружную_стену",
	наличие_подвала: "false",
	YII_: 1.82,
	геотехническая_категория: 1,
	Su: 15,
	крен: false,
	класс_арматуры: "A500",
	initDataHTMLReport: true,
	solveDHTMLReport: true,
	solveAHTMLReport: true,
	solveRHTMLReport: true,
	solveEHtmlReport: true,
	solvePHtmlReport: true,
	EPRCheckHTMLReport: true,
	solveSHTMLReport: true,
	solveStepsHTMLReport: true,
	solvePressingHTMLReport: true,
	solvePlateReinforcementHTMLReport: true,
	solveCheckMult: true,
	plateCrackingHTMLReport: true,
	solveUnderColumnReinforcement: true,
	shortSolvedParams: true,
	krenHTMLReport: false,
	anchorBoltHTMLReport: true,
	titlePageHTMLReport: true,
	contentTitles: true,
	планируемость_территории: false,
	dw: 4.5,
	Yc_h: 5,
	Yc_l: 36,
	k: 0.4,
	la: 0.5,
	La: 0.5,
	LBc: 0.5,
	c: 0.15,
	alpha: 3.15,
	база_колонны: "высокая",
	errors: {},
	ba: 0.45,
	c_two: 0.5,
	c_: 0.15,
	hf: 1.5,
	d: 1.4,
	l: 2.8,
	тип_расчета: "solve",
	size: true,
	anchor: true,
	reinforcement: true,
	тип_металлической_колонны: "одноветвевая",
	нагрузка_на_колонну: "статическая",
	марка_стали: "Ст3пс2",
	конструкция_болта: "с отгибом",
	n: 3,
	h: 0.2,
	b: 2.8,
	yg: "1.3",
	dc: 0.7,
	lp: 0.8,
	bp: 0.65,
	db: "12",
	H0: 25,
	hl: {
		"1": 0.3,
		"2": 0.3,
	},
	xl: {
		"1": 0.6,
		"2": 1,
	},
	hb: {
		"1": 0.3,
	},
	xb: {
		"1": 0.6,
	},
	nb: 1,
	nl: 2,
	ad: 5,
	rein_step: 200,
	диаметр_арматуры: 0.014,
	D: 0.5,
	hn: 0.1,
	iu: "",
	наличие_подготовки: true,
	ds_undercolumn: 0.014,
	kernels: 4,
};

export default state;