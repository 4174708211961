import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import './index.css';
import CustomNumericInput from "../../CustomNumericInput/CustomNumericInput";
import ConstructProperties from "../../ConstructProperties/index.jsx";
import {Popover, Whisper} from "rsuite";
import Collapsible from "react-collapsible";
import Select from 'react-select';
import {lang, popover} from "../../../Modules/Global";

import anchor1 from "../../../img/anchor1.png";
import anchor2 from "../../../img/anchor2.png";
import anchor5 from "../../../img/anchor3.png";
import anchor4 from "../../../img/anchor4.png";
import anchor3 from "../../../img/anchor5.png";

import highTraversaSvgL from '.././../../img/highTraversaSvgL.svg';
import highTraversaSvgB from '.././../../img/highTraversaSvgB.svg';
import lowTraversa from '.././../../img/lowTraversa.svg';
import twoBranches from '.././../../img/twoBranches.svg';
import {isEmpty} from "../../Global/Global";
import _ from "lodash";

import {stylesReactSelect} from "../../Helpers/style";
import ColumnTypeMetal from "../../ColumnTypeMetal/ColumnTypeMetal";
import ReactHtmlParser from "react-html-parser";

const styleMultilineLabel = {width: '65%', lineHeight: '16px', alignSelf: 'center', marginBottom: 0},
    styleMultiLineInput = {width: '35%'};

class PillarFoundationCheck extends Component {

    constructor(props) {
        super(props);

        this.app = this.props.app;

        // Список форм подошв
        this.optionsSoleShapes = [
            {id: 'прямоугольная', title: lang('Прямоугольная')},
            {id: 'круглая', title: lang('Круглая')},
            {id: 'квадратная', title: lang('Квадратная')}
        ];

        // Список типов металлической колонны
        this.optionsMetalColumnTypes = [
            { id: 'двухветвевая', title: lang('Двухветвевая') },
            { id: 'одноветвевая', title: lang('Одноветвевая') }
        ];

        // Список типов баз колонны
        this.optionsBaseTypes = [
            { id: 'высокая', title: lang('С высокой траверсой') },
            { id: 'низкая', title: lang('С низкой траверсой') }
        ];

        // Список типов нагрузок
        this.optionsLoads = [
            { id: 'статическая', title: lang('Статическая') },
            { id: 'динамическая', title: lang('Динамическая') }
        ];

        // Конструктивные особенности фундамента
        this.optionsFoundationDesignFeatures = [
            { id: 'наружную_стену', title: lang('Фундамент под наружную стену') },
            { id: 'внутреннюю_стену', title: lang('Фундамент под внутреннюю стену') },
        ];

        // Список типов конструкции болта
        this.optionsBoltTypes = [
            { id: 'конический', title: lang('конический'), img: anchor1 },
            { id: 'прямой', title: lang('прямой'), img: anchor2 },
            { id: 'с отгибом', title: lang('с отгибом'), img: anchor3 },
            { id: 'с анкерной плитой съемный', title: lang('с анкерной плитой съемный'), img: anchor4 },
            { id: 'с анкерной плитой глухой', title: lang('с анкерной плитой глухой'), img: anchor5 }
        ];

        this.state = {
            collapseState: {
                dimensions: false,
                diameter: false,
                reinforcement: false
            },

            innerCollapseState: {
                foundation: false,
                building: false
            },

            isDimensions: this.app.state.size,

            isDiameter: this.app.state.anchor,

            isReinforcement: this.app.state.reinforcement,

            su: null,

            // Наличие бетонной подготовки
            preparationFoundation: this.app.state['наличие_подготовки'],

            selectedSoleShape: _.find(this.optionsSoleShapes, {
                id: this.app.state['форма_подошвы']
            }),

            selectedMetalColumnType: _.find(this.optionsMetalColumnTypes, {
                id: this.app.state['тип_металлической_колонны']
            }),

            selectedBaseType: _.find(this.optionsBaseTypes, {
                id: this.app.state['база_колонны']
            }),

            selectedLoad: _.find(this.optionsLoads, {
                id: this.app.state['нагрузка_на_колонну']
            }),

            selectedFoundationDesignFeature: _.find(this.optionsFoundationDesignFeatures, {
                id: this.app.state['фундамент_под']
            }),

            selectedBoltType: _.find(this.optionsBoltTypes, {
                id: this.app.state['конструкция_болта']
            }),

            selectedOption: {
                Su: 10,
                isDisabled: 0,
                kren: "",
                label: "то же. с устройством железобетонных поясов или монолитных перекрытий. а также здания монолитной конструкции",
                value: 3
            },

            tabIndex: 0,

            tabIndex2: 0,
        };

        this.wrapper = this.props.wrapper;
        this.options = this.app.optionsSu;

		this.svgTwoBranchesHandler = this.wrapper.svgTwoBranchesHandler;
		this.svgOneBranchHandler = this.wrapper.svgOneBranchHandler;
		this.paramsHandler = this.wrapper.paramsHandler;
		this.toggleButtonsGroupHandler = this.wrapper.toggleButtonsGroupHandler;
    }

    customSelectHandler = (e) => {
        this.app.setState({ 'Su':e.Su });
        this.setState({selectedOption:e});
        if(!isEmpty(e.kren)){
            this.app.setState({
                'крен':true,
                iu:e.kren,
            })
        }else{
            this.app.setState({
                'крен':false,
                iu:'',
            })
        }
    };

    renderAnchorBoltsOptions = () =>{
        let options = [];
        let bolt = this.app.bolts.find(b => b.t === this.app.state['конструкция_болта']);
        let diams = String(Object(bolt).d).split(',');

        diams.forEach((item,i) =>{
            options.push(
                <option key={i} value={item}>{item}</option>
            )
        });
        return options;
    };

    customNumericInputHandler = (value, name , validateField) =>{
        value = parseFloat(value);

        if(name === 'nb' || name === 'nl'){
            if(value > 5) value = 5;
            if(isEmpty(value)) value = 0;
        }

        if(name.includes('xl') || name.includes('hl') || name.includes('hb') || name.includes('xb')){
            let nameArray = name.split('-');
            let index = nameArray[1];
            let param = nameArray[0];
            let stateArray = this.app.state[param];
            stateArray[index] = value;


            let errors = this.app.state.errors;
            if(!validateField.isValid) {
                errors[name] = {
                    textError: validateField.textError,
                    tab: '3'
                };

            }else{
                delete  errors[name];
            }

            this.app.setState({
                [param]:stateArray,
                errors:errors
            });

            return false;
        }

        let errors = this.app.state.errors;
        if(!validateField.isValid) {
            errors[name] = {
                textError: validateField.textError,
                tab: '3'
            };

        }else{
            delete  errors[name];
        }

        this.app.setState({
            [name]: value,
            errors:errors
        });
    };

    switchDimensions() {
        this.setState({isDimensions : !this.state.isDimensions}, () => {
            this.customCheckboxHandler('size', this.state.isDimensions);
            if (!this.state.isDimensions) this.hideDisabledCollapsible('dimensions');
        })
    }

    switchDiameter() {
        this.setState({isDiameter : !this.state.isDiameter}, () => {
            this.customCheckboxHandler('anchor', this.state.isDiameter);
            if (!this.state.isDiameter) this.hideDisabledCollapsible('diameter');
        })
    }

    switchReinforcement() {
        this.setState({isReinforcement : !this.state.isReinforcement}, () => {
            this.customCheckboxHandler('reinforcement', this.state.isReinforcement);
            if (!this.state.isReinforcement) this.hideDisabledCollapsible('reinforcement');
        })
    }

    customCheckboxHandler = (name,checked) =>{
        this.app.setState({[name]:checked});

        if(name === 'anchor'){
            if(checked){
                this.app.setState({
                    'тип_колонны':'металлическая',
                    anchorBoltHTMLReport:true,
                })
            }else{
                this.app.setState({
                    'тип_колонны':'монолитная',
                    anchorBoltHTMLReport:false,
                })
            }
        }

        let ch = [];
        let tabIndex = null;
        if(this.app.state['anchor']) ch.push(1);
        if(this.app.state['reinforcement']) ch.push(2);
        if(this.app.state['size']) ch.push(0);

        switch (name) {
            case 'anchor': tabIndex = 1;
                break;
            case 'reinforcement': tabIndex = 2;
                break;
            case 'size': tabIndex = 0;
                break;

        }

        if(!checked && this.state.tabIndex === tabIndex) {
            let idArr = Array(ch.filter(item => item !== tabIndex))[0];
            let id = idArr.length > 0 ? idArr[0]:123;
            this.setState({tabIndex:id});
        }
        if(checked && this.state.tabIndex === 123){
            this.setState({tabIndex:tabIndex});
        }

    };

    renderStepsFields = (side) => {
        let fields = [];

        for(let i = 1; i < this.app.state['n' + side] + 1 && i <= 5;i++){
            fields.push(
                <div className="form-group" key={i}>
                    <div className="row">
                        <div className="col-6" style={{paddingRight: "2px"}}>
                            <CustomNumericInput
                                name={'h'+ side + '-'+i}
                                className="form-control input-text"
                                value={this.app.state['h'+ side ][i]}
                                allowNegative={false}
                                measure={'[м]'}
                                label={"Высота " + i +"-й ступени, (м):"}
                                min={0.1}
                                presicion={2}
                                max={7}
                                step={0.1}
                                onValidate={this.validateField}
                                enabledHandlerOnInput={true}
                                isValid={!this.app.state.errors.hasOwnProperty('h' + side +'-'+i)}
                                onChange={this.customNumericInputHandler}
                            />
                        </div>
                        <div className="col-6" style={{paddingLeft: "2px"}}>
                            <CustomNumericInput
                                name={'x'+ side + '-'+i}
                                className="form-control input-text"
                                value={this.app.state['x'+ side ][i]}
                                allowNegative={false}
                                measure={'[м]'}
                                label={"Длина " + i + "-й ступени, (м):"}
                                min={0.1}
                                presicion={2}
                                max={7}
                                step={0.1}
                                onValidate={this.validateField}
                                enabledHandlerOnInput={true}
                                isValid={!this.app.state.errors.hasOwnProperty('x' + side +'-'+i)}
                                onChange={this.customNumericInputHandler}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return fields;
    };

    popover = () =>{
        return <Popover width={'auto'}>
            <div className="imgS" >
                <object id="svg-object"  data={twoBranches} type="image/svg+xml" />
                <object id="lowTraversaSvg" data={lowTraversa} type="image/svg+xml" />
                <object id="highTraversaSvgL" data={highTraversaSvgL} type="image/svg+xml" />
                <object id="highTraversaSvgB" data={highTraversaSvgB} type="image/svg+xml" />
            </div>
        </Popover>
    };

    validateField = (props) => {
        const value = props.value;
        const name = props.name;
        let isValid = true,
            textError = 'Заполните поле';

        switch (name) {
            case 'hn':
                if(this.app.state['наличие_подготовки']){
                    if(isEmpty(value)) {
                        isValid = false;
                        textError = 'Заполните поле';
                    }

                    if(value < 0.1 || value > 1){
                        isValid = false;
                        textError = 'hn должно быть не более 1 м и не менее 0.1 м';
                    }
                }
                break;
            case 'Su':
                if (value < 0) {
                    isValid = false;
                    textError = 'Осадка не может быть отрицательной';
                }
                if(isEmpty(value)){
                    isValid = false;
                    textError = "Заполните поле";
                }
                break;
            case 'la':
            case 'ba':
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if (value < 0.05 || isEmpty(value) || value > 0.8) {
                            isValid = false;
                            textError = 'Расстояние от оси колонны до оси болта не менее 0.05 м и не более 0.8 м';
                        }
                    }
                }
                break;
            case 'kh_temp':
                if(value < 0){
                    isValid = false;
                    textError = 'Температура не может быть отрицательной, введите корректные данные';
                }
                if(value > 45){
                    isValid = false;
                    textError = 'Температура должна быть не более 45';
                }
                if(isEmpty(value)) isValid = false;
                break;
            case 'bp':
                if(value < 0.2 || value > 1){
                    isValid = false;
                    textError = 'Введены некорректные размеры анкерной плиты, уточните размеры';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(value <= this.app.state.c_*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                if(isEmpty(value)) isValid = false;
                break;
            case 'lp':
                if(value < 0.2 || value > 1){
                    isValid = false;
                    textError = 'Введены некорректные размеры анкерной плиты, уточните размеры';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(value <= this.app.state.c*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                if(isEmpty(value)) isValid = false;
                break;
            case 'lc':
                if(this.app.state['тип_колонны'] !== 'металлическая'){
                    if(this.app.state['форма_подошвы'] !== 'круглая' && this.app.state['тип_колонны'] === 'монолитная' ){
                        if(value < 0.3 || value > 1.2){
                            isValid = false;
                            textError = 'Длина подколонника должна быть в пределах от 0.3 м до 1.2 м';
                        }
                    }
                }

                break;
            case 'bc':
                if(this.app.state['тип_колонны'] !== 'металлическая'){
                    if(this.app.state['форма_подошвы'] !== 'круглая' && this.app.state['тип_колонны'] === 'монолитная' ){
                        if(value < 0.3 || value > 1.2){
                            isValid = false;
                            textError = 'Длина подколонника должна быть в пределах от 0.3 м до 1.2 м';
                        }
                    }
                }

                break;
            case 'Yc_l':
                if(value > 120){
                    isValid = false;
                    textError = 'L не более 120 м';
                }
                if(value < 1){
                    isValid = false;
                    textError = 'L не менее 1 м';
                }
                if(isEmpty(value)){
                    isValid = false;
                    textError = 'Заполните поле';
                }
                break;
            case 'Yc_h':
                if(value > 60){
                    isValid = false;
                    textError = 'H не более 60 м';
                }
                if(value < 1){
                    isValid = false;
                    textError = 'H не менее 1 м';
                }
                if(isEmpty(value)){
                    isValid = false;
                    textError = 'Заполните поле';
                }
                break;
            case 'h':
                if(value < 0.2 || value > 3 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние в осях между ветвями колонны должно быть не менее 0.2 м и не более 3 м';
                }
                break;
            case 'k':
                if(value < 0.15 || value > 1.1 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до противоположной грани болта должна быть в пределах от 0.15 м до 1.1 м';
                }
                break;
            case 'La':
                if(value < 0.2 || value > 1 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до противоположной грани пластины должно быть в пределах от 0.2 м до 1 м';
                }
                break;
            case 'c':
                if(value < 0.1 || value > 0.6 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до оси колонны должно быть в пределах от 0.1 м до 0.6 м';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(this.app.state.lp <= value*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                break;
            case 'c_':
                if(value < 0.02 || value > 0.6 || isEmpty(value)){
                    isValid = false;
                    textError = 'Расстояние от оси болта до оси колонны должно быть в пределах от 0.02 м до 0.6 м';
                }
                if(this.app.state['тип_колонны'] === 'металлическая'){
                    if(this.app.state['тип_металлической_колонны'] === 'одноветвевая'){
                        if(this.app.state['база_колонны'] === 'низкая'){
                            if(this.app.state.bp <= value*2){
                                isValid = false;
                                textError = 'Анкерный болт находится за границей плиты,увеличьте анкерную плиту'
                            }
                        }
                    }
                }
                break;
            case 'LBc':
                if(this.app.state['форма_подошвы'] === 'круглая') {
                    if (this.app.state['тип_подколонника'] === 'круглый') {
                        if (value < 0.3 || value > 1.2 || isEmpty(value)) {
                            isValid = false;
                            textError = 'Диаметр подколонника  должна быть в пределах от 0.3 м до 1.2 м';
                        }
                    }
                }
                break;
            case 'l':
                if(this.app.state['форма_подошвы'] === 'прямоугольная') {
                    if (value < 0 || value > 12 || isEmpty(value)) {
                        isValid = false;
                        textError = 'Длина подколонника  должна быть в пределах от 0 м до 12 м';
                    }
                }
                break;
            case 'b':
                if(this.app.state['форма_подошвы'] === 'прямоугольная') {
                    if (value < 0 || value > 12 || isEmpty(value)) {
                        isValid = false;
                        textError = 'Ширина подколонника  должна быть в пределах от 0 м до 12 м';
                    }
                }
                break;
            case 'D':
                if(this.app.state['форма_подошвы'] === 'прямоугольная') {
                    if (value < 0 || value > 12 || isEmpty(value)) {
                        isValid = false;
                        textError = 'Ширина подколонника  должна быть в пределах от 0 м до 12 м';
                    }
                }
                break;
            case 'dc':
                if(this.app.state['тип_колонны'] === 'сборная'){
                    if(value > 2){
                        isValid = false;
                        textError = 'Глубина заделки должна быть меньше из условия соотношения сечения колонны к глубине ее заделки';
                    }
                    if(Math.max(this.app.state.lc,this.app.state.bc) > value){
                        isValid = false;
                        textError = 'Условие заделки колонны в стакан не обеспеченно убедитесь в правильности введенных данных';
                    }
                    if(!isEmpty(this.app.state['высота_фундамента'])){
                        if(value > this.app.state['высота_фундамента'] - 0.2){
                            isValid = false;
                            textError = 'Высота фундамента меньше глубины заделки колонны в стакан';
                        }
                    }

                }
                break;
            case 'kernels':
                if(this.app.state['reinforcement']) {
                    if (value < 4 || value > 26 || isEmpty(value)) {
                        isValid = false;
                        textError = 'Количество стержней в подколонной части должно быть в пределах от 4 до 26';
                    }
                }
                break;
            case 'ad':
                if(value < 4 || value > 7){
                    isValid = false;
                    textError = 'Величина защитного слоя должна быть не меньше 4см  и не больше 7см';
                }
                break;
            case 'глубина_заложения':
                if(value < 0){
                    isValid = false;
                    textError = 'Не может быть отрицательной';
                }
                if(value < 0.5 || value > 4.5){
                    isValid = false;
                    textError = 'Глубина заложения должна быть не мене 0.5 м и не более 4.5 м';
                }
                if(!this.app.state['планируемость_территории']){
                    if(value < this.app.state['глубина_слоя'] && !isEmpty(value)){
                        isValid = false;
                        textError = 'Подошва фундамента залегает на насыпном грунте,увеличьте глубину заложения';
                    }
                }
                if (isEmpty(value)) isValid = true;
                break;
            case 'высота_фундамента':
                if(value < 0){
                    isValid = false;
                    textError = 'Не может быть отрицательной';
                }
                if(value < 0.6 || value > 5){
                    isValid = false;
                    textError = 'Высота фундамента должна быть не мене 0.6 м и не более 5 м';
                }
                if (isEmpty(value)) isValid = true;
                break;
            case 'nl':
            case 'nb':
                if(this.app.state['тип_расчета'] === 'check'){
                    if(this.app.state.nl + this.app.state.nb === 0){
                        isValid = false;
                        textError = 'Подбор арматуры подошвы не будет проведен ввиду отсутствия заданного количества ступеней';
                    }else{
                        let errors = this.app.state.errors;
                        delete errors.nl;
                        delete errors.nb;
                        this.app.setState({errors:errors});
                    }

                }
                break;
        }

        if(name.includes('xl') || name.includes('xb')){
            if(value < 0.1 || value >7 || isEmpty(value) || isNaN(value)){
                isValid = false;
                textError = 'Длина ступени должна быть не меньше 0.1м и не больше 7м';
            }
        }
        if(name.includes('hl') || name.includes('hb')){
            if(value < 0.2 || value > 0.45 || isEmpty(value) || isNaN(value)){
                isValid = false;
                textError = 'Высота ступени должна быть не меньше 0.2 и не больше 0.45';
            }
        }

        if(value < 0){
            isValid = false;
            textError = 'Значение не может быть отрицательным';
        }
        return {
            isValid: isValid,
            textError: (isValid) ? '' : textError
        }
    };

    handleTriggerClickCollapsible = (name) => {
        let collapseState = this.state.collapseState;

        Object.entries(collapseState).forEach(([key, value]) => {
            if (key !== name) collapseState[key] = false;
        });

        collapseState[name] = !collapseState[name];

        this.setState({collapseState})
    };

    handleTriggerClickInnerCollapsible = (name) => {
        let innerCollapseState = this.state.innerCollapseState;

        Object.entries(innerCollapseState).forEach(([key, value]) => {
            if (key !== name) innerCollapseState[key] = false;
        });

        innerCollapseState[name] = !innerCollapseState[name];

        this.setState({innerCollapseState})
    };

    hideDisabledCollapsible = (name) => {
        let collapseState = this.state.collapseState;
        collapseState[name] = false;
        this.setState({collapseState})
    };

    render() {
    let boltOptions = this.renderAnchorBoltsOptions();
    return (
        <div className='container-fluid'>
            <div className="switch-wrapper" style={{marginLeft: -10}}>
                <label className="switch">
                    <input type="checkbox" checked={this.state.isDimensions}
                           onChange={this.switchDimensions.bind(this)}/>
                    <span className="slider"></span>
                </label>
                <span className="switch-label">{lang('Габариты фундамента')}</span>
                <div className="clearfix"></div>
            </div>

            <div className="switch-wrapper" style={{marginLeft: -10}}>
                <label className="switch">
                    <input type="checkbox" checked={this.state.isDiameter}
                           onChange={this.switchDiameter.bind(this)}/>
                    <span className="slider"></span>
                </label>
                <span className="switch-label">{lang('Диаметр анкера')}</span>
                <div className="clearfix"></div>
            </div>

            <div className="switch-wrapper" style={{marginLeft: -10}}>
                <label className="switch">
                    <input type="checkbox" checked={this.state.isReinforcement}
                           onChange={this.switchReinforcement.bind(this)}/>
                    <span className="slider"></span>
                </label>
                <span className="switch-label">{lang('Армирование')}</span>
                <div className="clearfix"></div>
            </div>

			<br/>

            <div className="row">
                <div className="Collapsible-wrapper" style={{marginLeft: -15, marginRight: -15}}>
                    {this.app.state.size &&
                    <Collapsible accordionPosition={'dimensions'}
                                 handleTriggerClick={this.handleTriggerClickCollapsible}
                                 triggerDisabled={!this.app.state.size}
                                 trigger={'Габариты фундамента'}
                                 overflowWhenOpen={'visible'}
                                 open={this.state.collapseState.dimensions}>
                        <div className="Collapsible-content">
                            <div className="Collapsible-panel">
                                <div>
                                    <div className="row no-gutters">
                                        <div className="w-100">
                                            <div className="Collapsible-wrapper"
												 style={{
												 	marginLeft: -15,
													marginRight: -15
												 }}>
                                                <Collapsible accordionPosition={'foundation'}
                                                             handleTriggerClick={this.handleTriggerClickInnerCollapsible}
                                                             trigger={ReactHtmlParser('<span style="opacity: 0;">2. </span> Параметры фундамента')}
                                                             overflowWhenOpen={'visible'}
                                                             open={this.state.innerCollapseState.foundation}>
                                                    <div className="Collapsible-content">
                                                        <div className="Collapsible-panel">
                                                            <div>
                                                                <div className="row no-gutters">
                                                                    <div className="w-100">
                                                                        <div className="form-group">
                                                                            <label htmlFor="">{lang('Форма подошвы') + ':'}</label>
                                                                            <div className="Select-control">
                                                                                <Select
                                                                                    menuPortalTarget={document.body}
                                                                                    styles={stylesReactSelect}
                                                                                    value={this.state.selectedSoleShape}
                                                                                    onChange={selected => {
                                                                                        this.setState({
                                                                                            selectedSoleShape: selected
                                                                                        }, () => this.app.setState({'форма_подошвы': selected.id}));
                                                                                    }}
                                                                                    options={this.optionsSoleShapes}
                                                                                    getOptionLabel ={option => option.title}
                                                                                    getOptionValue ={option => option.id}
                                                                                    placeholder={lang('Выберите форму подошвы')}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <CustomNumericInput
                                                                                name={'высота_фундамента'}
                                                                                className="form-control input-text"
                                                                                value={this.app.state['высота_фундамента']}
                                                                                allowNegative={false}
                                                                                measure={'[м]'}
                                                                                labelLeft
                                                                                widthLabel={'65%'}
                                                                                widthInput={'35%'}
                                                                                label={"Высота h<sub>ф</sub>, (м):"}
                                                                                min={0}
                                                                                step={0.1}
                                                                                onValidate={this.validateField}
                                                                                enabledHandlerOnInput={true}
                                                                                isValid={!this.app.state.errors.hasOwnProperty('высота_фундамента')}
                                                                                onChange={this.customNumericInputHandler}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <CustomNumericInput
                                                                                name={'глубина_заложения'}
                                                                                className="form-control input-text"
                                                                                value={this.app.state['глубина_заложения']}
                                                                                allowNegative={false}
                                                                                measure={'[м]'}
                                                                                labelLeft
                                                                                widthLabel={'65%'}
                                                                                widthInput={'35%'}
                                                                                label={"Глубина заложения d, (м):"}
                                                                                min={0}
                                                                                step={0.1}
                                                                                onValidate={this.validateField}
                                                                                enabledHandlerOnInput={true}
                                                                                isValid={!this.app.state.errors.hasOwnProperty('глубина_заложения')}
                                                                                onChange={this.customNumericInputHandler}
                                                                            />
                                                                        </div>
                                                                        {this.app.state['форма_подошвы'] === 'прямоугольная' &&
                                                                        <div className="form-group">
                                                                            <CustomNumericInput
                                                                                name={'l'}
                                                                                className="form-control input-text"
                                                                                value={this.app.state.l}
                                                                                allowNegative={false}
                                                                                measure={'[м]'}
                                                                                labelLeft
                                                                                widthLabel={'65%'}
                                                                                widthInput={'35%'}
                                                                                label={"Длина l, (м):"}
                                                                                min={0}
                                                                                step={0.1}
                                                                                onValidate={this.validateField}
                                                                                enabledHandlerOnInput={true}
                                                                                isValid={!this.app.state.errors.hasOwnProperty('l')}
                                                                                onChange={this.customNumericInputHandler}
                                                                            />
                                                                        </div>

                                                                        }
                                                                        {this.app.state['форма_подошвы'] === 'квадратная' &&
                                                                        <div className="form-group">
                                                                            <CustomNumericInput
                                                                                name={'l'}
                                                                                className="form-control input-text"
                                                                                value={this.app.state.l}
                                                                                allowNegative={false}
                                                                                measure={'[м]'}
                                                                                labelLeft
                                                                                widthLabel={'65%'}
                                                                                widthInput={'35%'}
                                                                                label={"Сторона фундамента а, (м):"}
                                                                                min={0}
                                                                                step={0.1}
                                                                                onValidate={this.validateField}
                                                                                enabledHandlerOnInput={true}
                                                                                isValid={!this.app.state.errors.hasOwnProperty('l')}
                                                                                onChange={this.customNumericInputHandler}
                                                                            />
                                                                        </div>
                                                                        }

                                                                        {this.app.state['форма_подошвы'] === 'прямоугольная' &&

                                                                        <div className="form-group">
                                                                            <CustomNumericInput
                                                                                name={'b'}
                                                                                className="form-control input-text"
                                                                                value={this.app.state.b}
                                                                                allowNegative={false}
                                                                                measure={'[м]'}
                                                                                labelLeft
                                                                                widthLabel={'65%'}
                                                                                widthInput={'35%'}
                                                                                label={"Ширина b, (м):"}
                                                                                min={0}
                                                                                step={0.1}
                                                                                onValidate={this.validateField}
                                                                                enabledHandlerOnInput={true}
                                                                                isValid={!this.app.state.errors.hasOwnProperty('b')}
                                                                                onChange={this.customNumericInputHandler}
                                                                            />
                                                                        </div>

                                                                        }
                                                                        {(this.app.state['форма_подошвы'] === 'круглая') &&
                                                                        <div className="form-group">
                                                                            <CustomNumericInput
                                                                                name={'D'}
                                                                                className="form-control input-text"
                                                                                value={this.app.state.D}
                                                                                allowNegative={false}
                                                                                measure={'[м]'}
                                                                                labelLeft
                                                                                widthLabel={'65%'}
                                                                                widthInput={'35%'}
                                                                                label={"Диаметр, (м):"}
                                                                                min={0}
                                                                                step={0.1}
                                                                                onValidate={this.validateField}
                                                                                enabledHandlerOnInput={true}
                                                                                isValid={!this.app.state.errors.hasOwnProperty('D')}
                                                                                onChange={this.customNumericInputHandler}
                                                                            />
                                                                        </div>
                                                                        }

                                                                        <div className="form-group" style={{marginTop: 5}}>
                                                                            <label htmlFor="">{lang('Конструктивные особенности фундамента') + ':'}</label>
                                                                            <div className="Select-control">
                                                                                <Select
                                                                                    styles={stylesReactSelect}
                                                                                    value={this.state.selectedFoundationDesignFeature}
                                                                                    onChange={selected => {
                                                                                        this.setState({
                                                                                            selectedFoundationDesignFeature: selected
                                                                                        }, () => this.app.setState({'фундамент_под': selected.id}));
                                                                                    }}
                                                                                    options={this.optionsFoundationDesignFeatures}
                                                                                    getOptionLabel ={option => option.title}
                                                                                    getOptionValue ={option => option.id}
                                                                                    placeholder={lang('Выберите тип фундамента')}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div className="switch-wrapper">
                                                                                <label className="switch">
                                                                                    <input type="checkbox"
                                                                                           name={'наличие_подготовки'}
                                                                                           defaultChecked={this.state.preparationFoundation}
                                                                                           onChange={() => {
                                                                                               let checked = !this.state.preparationFoundation;

                                                                                               this.setState({preparationFoundation: checked}, () => {
                                                                                                   this.app.setState({'наличие_подготовки': checked});
                                                                                               });
                                                                                           }}/>
                                                                                    <span className="slider"></span>
                                                                                </label>
                                                                                <span className="switch-label">{lang('Наличие бетонной подготовки')}</span>
                                                                                <div className="clearfix"></div>
                                                                            </div>

                                                                            {this.state.preparationFoundation &&
                                                                            <div className="form-group">
                                                                                <CustomNumericInput
                                                                                    name={'hn'}
                                                                                    className="form-control input-text"
                                                                                    value={this.app.state['hn']}
                                                                                    allowNegative={false}
                                                                                    measure={'[м]'}
                                                                                    precision={2}
                                                                                    min={0}
                                                                                    labelLeft={true}
                                                                                    widthLabel={'65%'}
                                                                                    widthInput={'35%'}
                                                                                    label={"Толщина подготовки h<sub>n</sub>, (м):"}
                                                                                    step={0.1}
                                                                                    onValidate={this.validateField}
                                                                                    enabledHandlerOnInput={true}
                                                                                    isValid={!this.app.state.errors.hasOwnProperty('hn')}
                                                                                    onChange={this.customNumericInputHandler}
                                                                                />
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapsible>

                                                <Collapsible accordionPosition={'building'}
                                                             handleTriggerClick={this.handleTriggerClickInnerCollapsible}
                                                             trigger={ReactHtmlParser('<span style="opacity: 0;">2. </span> Параметры здания')}
                                                             overflowWhenOpen={'visible'}
                                                             open={this.state.innerCollapseState.building}>
                                                    <div className="Collapsible-content">
                                                        <div className="Collapsible-panel">
                                                            <div style={{paddingBottom: 10}}>
                                                                <div className="row no-gutters">
                                                                    <div className="w-100">
                                                                        <ConstructProperties
																			parent={this}
																			app={this.app}
																		/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapsible>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapsible>
                    }
                    {this.app.state.anchor &&
                    <Collapsible accordionPosition={'diameter'}
                                 handleTriggerClick={this.handleTriggerClickCollapsible}
                                 triggerDisabled={!this.app.state.anchor}
                                 trigger={'Диаметр анкера'}
                                 overflowWhenOpen={'visible'}
                                 open={this.state.collapseState.diameter}>
                        <div className="Collapsible-content">
                            <div className="Collapsible-panel">
                                <div>
                                    <div className="row no-gutters">
										<ColumnTypeMetal
											app={this.app}
											parent={this}
											isCheck={true}
										/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapsible>
                    }
                    {this.app.state.reinforcement &&
                    <Collapsible accordionPosition={'reinforcement'}
                                 handleTriggerClick={this.handleTriggerClickCollapsible}
                                 triggerDisabled={!this.app.state.reinforcement}
                                 trigger={'Армирование'}
                                 overflowWhenOpen={'visible'}
                                 open={this.state.collapseState.reinforcement}>
                        <div className="Collapsible-content">
                            <div className="Collapsible-panel">
                                <div>
                                    <div className="row no-gutters">
                                        <div className="w-100">
                                            <p className={this.app.state.size ? 'alert alert-light' : 'hide'}>{lang('Данные взяты из вкладки "Габариты фундамента"')}</p>
                                            <div className={this.app.state.size ? 'disabled' : ''}>
                                                <div className="form-group">
                                                    <label htmlFor="">{lang('Форма подошвы') + ':'}</label>
                                                    <div className="Select-control">
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={stylesReactSelect}
                                                            value={this.state.selectedSoleShape}
                                                            onChange={selected => {
                                                                this.setState({
                                                                    selectedSoleShape: selected
                                                                }, () => this.app.setState({'форма_подошвы': selected.id}));
                                                            }}
                                                            options={this.optionsSoleShapes}
                                                            getOptionLabel={option => option.title}
                                                            getOptionValue={option => option.id}
                                                            placeholder={lang('Выберите форму подошвы')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <CustomNumericInput
                                                        name={'высота_фундамента'}
                                                        className="form-control input-text"
                                                        value={this.app.state['высота_фундамента']}
                                                        allowNegative={false}
                                                        measure={'[м]'}
                                                        labelLeft
                                                        widthLabel={'65%'}
                                                        widthInput={'35%'}
                                                        label={"Высота h<sub>ф</sub>, (м):"}
                                                        min={0}
                                                        step={0.1}
                                                        onValidate={this.validateField}
                                                        enabledHandlerOnInput={true}
                                                        isValid={!this.app.state.errors.hasOwnProperty('высота_фундамента')}
                                                        onChange={this.customNumericInputHandler}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <CustomNumericInput
                                                        name={'глубина_заложения'}
                                                        className="form-control input-text"
                                                        value={this.app.state['глубина_заложения']}
                                                        allowNegative={false}
                                                        measure={'[м]'}
                                                        labelLeft
                                                        widthLabel={'65%'}
                                                        widthInput={'35%'}
                                                        label={"Глубина заложения d, (м):"}
                                                        min={0}
                                                        step={0.1}
                                                        onValidate={this.validateField}
                                                        enabledHandlerOnInput={true}
                                                        isValid={!this.app.state.errors.hasOwnProperty('глубина_заложения')}
                                                        onChange={this.customNumericInputHandler}
                                                    />
                                                </div>
                                                {this.app.state['форма_подошвы'] === 'прямоугольная' &&
                                                <div className="form-group">
                                                    <CustomNumericInput
                                                        name={'l'}
                                                        className="form-control input-text"
                                                        value={this.app.state.l}
                                                        allowNegative={false}
                                                        measure={'[м]'}
                                                        labelLeft
                                                        widthLabel={'65%'}
                                                        widthInput={'35%'}
                                                        label={"Длина l, (м):"}
                                                        min={0}
                                                        step={0.1}
                                                        onValidate={this.validateField}
                                                        enabledHandlerOnInput={true}
                                                        isValid={!this.app.state.errors.hasOwnProperty('l')}
                                                        onChange={this.customNumericInputHandler}
                                                    />
                                                </div>

                                                }
                                                {this.app.state['форма_подошвы'] === 'квадратная' &&
                                                <div className="form-group">
                                                    <CustomNumericInput
                                                        name={'l'}
                                                        className="form-control input-text"
                                                        value={this.app.state.l}
                                                        allowNegative={false}
                                                        measure={'[м]'}
                                                        labelLeft
                                                        widthLabel={'65%'}
                                                        widthInput={'35%'}
                                                        label={"Сторона фундамента а, (м):"}
                                                        min={0}
                                                        step={0.1}
                                                        onValidate={this.validateField}
                                                        enabledHandlerOnInput={true}
                                                        isValid={!this.app.state.errors.hasOwnProperty('l')}
                                                        onChange={this.customNumericInputHandler}
                                                    />
                                                </div>
                                                }

                                                {this.app.state['форма_подошвы'] === 'прямоугольная' &&

                                                <div className="form-group">
                                                    <CustomNumericInput
                                                        name={'b'}
                                                        className="form-control input-text"
                                                        value={this.app.state.b}
                                                        allowNegative={false}
                                                        measure={'[м]'}
                                                        labelLeft
                                                        widthLabel={'65%'}
                                                        widthInput={'35%'}
                                                        label={"Ширина b, (м):"}
                                                        min={0}
                                                        step={0.1}
                                                        onValidate={this.validateField}
                                                        enabledHandlerOnInput={true}
                                                        isValid={!this.app.state.errors.hasOwnProperty('b')}
                                                        onChange={this.customNumericInputHandler}
                                                    />
                                                </div>

                                                }
                                                {(this.app.state['форма_подошвы'] === 'круглая') &&
                                                <div className="form-group">
                                                    <CustomNumericInput
                                                        name={'D'}
                                                        className="form-control input-text"
                                                        value={this.app.state.D}
                                                        allowNegative={false}
                                                        measure={'[м]'}
                                                        labelLeft
                                                        widthLabel={'65%'}
                                                        widthInput={'35%'}
                                                        label={"Диаметр, (м):"}
                                                        min={0}
                                                        step={0.1}
                                                        onValidate={this.validateField}
                                                        enabledHandlerOnInput={true}
                                                        isValid={!this.app.state.errors.hasOwnProperty('D')}
                                                        onChange={this.customNumericInputHandler}
                                                    />
                                                </div>
                                                }
                                            </div>

                                            <div className="d-flex form-group">
                                                <p style={styleMultilineLabel}>{lang('Диаметр арматуры')}:</p>
                                                <select name='диаметр_арматуры'
                                                        onChange={this.paramsHandler}
                                                        className='fz-14 form-control'
                                                        value={this.app.state['диаметр_арматуры']}
                                                        style={styleMultiLineInput}>
                                                    <option value='0.012'>12 мм</option>
                                                    <option value='0.014'>14 мм</option>
                                                    <option value='0.016'>16 мм</option>
                                                    <option value='0.018'>18 мм</option>
                                                    <option value='0.02'>20 мм</option>
                                                    <option value='0.022'>22 мм</option>
                                                    <option value='0.025'>25 мм</option>
                                                    <option value='0.028'>28 мм</option>
                                                    <option value='0.032'>32 мм</option>
                                                    <option value='0.036'>36 мм</option>
                                                    <option value='0.04'>40 мм</option>
                                                    <option value='0.045'>45 мм</option>
                                                    <option value='0.05'>50 мм</option>
                                                    <option value='0.055'>55 мм</option>
                                                    <option value='0.06'>60 мм</option>
                                                    <option value='0.07'>70 мм</option>
                                                    <option value='0.08'>80 мм</option>
                                                </select>
                                            </div>
                                            <div className="d-flex form-group">
                                                <p style={styleMultilineLabel}>{lang('Диаметр арматуры в подколонную часть')}:</p>
                                                <select name='ds_undercolumn'
                                                        onChange={this.paramsHandler}
                                                        className='fz-14 form-control'
                                                        value={this.app.state['ds_undercolumn']}
                                                        style={styleMultiLineInput}>
                                                    <option value='0.012'>12 мм</option>
                                                    <option value='0.014'>14 мм</option>
                                                    <option value='0.016'>16 мм</option>
                                                    <option value='0.018'>18 мм</option>
                                                    <option value='0.02'>20 мм</option>
                                                    <option value='0.022'>22 мм</option>
                                                    <option value='0.025'>25 мм</option>
                                                    <option value='0.028'>28 мм</option>
                                                    <option value='0.032'>32 мм</option>
                                                    <option value='0.036'>36 мм</option>
                                                    <option value='0.04'>40 мм</option>
                                                    <option value='0.045'>45 мм</option>
                                                    <option value='0.05'>50 мм</option>
                                                    <option value='0.055'>55 мм</option>
                                                    <option value='0.06'>60 мм</option>
                                                    <option value='0.07'>70 мм</option>
                                                    <option value='0.08'>80 мм</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <CustomNumericInput
                                                    name={'kernels'}
                                                    className="form-control input-text"
                                                    value={this.app.state.kernels}
                                                    allowNegative={false}
                                                    measure={' '}
                                                    precision={2}
                                                    labelLeft
                                                    widthLabel={'65%'}
                                                    widthInput={'35%'}
                                                    label={"Количество арматуры в подколонной части"}
                                                    min={4}
                                                    max={26}
                                                    step={1}
                                                    onValidate={this.validateField}
                                                    enabledHandlerOnInput={true}
                                                    isValid={!this.app.state.errors.hasOwnProperty('kernels')}
                                                    onChange={this.customNumericInputHandler}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <CustomNumericInput
                                                    name={'ad'}
                                                    className="form-control input-text"
                                                    value={this.app.state.ad}
                                                    allowNegative={false}
                                                    measure={'[см]'}
                                                    precision={2}
                                                    labelLeft
                                                    widthLabel={'65%'}
                                                    widthInput={'35%'}
                                                    label={"Величина защитного слоя, (см):"}
                                                    min={4}
                                                    max={7}
                                                    step={1}
                                                    onValidate={this.validateField}
                                                    enabledHandlerOnInput={true}
                                                    isValid={!this.app.state.errors.hasOwnProperty('ad')}
                                                    onChange={this.customNumericInputHandler}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <CustomNumericInput
                                                    name={'bc'}
                                                    className="form-control input-text"
                                                    value={this.app.state.bc}
                                                    allowNegative={false}
                                                    measure={'[м]'}
                                                    precision={2}
                                                    labelLeft
                                                    widthLabel={'65%'}
                                                    widthInput={'35%'}
                                                    label={"Ширина подколонника bc, (м):"}
                                                    min={0}
                                                    max={10}
                                                    step={1}
                                                    onValidate={this.validateField}
                                                    enabledHandlerOnInput={true}
                                                    isValid={!this.app.state.errors.hasOwnProperty('bc')}
                                                    onChange={this.customNumericInputHandler}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <CustomNumericInput
                                                    name={'lc'}
                                                    className="form-control input-text"
                                                    value={this.app.state.lc}
                                                    allowNegative={false}
                                                    measure={'[м]'}
                                                    precision={2}
                                                    labelLeft
                                                    widthLabel={'65%'}
                                                    widthInput={'35%'}
                                                    label={"Длина подколонника lc, (м):"}
                                                    min={0}
                                                    max={10}
                                                    step={1}
                                                    onValidate={this.validateField}
                                                    enabledHandlerOnInput={true}
                                                    isValid={!this.app.state.errors.hasOwnProperty('lc')}
                                                    onChange={this.customNumericInputHandler}
                                                />
                                            </div>

                                            <div className="d-flex form-group">
                                                <p style={{width: '65%'}}>{lang('Класс бетона')}:</p>
                                                <select name='класс_бетона'
                                                        onChange={this.paramsHandler}
                                                        className='fz-14 form-control'
                                                        value={this.app.state['класс_бетона']}
                                                        style={{width: '35%'}}>
                                                    <option value='B15'>B15</option>
                                                    <option value='B20'>B20</option>
                                                    <option value='B25'>B25</option>
                                                    <option value='B30'>B30</option>
                                                    <option value='B35'>B35</option>
                                                    <option value='B40'>B40</option>
                                                    <option value='B45'>B45</option>
                                                    <option value='B50'>B50</option>
                                                    <option value='B55'>B55</option>
                                                    <option value='B60'>B60</option>
                                                    <option value='B70'>B70</option>
                                                    <option value='B80'>B80</option>
                                                    <option value='B90'>B90</option>
                                                    <option value='B100'>B100</option>
                                                </select>
                                            </div>

                                            <div className="d-flex form-group">
                                                <p style={{width: '65%'}}>{lang('Класс арматуры')}:</p>
                                                <select name='класс_арматуры'
                                                        style={{width: '35%'}}
                                                        onChange={this.paramsHandler}
                                                        className='fz-14 form-control'
                                                        value={this.app.state['класс_арматуры']}>
                                                    <option value='A240'>А240</option>
                                                    <option value='A400'>А400</option>
                                                    <option value='A500'>А500</option>
                                                    <option value='A600'>А600</option>
                                                    <option value='A800'>А800</option>
                                                    <option value='A1000'>А1000</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <CustomNumericInput
                                                    name={'nl'}
                                                    className="form-control input-text"
                                                    value={this.app.state.nl}
                                                    allowNegative={false}
                                                    measure={' '}
                                                    labelLeft
                                                    widthLabel={'65%'}
                                                    widthInput={'35%'}
                                                    label={"Количество ступеней по стороне l:"}
                                                    min={0}
                                                    max={5}
                                                    step={1}
                                                    onValidate={this.validateField}
                                                    enabledHandlerOnInput={true}
                                                    isValid={!this.app.state.errors.hasOwnProperty('nb')}
                                                    onChange={this.customNumericInputHandler}
                                                />
                                            </div>
                                            <div className={'alert alert-light'}
                                                 style={{marginBottom: "7px"}}>{lang("Вылет ступени принимать не более трехкратной величины суммы всех ступеней")}</div>
                                            {this.renderStepsFields('l')}
                                            <div className="form-group">
                                                <CustomNumericInput
                                                    name={'nb'}
                                                    className="form-control input-text"
                                                    value={this.app.state.nb}
                                                    allowNegative={false}
                                                    measure={' '}
                                                    labelLeft
                                                    widthLabel={'65%'}
                                                    widthInput={'35%'}
                                                    label={"Количество ступеней по стороне b:"}
                                                    min={0}
                                                    max={5}
                                                    step={1}
                                                    onValidate={this.validateField}
                                                    enabledHandlerOnInput={true}
                                                    isValid={!this.app.state.errors.hasOwnProperty('nb')}
                                                    onChange={this.customNumericInputHandler}
                                                />
                                            </div>
                                            {this.renderStepsFields('b')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapsible>
                    }
                </div>
            </div>
        </div>
    );
  }

}

export default PillarFoundationCheck;
