import {combineReducers} from "redux";

import appSlice from './slices/appSlice';
import sidebarsSlice from "./slices/sidebarsSlice";
import solveSlice from "./slices/solveSlice";

import suSlice, {fetchSu} from './asyncSlices/suSlice';
import citiesSlice, {fetchCities} from './asyncSlices/citiesSlice';

const reducer = combineReducers({
	app: appSlice,
	solve: solveSlice,
	sidebars: sidebarsSlice,

	fetched: combineReducers({
		cities: citiesSlice,
		Su: suSlice
	})
});

export default reducer;

