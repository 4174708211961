import React, { Component } from 'react';
import { Container,ToggleButton,ToggleButtonGroup,Form,Col,Row } from 'react-bootstrap'
import Select from 'react-select'
import { ReactSVG } from 'react-svg'
import $ from 'jquery'
import CustomNumericInput from "../../CustomNumericInput/CustomNumericInput";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import surveysImg from '../../../img/surveysImg.svg';
import {isEmpty,precision} from "../../Global/Global";
import {Checkbox} from "rsuite";

class PillarScheme extends Component {

	state = {
		selectedOption: {
			label: "Ростов-на-Дону",
			value: 274
		},
		cities:null
	};

	app = this.props.app;
	options = this.app.optionsCities;

	selectHandler = (e) => {
		this.app.setState({'город':e.value})
		this.setState({selectedOption:e})
	};

	customCheckboxHandler = (v,checked) =>{
		this.app.setState({
			'привязка_к_абсолютным_отметкам': checked,
		});
		setTimeout(this.handleTracksSvgLines,1);
	}

	lineHandler = (lines,state,color = 'black') => {
		if(state === 'show'){
			for(let i = 0; i < lines.length;i++){
				if(lines[i].hasAttribute('stroke')){
					lines[i].setAttribute('stroke',color);
					lines[i].setAttribute('stroke-width','1px');
				}else{
					lines[i].setAttribute('fill',color);
				}
			}
		}
		if(state === 'hide'){
			for(let i = 0; i < lines.length;i++){
				if(lines[i].hasAttribute('stroke')){
					lines[i].setAttribute('stroke','none');
					lines[i].setAttribute('stroke-width','3px');
				}else{
					lines[i].setAttribute('fill','none');
				}
			}
		}
	}
	handleDwSvgLines = (number,svg) =>{
		if(!svg) svg = document.getElementById('surveysImg');
		let isShow = number > 0;
		let lines = svg.getElementsByClassName('dw');
		this.lineHandler(lines,isShow?'show':'hide','#3288D7');
	}

	handleTracksSvgLines = (svg) =>{
		if(!svg) svg = document.getElementById('surveysImg');

		let svgDoc = svg;
		let isAbs = this.app.state['привязка_к_абсолютным_отметкам'];
		let showLines = svgDoc.getElementsByClassName(isAbs?'abs':'notAbs');
		let hideLines = svgDoc.getElementsByClassName(isAbs?'notAbs':'abs');
		this.lineHandler(showLines,'show');
		this.lineHandler(hideLines,'hide');
	}

	handleGroundSvgLines = (svg) =>{
		if(!svg) svg = document.getElementById('surveysImg');
		let isGrounded = this.app.state['планируемость_территории'];
		let svgDoc = svg;
		let ground = svgDoc.getElementsByClassName('ground');
		this.lineHandler(ground,!isGrounded?'show':'hide');

	}
	validateField = (props) => {

		const value = props.value;
		const name = props.name;

		let isValid = true;
		let textError = 'Ошибка в заполнении поля';

		switch (name) {
			case 'dw':

				let deep = 0;
				this.app.state.surveysData.forEach(item => deep+= item.глубина_залегания);

				isValid = true;
				if (value < 0) {
					isValid = false;
					textError = 'Уровень грунтовых вод не может залегать выше поверхности грунта';
				}
				if(deep < value && value !== 0){
					isValid = false;
					textError = 'Уровень грунтовых вод ниже заданной мощности слоев, убедитесь в правильности введенных данных';
				}
				break;
			case 'глубина_заложения':
				if(value < 0){
					isValid = false;
					textError = 'Не может быть отрицательной';
				}
				if(value < 0.5 || value > 10){
					isValid = false;
					textError = 'Глубина заложения должна быть не мене 0.5 м и не более 10 м';
				}
				if(!this.app.state['планируемость_территории']){
					if(value < this.app.state['глубина_слоя'] && !isEmpty(value)){
						isValid = false;
						textError = 'Подошва фундамента залегает на насыпном грунте,увеличьте глубину заложения';
					}else{
						if(this.app.state.errors.hasOwnProperty('глубина_слоя')){
							let errors = this.app.state.errors;
							delete errors['глубина_слоя'];
							this.app.setState({errors:errors});
						}
					}
				}
				if (isEmpty(value)) isValid = true;
				break;
			case 'высота_фундамента':
				if(this.app.state['тип_расчета'] === 'check' && this.app.state['anchor'] && (!this.app.state['reinforcement'] && !this.app.state['size'])){
					if (isEmpty(value) || parseFloat(value) === 0) {

						textError = 'Укажите высоту фундамента для сбора нагрузок приходящий на верх фундамента';
						isValid = false;
					}
				}else{
					if(value < 0){
						isValid = false;
						textError = 'Не может быть отрицательной';
					}
					if(value < 0.6 || value > 15){
						isValid = false;
						textError = 'Высота фундамента должна быть не мене 0.6 м и не более 15 м';
					}
					if (isEmpty(value)) isValid = true;
				}
				break;
			case 'глубина_слоя':
				if (isEmpty(value)) {
					isValid = false;
					textError = 'Не задана мощность почвенно-растительного (насыпного) слоя грунта';
				}
				if(!this.app.state['планируемость_территории']) {
					if (value > this.app.state['глубина_заложения'] && !isEmpty(this.app.state['глубина_заложения'])) {
						isValid = false;
						textError = 'Подошва фундамента залегает на насыпном грунте,увеличьте глубину заложения';
					}
				}
				break;
			case 'YII_':
				isValid = true;
				if (value > 2.5) {
					isValid = false;
					textError = 'Удельный вес грунта должен быть не более 2.5 гр/см<sup>2</sup>';
				}
				if (isEmpty(value)) {
					isValid = false;
					textError = 'Заполните поле';
				}
				if (value < 0) {
					isValid = false;
					textError = 'Удельный вес грунта не может быть отрицательным';
				}
				break;
			default:
				break;
		}

		return {
			isValid: isValid,
			textError: (isValid) ? '' : textError
		}
	}

	trackInputs = (e) => {

		e.preventDefault();

		let value = e.target.value.replace(/[,]/g, ".");
		let name = e.target.name;


		if(value.includes('-') && !this.app.state['привязка_к_абсолютным_отметкам']) return false;
		if(!($.isNumeric(value) || value.includes('-') || isEmpty(value))) return false;
		if(precision(value) > 2)return false;

		let validator = this.validateField({
			value:value,
			name:name
		});

		let errors = this.app.state.errors;

		if(!validator.isValid) {

			e.target.classList.add('error-table');
			errors[name] = {
				textError: validator.textError,
				tab: '2'
			};

		}else{
			e.target.classList.remove('error-table');
			delete  errors[name];

		}
		this.app.setState({
			[name]: value,
			errors:errors
		});
	}

	customNumericInputHandler = (value, name , validator) =>{

		let errors = this.app.state.errors;
		if(!validator.isValid) {
			{
				errors[name] = {
					textError: validator.textError,
					tab: '2'
				};
			}
		}else{
			delete  errors[name];
		}

		this.app.setState({
			[name]: value,
			errors:errors
		});

		if(name === 'dw'){
			this.handleDwSvgLines(value);
		}

	};

	toggleButtonsGroupHandler = (value,e) =>{

		let name = e.target.name;
		this.app.setState({[name]:value});
		this.handleGroundSvgLines();

	}

	render() {
		return (
			<div className='foundation_scheme' style={{minWidth: 550}}>
				<ReactSVG
					src={surveysImg}
					beforeInjection={svg => {
						svg.setAttribute('width', '350px');
						svg.setAttribute('id', 'surveysImg');
						this.handleTracksSvgLines(svg);
						this.handleDwSvgLines(this.app.state.dw,svg);
						this.handleGroundSvgLines(svg);
					}}
					evalScripts="always"
					renumerateIRIElements={false}
				/>
				<div className="container h-100 w-100 d-flex align-items-center justify-content-center">
					<Checkbox name={'привязка_к_абсолютным_отметкам'} disabled={this.app.state['тип_расчета'] === 'check'} checked={this.app.state['привязка_к_абсолютным_отметкам']} onChange={this.customCheckboxHandler} >Привязать к абсолютным отметкам</Checkbox>
				</div>

				{(this.app.state['привязка_к_абсолютным_отметкам']) &&
				<div className='tracks track-up' >
					<input name='отметка_верха' onChange={this.trackInputs} onBlur={this.trackInputs} className="input-text form-control mr-1"  type="text" value={this.props.data['отметка_верха']}/>
					[м]
				</div>
				}
				{(this.app.state['привязка_к_абсолютным_отметкам']) &&
				<div className='tracks track-surface'>
					<input name='отметка_поверхности' onChange={this.trackInputs} onBlur={this.trackInputs} className="input-text form-control mr-1" type="text" value={this.props.data['отметка_поверхности']}/>
					[м]
				</div>
				}
				{(this.app.state['привязка_к_абсолютным_отметкам']) &&
				<div className='tracks track-under'>
					<input name='отметка_подошвы' onChange={this.trackInputs} onBlur={this.trackInputs} className="input-text form-control mr-1" type="text" value={this.props.data['отметка_подошвы']}/>
					[м]
				</div>
				}
				{(!this.app.state['привязка_к_абсолютным_отметкам']) &&
				<div className='tracks track-h_found'>
					<input id={'высота_фундамента'} name='высота_фундамента' onBlur={this.trackInputs} disabled={(this.app.state['тип_расчета'] === 'check' && (!this.app.state['anchor'] || (this.app.state['reinforcement'] || this.app.state['reinforcement'])))} onChange={this.trackInputs} className="input-text form-control mr-1" type="text" value={this.app.state['высота_фундамента']}/>
					[м]
				</div>
				}
				{(!this.app.state['привязка_к_абсолютным_отметкам']) &&
				<div className='tracks track-deep'>
					<input id={'глубина_заложения'} name='глубина_заложения' onBlur={this.trackInputs} disabled={this.app.state['тип_расчета'] === 'check'} onChange={this.trackInputs} className="input-text form-control mr-1" type="text" value={this.app.state['глубина_заложения']}/>
					[м]
				</div>
				}
			</div>
		);
	}
}

export default PillarScheme;
