import React, { Component } from 'react';
import config from "../../../Model/Config";
import { ToggleButton,ToggleButtonGroup,Form,Row } from 'react-bootstrap'
import schemeSquare from '../../../img/1.svg';
import schemeCircle from '../../../img/1c.svg';
import LoadsTable from '../PillarLoadsTable';
import $ from 'jquery';
import {Notification, Whisper} from 'rsuite/lib/index';
import './index.css';
import leftLoadsArrow from "../../../img/leftLoadsArrow.svg";
import rightLoadsArrow from "../../../img/rightLoadsArrow.svg";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {lang, popover} from "../../../Modules/Global";

class Loads extends Component {
	state = {
		loads:'загружения',
		schemeOpen: window.outerWidth > 1400
	};

	schemeHandler = () => this.setState({schemeOpen: !this.state.schemeOpen});
	radioHandler = (e) => this.setState({loads:e});

	app = this.props.app;

	upload = (file) => {
		var fd = new FormData();
		fd.append('loadsFiles', file);

		$.ajax({
			url: config.url + '/loads',
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
			success: (data) => {
				this.app.setState({loadsData:data});
			},
			error: () => {
				Notification.error({
					title: 'Ошибка',
					duration: 40000,
					description: <div>
						<p>Проверте корректность загружаемых данных</p>
					</div>,
				});
			}
		});

	};

	cleanInputFile = (e) => {
		e.target.value = null;
	};

	uploadLoads = (e) =>{
		this.upload(e.target.files[0]);
	};


	render() {

		let arrow = (this.state.schemeOpen)? rightLoadsArrow : leftLoadsArrow;
		let scheme = (this.app.state['форма_подошвы'] === 'круглая')? schemeCircle : schemeSquare ;
		return (
			<div  style={{
				width: '100%',
				height: '100%'
			}} className="pb-5 container-loads container-fluid" >
				<div className="w-100 row">
					<h1 className="app-page-title">Сбор нагрузок на фундамент</h1>
				</div>

				<Form.Group className="p-0 row" onChange={this.props.paramsHandler}>
					<input type='file' name='loads' id={'loadsFile'} className={'hide'} onClick={this.cleanInputFile} onChange={this.uploadLoads}/>
					<label className="btn btn-sm btn-light m-0" htmlFor="loadsFile">{lang("Импорт загружений")}</label>

					<a
						className="btn btn-sm btn-light m-0 ml-1"
						target="_blank"
						href="/template.xlsx">
						{lang("Скачать шаблон")}
					</a>
				</Form.Group>

				<Row className={(this.state.loads === "сочетания")? "p-0 disabled" : "p-0"}>
					<div className={'loads-table-wrapper p-0 ' + (this.state.schemeOpen ? 'col-lg-8' : 'col')}>
						<LoadsTable app={this.app}/>
					</div>
					<div className="btn-spoiler schemeButton justify-content-center align-content-center">

						<Whisper placement="auto"
								 delayShow={300}
								 trigger="hover" speaker={
								 	popover(
										<div>
											{this.state.schemeOpen
												? lang("Скрыть схему нагружения фундамента")
												: lang("Показать схему нагружения фундамента")
											}
										</div>
									)} >
							<div onClick={this.schemeHandler}>
								<FontAwesomeIcon
									icon={this.state.schemeOpen ? faChevronRight : faChevronLeft}
									style={{marginLeft: 5, opacity: 0.3}}/>
							</div>
						</Whisper>


					</div>
					<div
						className={'text-center' + (this.state.schemeOpen ? ' col' : '')}
						style={{
							width: this.state.schemeOpen ? 'auto' : 0
						}}
						>
						<object id="loads-scheme"
								className="img-fluid"
								data={scheme}
								type="image/svg+xml">
						</object>
					</div>
				</Row>
			</div>
		);
	}
}

export default Loads;
